import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import PageContainer from "../../../../Components/PageContainer/PageContainer";
import { useParams } from "react-router-dom";
import { removeOrderSync, upsertOrderSync } from "../../../../redux/Order/OrderSlice";
import { MenuCategoryList } from "./MenuCategory/MenuCategoryList";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { MenuProductList } from "./MenuProduct/MenuProductList";
import { MenuOrderLayout } from "./MenuOrder/MenuOrderLayout";

import { FaHourglassEnd } from "react-icons/fa";
import { StandardCard } from "../../../../Components/Card";
import { CustomRoundButton } from "../../../../Components/CustomButton";

export const OrderMenuLayout: React.FC = () => {
    const { tableNo } = useParams<{ tableNo: string }>();

    const { list } = useAppSelector((state) => state.order);
    const dispatch = useAppDispatch();

    const handleAddOrder =
        (quantity: number, name: string, price: number) => () => {
            dispatch(upsertOrderSync({ quantity, name, price }));
        };

    const handleRemoveOrder = (name: string, price: number) => () => {
        dispatch(removeOrderSync({ name, price }));
    };

    const SubTotal = React.useMemo(() => {
        return list.reduce((acc, item) => {
            return acc + item.price * item.quantity;
        }, 0);
    }, [list]);

    const tax = React.useMemo(() => {
        return SubTotal * 0.1;
    }, [SubTotal]);

    return (
        <PageContainer title="Menu" description="Menu page">
            <Grid container spacing={1.5} pt={2} flexWrap="wrap">
                <Grid item xs={12} sm={3} md={3} lg={2}>
                    <MenuCategoryList />
                </Grid>

                <Grid item xs={12} sm={5} md={6} lg={7}>
                    <MenuProductList />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={3}>
                    <MenuOrderLayout />
                </Grid>
            </Grid>
        </PageContainer>
    );
};





