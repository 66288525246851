import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { IAirline } from "./airlines.types";


export const fetchAllAirlinesAsync = createAsyncThunk<{ recordCount: number, data: IAirline[] }>(
    'airlines/fetchAllAirlinesAsync', async () => {
        const response = await axios_api.get(`/airlines/get-airline`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchAirlinesAsync = createAsyncThunk<{ recordCount: number, data: IAirline[] }, IQueryParams>(
    'airlines/fetchAirlinesAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`/airlines/get-airline${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchSingleAirlinesAsync = createAsyncThunk<IAirline, string>(
    'airlines/fetchSingleAirlinesAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`/airlines/get-airline?airline_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)

export const upsertSingleAirlineAsync = createAsyncThunk<IAirline, IAirline>(
    'airlines/upsertSingleAirlineAsync', async (payload: IAirline, thunkAPI) => {
        const response = await axios_api.post(`/airlines/upsert-airline`, payload)
        thunkAPI.dispatch(fetchAirlinesAsync({
            columns: [],
            page: 1,
            status: "-1",
            value: "",
            rowsPerPage: 10
        }))
        return response.data
    },
)