// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active .customer_name_card{
    background-color: #C9CAEF;
}
.active .customer_name_card .customer_name_card__name{
    color: #000;
}`, "",{"version":3,"sources":["webpack://./src/Components/Card/CustomerNameCard/CustomerNameCard.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;AACf","sourcesContent":[".active .customer_name_card{\n    background-color: #C9CAEF;\n}\n.active .customer_name_card .customer_name_card__name{\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
