import { createSlice } from "@reduxjs/toolkit";
import { defaultFlightMenuState } from "./flight-menu.state";
import { LoadState } from "../../../constants/enum";
import { fetchFlightsMenuListAsync, fetchFlightsMenuListWithoutArgsAsync, fetchSingleFlightMenuAsync } from "./flight-menu.actions";


const flightMenuSlice = createSlice({
    initialState: defaultFlightMenuState,
    name: "airlineMenu",
    reducers: {
        clearFlightMenuState: (state) => {
            return defaultFlightMenuState
        }
    },
    extraReducers: (builder) => {
         //#################################### Multiple Airlines Without Args ##############################################
         builder.addCase(fetchFlightsMenuListWithoutArgsAsync.pending, (state, action) => {
            state.flight_menu_list.loading = LoadState.pending
        })
        builder.addCase(fetchFlightsMenuListWithoutArgsAsync.fulfilled, (state, action) => {
            state.flight_menu_list.loading = LoadState.succeeded
            state.flight_menu_list.data = action.payload.data
            state.flight_menu_list.totalRecords = action.payload.recordCount
            state.flight_menu_list.error = null
        })
        builder.addCase(fetchFlightsMenuListWithoutArgsAsync.rejected, (state, action) => {
            state.flight_menu_list.error = action.error.message as string
        })

        //#################################### Multiple Airlines ##############################################
        builder.addCase(fetchFlightsMenuListAsync.pending, (state, action) => {
            state.flight_menu_list.loading = LoadState.pending
        })
        builder.addCase(fetchFlightsMenuListAsync.fulfilled, (state, action) => {
            state.flight_menu_list.loading = LoadState.succeeded
            state.flight_menu_list.data = action.payload.data
            state.flight_menu_list.totalRecords = action.payload.recordCount
            state.flight_menu_list.error = null
        })
        builder.addCase(fetchFlightsMenuListAsync.rejected, (state, action) => {
            state.flight_menu_list.error = action.error.message as string
        })


        //#################################### Single Airline ##############################################
        builder.addCase(fetchSingleFlightMenuAsync.pending, (state, action) => {
            state.single_flight_menu.loading = LoadState.pending
        })
        builder.addCase(fetchSingleFlightMenuAsync.fulfilled, (state, action) => {
            state.single_flight_menu.loading = LoadState.succeeded
            state.single_flight_menu.data = action.payload
            state.single_flight_menu.error = null
        })
        builder.addCase(fetchSingleFlightMenuAsync.rejected, (state, action) => {
            state.single_flight_menu.error = action.error.message as string
        })
    },
});

export const flightMenuReducer = flightMenuSlice.reducer;
export const {
    clearFlightMenuState
} = flightMenuSlice.actions;
