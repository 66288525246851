import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { IAirlineMenu } from "./airline-menu.types"


export const fetchSingleAirlineMenuListAsync = createAsyncThunk<{ recordCount: number, data: IAirlineMenu[] }, string>(
    'airline-menu/fetchSingleAirlineMenuListAsync', async (airline_uuid: string, thunkAPI) => {
        const response = await axios_api.get(`/airlines/get-menu?airline_uuid=${airline_uuid}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export interface IGetAirlineMenu extends IQueryParams {
    airline_uuid?: string
}
export const fetchSingleAirlineMenuListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IAirlineMenu[] }, IGetAirlineMenu>(
    'airline-menu/fetchSingleAirlineMenuListWithArgsAsync', async (queryParams: IGetAirlineMenu, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        let get_url = `/airlines/get-menu${searchQuery}`;
        if (queryParams.airline_uuid) {
            get_url += `&airline_uuid=${queryParams.airline_uuid}`
        }
        const response = await axios_api.get(get_url)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


// export const fetchMenuItemListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IAirlineMenu[] }, IQueryParams>(
//     'airline-menu/fetchMenuItemListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
//         const searchQuery = getSearchQuery(queryParams);
//         const response = await axios_api.get(`/airlines/get-menu${searchQuery}`)
//         const data = response.data.data;
//         const count = response.data.totalRecords
//         return { recordCount: count, data: data }
//     },
// )

export const fetchAirlineSingleMenuAsync = createAsyncThunk<IAirlineMenu, string>(
    'airline-menu/fetchAirlineSingleMenuAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`/airlines/get-menu?menu_item_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)

export const upsertAirlineSingleMenuAsync = createAsyncThunk<IAirlineMenu, IAirlineMenu>(
    'airline-menu/upsertAirlineSingleMenuAsync', async (payload: IAirlineMenu, thunkAPI) => {
        const response = await axios_api.post(`/airlines/upsert-menu`, payload)
        return response.data
    },
)