import { createSlice } from "@reduxjs/toolkit";
import { defaultReservationState } from "./reservations.state";
import { getReservationsFormLocalStore, setReservationsInLocalStore } from "./reservations.localStore";
import { fetchTableReservationListAsync } from "./reservations.actions";
import { LoadState } from "../../../constants/enum";


const reservationsSlice = createSlice({
    initialState: defaultReservationState,
    name: "reservations",
    reducers: {
        getReservationsListSync: (state) => {
            const data = getReservationsFormLocalStore()
            state.reservations_list.data = data
        },

        upsertNewReservationsSync: (state, action) => {
            if (action.payload.reservation_uuid) {
                const index = state.reservations_list.data.findIndex((res) => res.reservation_uuid === action.payload.reservation_uuid)
                state.reservations_list.data.splice(index, 1, action.payload)
                setReservationsInLocalStore(state.reservations_list.data)
            } else {
                state.reservations_list.data.push({
                    ...action.payload,
                    reservation_uuid: Date.now().toString()
                })
                setReservationsInLocalStore(state.reservations_list.data)
            }
        },
        deleteReservationsSync: (state, action) => {
            const reservation_uuid = action.payload.reservation_uuid
            const index = state.reservations_list.data.findIndex((res) => res.reservation_uuid === reservation_uuid)
            state.reservations_list.data.splice(index, 1)
            setReservationsInLocalStore(state.reservations_list.data)
        },
        clearReservationsState: (state) => {
            return defaultReservationState
        },
    },
    extraReducers: (builder) => {
        // ############################# Multiple Reservations ######################################
        builder.addCase(fetchTableReservationListAsync.pending, (state, action) => {
            state.reservations_list.loading = LoadState.pending
        })
        builder.addCase(fetchTableReservationListAsync.fulfilled, (state, action) => {
            state.reservations_list.loading = LoadState.succeeded
            state.reservations_list.data = action.payload.data
            state.reservations_list.totalRecords = action.payload.recordCount
            state.reservations_list.error = null
        })
        builder.addCase(fetchTableReservationListAsync.rejected, (state, action) => {
            state.reservations_list.error = action.error.message as string
        })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const reservationsReducer = reservationsSlice.reducer;
export const { clearReservationsState, getReservationsListSync, upsertNewReservationsSync, deleteReservationsSync } = reservationsSlice.actions;
