import { ShiftsTable } from "./AllShifts/ShiftsTable";
import { ManagePunchInOut } from "./PunchInOut/ManagePunchInOut";
import { ALL_SHIFTS_ROUTE, SHIFTS_PUNCH_IN_OUT_ROUTE } from "./ShiftAndPunchInOut.constants";

export const shiftAndPunchInOutRoutes = [
    {
        path: ALL_SHIFTS_ROUTE,
        exact: true,
        element: <ShiftsTable />
    },
    {
        path: SHIFTS_PUNCH_IN_OUT_ROUTE,
        exact: true,
        element: <ManagePunchInOut />
    },
] 