import React from "react";
import { useAppDispatch } from "../../../redux";
import { defaultOrder, upsertSingleOrderWithCallbackAsync } from "../../../redux/orders";
import { POS_ORDERS_ROUTE, POS_ORDER_MENU_ROUTE } from "./MenuAndOrders.constants";
import { OrderMenuLayout } from "./OrderMenu/OrderMenuLayout";
import { POSOrdersTable } from "./PosOrders/POSOrdersTable";
import { ViewSinglePOSOrder } from "./PosOrders/ViewSinglePOSOrder";
import { useNavigate } from "react-router-dom";

export const CreateNewOrder: React.FC<{}> = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    dispatch(upsertSingleOrderWithCallbackAsync({
        payload: {
            ...defaultOrder,
        },
        onSuccess(isSuccess, resData) {
            if (isSuccess && resData) {
                navigate(`${POS_ORDER_MENU_ROUTE}/edit/${resData.order_uuid}`)
            }
        },

    }))
    return <></>
}

export const posOrderRoutes = [
    {
        path: POS_ORDERS_ROUTE,
        exact: true,
        element: <POSOrdersTable />
    },
    {
        path: `${POS_ORDERS_ROUTE}/view/:uuid`,
        exact: true,
        element: <ViewSinglePOSOrder />
    },
    {
        path: `${POS_ORDER_MENU_ROUTE}/new`,
        exact: true,
        element: <CreateNewOrder />
    },
    {
        path: `${POS_ORDER_MENU_ROUTE}/edit/:uuid`,
        exact: true,
        element: <OrderMenuLayout />
    },
]

