import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { ControlledCustomSelect, OutlinedTextField } from "../../../../Components/formComponents";
import { IAirlineFlight, defaultAirlineFlight, upsertSingleAirlineFlightAsync } from "../../../../redux/airlineAndFlights/flights";
import { Grid } from "@mui/material";
import { OutlinedTimePicker} from "../../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { AirlineAutoSearch } from "../../Airlines/AutoSearch/AirlineAutoSearch";

export interface IManageAirlineDialogProps {
  flightInfo?: IAirlineFlight;
  open: boolean;
  onClose: () => void;
}
export const ManageAirlineFlightDialog: React.FC<IManageAirlineDialogProps> = (props) => {
  const { open, flightInfo, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: { ...defaultAirlineFlight },
    validate: (values) => {
      const errors: any = {};
      if (!values.airline_uuid) {
        errors.airline_uuid = "*This field is required.";
      }
      if (!values.flight_name) {
        errors.flight_name = "*This field is required.";
      }
      if (!values.flight_number) {
        errors.flight_number = "*This field is required.";
      }
      if (!values.flight_time) {
        errors.flight_time = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleAirlineFlightAsync(values)
      ).finally(() => {
        setSaveLoading(false);
        onClose()
      });
    },
  });

  useEffect(() => {
    if (!flightInfo) return
    setValues(flightInfo)
  }, [flightInfo])

  return (
    <Dialog
      open={open}
      title={`Add/Edit Airline Flight`}
      onClose={onClose}
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <AirlineAutoSearch
            name="airline_name"
            label="Airline Name"
            value={{
              airline_uuid: values.airline_uuid as string,
              airline_name: values.airline_name as string,
            }}
            onSelect={(newValue) => {
              setValues({
                ...values,
                airline_uuid: newValue.airline_uuid,
                airline_name: newValue.airline_name,
              });
            }}
            error={errors.airline_uuid}
          />

        </Grid>
        <Grid item xs={12} lg={4}>
          <OutlinedTextField
            label="Flight Name"
            id="flight_name"
            value={values.flight_name}
            onChange={handleChange}
            error={errors.flight_name}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <OutlinedTextField
            label="Flight Number"
            name="flight_number"
            fullWidth
            value={values.flight_number}
            onChange={handleChange}
            error={errors.flight_number}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <OutlinedTimePicker
            label="Flight Time"
            value={moment(values.flight_time)}
            fullWidth
            onChange={(date) => {
              setFieldValue("flight_time", date.toISOString());
            }}
            error={errors.flight_time}
          />
        </Grid>
        {values.flight_uuid && (
          <Grid item xs={12} lg={4}>
            <ControlledCustomSelect
              label="Status"
              name="status"
              fullWidth
              value={values.status}
              onChange={handleChange}
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ]}
              displayEmpty
              placeholder="Select Status"
              error={errors.status ? true : false}
              helperText={errors.status}
            />
          </Grid>
        )}
      </Grid>


    </Dialog >
  );
};
