import { Stack, Grid, Box, Button, FormControl, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../../layout/breadcrumb/IBreadcrumbProps";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { PageLoader } from "../../../../Components/PageLoader/PageLoader";
import PageContainer from "../../../../Components/PageContainer/PageContainer";
import { clearAirlineState, upsertSingleAirlineAsync } from "../../../../redux/airlineAndFlights/airlines";
import { AIRLINE_ROUTES } from "../../AirlineAndFlights.constants";
import { ControlledCustomSelect, CustomTextField, OutlinedTextField } from "../../../../Components/formComponents";
import LoadingDialog from "../../../../Components/Dialogs/LoadingDialog";
import { LoadState } from "../../../../constants/enum";
import { Breadcrumb } from "../../../../layout/breadcrumb/Breadcrumb";
import { StandardCard } from "../../../../Components/Card";
import { clearAirlineMenuState, fetchAirlineSingleMenuAsync, upsertAirlineSingleMenuAsync } from "../../../../redux/airlineAndFlights/airline-menu";
import { DASHBOARD_ROUTES } from "../../../Dashboard/Dashboard.constants";

const BCrumb: IBreadcrumbProps["items"] = [
  {
    to: DASHBOARD_ROUTES,
    title: "dashboard",
  },
  {
    to: "/airlines",
    title: "airlines",
  },
  {
    title: "manage",
  },
];

export const ManageAirlineSingleMenu = () => {
  const { uuid: airline_menu_uuid } = useParams() as { uuid?: string };
  const [searchParams] = useSearchParams();
  const airline_uuid = searchParams.get('airline_uuid')
  const airline_name = searchParams.get('airline_name')

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    data: airlineMenuInfo,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.airlinesAndFlights.airlineMenu.single_airline_menu);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...airlineMenuInfo,
      airline_uuid: airline_uuid || "",
      airline_name: airline_name || ""
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.menu_item_name) {
        errors.menu_item_name = "*This field is required.";
      }
      if (!values.menu_item_price) {
        errors.menu_item_price = "*This field is required.";
      }
      if (!values.menu_item_size) {
        errors.menu_item_size = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertAirlineSingleMenuAsync(values)
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(`${AIRLINE_ROUTES}/manage/${values.airline_uuid}`)
        }
      }).finally(() => {
        setSaveLoading(false);
        // onClose()
      });
    },
  });

  React.useEffect(() => {
    if (airline_menu_uuid) {
      dispatch(fetchAirlineSingleMenuAsync(airline_menu_uuid));
    }
  }, [airline_menu_uuid]);

  React.useEffect(() => {
    const newInfo = airlineMenuInfo.menu_item_uuid ? airlineMenuInfo : {
      ...airlineMenuInfo,
      airline_uuid: airline_uuid || "",
      airline_name: airline_name || ""
    }
    setValues(newInfo);
  }, [airlineMenuInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearAirlineMenuState());
    };
  }, []);

  return (
    <>
      <PageLoader
        loading={loading === LoadState.pending}
      >
        <PageContainer
          title="Manage Airline Menu"
          description="this is inner page"
        >
          <Breadcrumb title="Manage Airline Menu" items={BCrumb} />
          <StandardCard heading="Airline Menu Information" sx={{ mb: 4 }}>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <OutlinedTextField
                    label="Menu Item Name"
                    id="menu_item_name"
                    fullWidth
                    value={values.menu_item_name}
                    onChange={handleChange}
                    error={errors.menu_item_name}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <OutlinedTextField
                    label="Menu Item Price"
                    name="menu_item_price"
                    fullWidth
                    value={values.menu_item_price}
                    onChange={handleChange}
                    error={errors.menu_item_price}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <OutlinedTextField
                    label="Menu Item Size"
                    name="menu_item_size"
                    fullWidth
                    value={values.menu_item_size}
                    onChange={handleChange}
                    error={errors.menu_item_size}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <OutlinedTextField
                    label="Airline Name"
                    name="airline_name"
                    fullWidth
                    disabled
                    value={values.airline_name}
                    onChange={handleChange}
                    error={errors.airline_name}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <ControlledCustomSelect
                    label="Status"
                    name="status"
                    fullWidth
                    disabled={!values.menu_item_uuid}
                    value={values.status}
                    onChange={handleChange}
                    options={[
                      { label: "Active", value: "ACTIVE" },
                      { label: "Inactive", value: "INACTIVE" },
                    ]}
                    displayEmpty
                    placeholder="Select Status"
                    error={errors.status ? true : false}
                    helperText={errors.status}
                  />
                </Grid>

              </Grid>
              <Stack direction={"row"} justifyContent="flex-end" spacing={2} marginTop={4}>
                <Button
                  type="submit"
                  disabled={saveLoading}
                  variant="contained"
                >
                  Save
                </Button>
                {saveLoading && <LoadingDialog open={saveLoading} />}
              </Stack>
            </Box>
          </StandardCard>
        </PageContainer>
      </PageLoader>
    </>
  );
};
