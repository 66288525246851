import React from "react";
// import { DataTable, ITableColumn } from "../../../Components/Tables/Table";
import { Avatar, Button } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../Components/Table/DataTable";
import { useDateFilter } from "../../../../Components/Table/hooks/useDateFilter";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import { StandardTableActions } from "../../../../Components/StandardTableActions/StandardTableActions";
import { formatDateWithTime } from "../../../../helpers";
import { UpsertPaymentsPaidDialog } from "./dialogs/UpsertPaymentsPaidDialog";
import { CustomButton } from "../../../../Components/CustomButton";
import { IPaymentPaid, clearPaymentsPaidState, defaultPaymentPaid, fetchPaymentPaidListWithArgsAsync } from "../../../../redux/accounting";

export const PaymentsPaidTable = () => {
  const {
    data: payments_paid_list,
  } = useAppSelector((storeState: IStoreState) => storeState.pointOfSale.accounting.paymentPaid.payments_paid_list);

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUpsertDialog, setOpenUpsertDialog] = React.useState<IPaymentPaid | null>(null);


  const dataTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPaymentPaid) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "vendor",
        headerName: "Vendor",
        fieldName: "vendor",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "category",
        headerName: "Category",
        fieldName: "category",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "order_number",
        headerName: "Order Number",
        fieldName: "order_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "invoice_number",
        headerName: "Invoice Number",
        fieldName: "invoice_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "total_tax",
        headerName: "Total Tax",
        fieldName: "total_tax",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "total_amount",
        headerName: "Total Amount",
        fieldName: "total_amount",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },

      {
        key: "payment_mode",
        headerName: "Payment Mode",
        fieldName: "payment_mode",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "order_status",
        headerName: "Order Status",
        fieldName: "order_status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      // {
      //   key: "insert_ts",
      //   headerName: "Date",
      //   fieldName: "insert_ts",
      //   enableSorting: true,
      //   renderType: RenderType.DATE_DARK_COLOR,
      //   exportCellWidth: 25,
      //   onExportRender: (values) => {
      //     return formatDateWithTime(values.value);
      //   },
      // },
      {
        key: "view",
        headerName: "View",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPaymentPaid) => {
          return (
            <Button variant="contained" >View Invoice</Button>
          );
        },
      },
    ],

    items: payments_paid_list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };


  React.useEffect(() => {
    fetchList();
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearPaymentsPaidState());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchPaymentPaidListWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      }),
    );
  };

  return (
    <>
      <CustomButton
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          marginLeft: "auto",
          marginY: 2,
        }}
        onClick={() => setOpenUpsertDialog(defaultPaymentPaid)}
      >
        Add New
      </CustomButton>
      <DataTable {...dataTableProps} />
      {openUpsertDialog && <UpsertPaymentsPaidDialog open={true} initialState={openUpsertDialog} onClose={() => setOpenUpsertDialog(null)} />}
    </>
  );
};
