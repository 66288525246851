import { Box, Select, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomSearchField } from "../../Components/formComponents";
import { OrderTableCard } from "./OrderTableCard/OrderTableCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { IStoreState, useAppDispatch, useAppSelector } from "../../redux";
import { clearFlightMenuState, fetchFlightsMenuListWithoutArgsAsync } from "../../redux/airlineAndFlights";

export const OrdersTable = () => {
  const {
    data: flight_menu_list,
  } = useAppSelector((storeState: IStoreState) => storeState.airlinesAndFlights.flightMenu.flight_menu_list);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchFlightsMenuListWithoutArgsAsync());
    return () => {
      dispatch(clearFlightMenuState());
    };
  }, []);

  return (
    <PageContainer title="Orders" description="Orders page">
      <Box>
        <CustomSearchField />
        <Box>
          <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={7}
          >
            <Typography variant="h2">Kitchen Orders</Typography>
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"25%"}
            >
              <Select fullWidth />
              <Select fullWidth />
            </Stack>
          </Stack>
        </Box>

        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
          style={{
            height: "65vh",
            overflowY: "scroll",
            marginTop: "18px",
            paddingRight: "12px",
          }}
        >
          <Masonry gutter="12px">
            {flight_menu_list.map((order, o_idx) => {
              return (<OrderTableCard key={o_idx} order={order} />)
            })}
          </Masonry>
        </ResponsiveMasonry>
      </Box>
    </PageContainer>
  );
};
