import { createSlice } from "@reduxjs/toolkit";
import { defaultTablesLayoutState } from "./tables.state";
import { getTablesDataFormLocalStore, setTablesDataInLocalStore } from "./tables.localStore";
import { fetchSingleTableLayoutAsync } from "./tables.actions";
import { LoadState } from "../../../constants/enum";


const tablesLayoutSlice = createSlice({
    initialState: defaultTablesLayoutState,
    name: "reservations",
    reducers: {
        getTablesListSync: (state) => {
            const data = getTablesDataFormLocalStore()
            state.single_tables_layout.data.tables = data
        },
        reserveTableByNumberSync: (state, action) => {
            const tableNumber = action.payload
            const newTablesList = state.single_tables_layout.data.tables.map((table) => {
                if (table.data.tableNumber === tableNumber) {
                    return {
                        ...table,
                        data: {
                            ...table.data,
                            availability: "RESERVED" as any
                        }
                    }
                }
                return table
            })
            state.single_tables_layout.data.tables = newTablesList
            setTablesDataInLocalStore(state.single_tables_layout.data.tables)
        },
        checkInTableByNumberSync: (state, action) => {
            const tableId = action.payload
            const newTablesList = state.single_tables_layout.data.tables.map((table) => {
                if (table.data.tableNumber === tableId) {
                    return {
                        ...table,
                        data: {
                            ...table.data,
                            availability: "CHECKED_IN" as any
                        }
                    }
                }
                return table
            })
            state.single_tables_layout.data.tables = newTablesList
            setTablesDataInLocalStore(state.single_tables_layout.data.tables)
        },
        clearTablesLayoutState: (state) => {
            return defaultTablesLayoutState
        },

        getSingleTablesInfoSync: (state, action) => {
            const data = getTablesDataFormLocalStore()
            const tableInfo = data.find((table) => table.id === action.payload)
            if (tableInfo) {
                state.selected_table = tableInfo
            }
        },
    },
    extraReducers: (builder) => {
        //############################# Multiple Product Categories ######################################
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.pending, (state, action) => {
        //     state.category_list.loading = LoadState.pending
        // })
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.fulfilled, (state, action) => {
        //     state.category_list.loading = LoadState.succeeded
        //     state.category_list.data = action.payload.data
        //     state.category_list.totalRecords = action.payload.recordCount
        //     state.category_list.error = null
        // })
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.rejected, (state, action) => {
        //     state.category_list.error = action.error.message as string
        // })


        // #################################### Single Table Layout ##############################################
        builder.addCase(fetchSingleTableLayoutAsync.pending, (state, action) => {
            state.single_tables_layout.loading = LoadState.pending
        })
        builder.addCase(fetchSingleTableLayoutAsync.fulfilled, (state, action) => {
            state.single_tables_layout.loading = LoadState.succeeded
            state.single_tables_layout.data = action.payload
            state.single_tables_layout.error = null
        })
        builder.addCase(fetchSingleTableLayoutAsync.rejected, (state, action) => {
            state.single_tables_layout.error = action.error.message as string
        })
    },
});

export const tablesLayoutReducer = tablesLayoutSlice.reducer;
export const { getTablesListSync,
    reserveTableByNumberSync, checkInTableByNumberSync,
    clearTablesLayoutState,
    getSingleTablesInfoSync,


} = tablesLayoutSlice.actions;
