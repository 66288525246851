import moment from "moment";
import { axios_api } from "../../api/axios-api"
import { IAirlineRevenue, IAirlineRevenueChart, IBarChart } from "./Dashboard.types";
import { calculateAirlineRevenue, calculateRevenueOrFoodCostForBarChart } from "./Dashboard.helpers";



export const fetchRevenueAndFoodCostListAsync = (airline_or_flight: "airline" | "flight") => new Promise<IBarChart[]>((resolve, reject) => {
    const today = moment().format('YYYY-MM-DD')
    const sevenDaysBefore = moment().subtract(6, "week").subtract(1, "day").format('YYYY-MM-DD')
    axios_api.get(`/airlines/get-cost-of-food?airline_or_flight=${airline_or_flight}&from_date=${sevenDaysBefore}&to_date=${today}`).then((response) => {
        resolve(calculateRevenueOrFoodCostForBarChart(airline_or_flight, response.data.data))
    }).catch((error) => reject(error))
})



export const fetchAirlinesRevenueAsync = () => new Promise<IAirlineRevenueChart>(async (resolve, reject) => {
    try {
        const today = moment().format('YYYY-MM-DD')
        const yesterday = moment().subtract(1, "day").format('YYYY-MM-DD')
        const sevenDaysBefore = moment().subtract(6, "week").subtract(1, "day").format('YYYY-MM-DD')
        const oneMonthBefore = moment().subtract(1, "month").subtract(1, "day").format('YYYY-MM-DD')

        const dailyRevenue = await axios_api.get(`/airlines/get-cost-of-food?airline_or_flight=airline&from_date=${today}&to_date=${today}`)
        const yesterdaysRevenue = await axios_api.get(`/airlines/get-cost-of-food?airline_or_flight=airline&from_date=${yesterday}&to_date=${yesterday}`)
        const weeklyRevenue = await axios_api.get(`/airlines/get-cost-of-food?airline_or_flight=airline&from_date=${sevenDaysBefore}&to_date=${today}`)
        const monthlyRevenue = await axios_api.get(`/airlines/get-cost-of-food?airline_or_flight=airline&from_date=${oneMonthBefore}&to_date=${today}`)
        resolve({
            today: calculateAirlineRevenue(dailyRevenue.data.data),
            yesterday: calculateAirlineRevenue(yesterdaysRevenue.data.data),
            current_week: calculateAirlineRevenue(weeklyRevenue.data.data),
            current_month: calculateAirlineRevenue(monthlyRevenue.data.data)
        })
    } catch (error) {
        reject(error)
    }
})


