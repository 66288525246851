import React from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { ItemCard, StandardCard } from "../../../../Components/Card";
import {
  CustomEditButton,
  CustomRoundButton,
} from "../../../../Components/CustomButton";
import PageContainer from "../../../../Components/PageContainer/PageContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthProvider } from "../../../../providers";
import { RightOrderCard } from "../../../Menu/RightOrderCard/RightOrderCard";
import { useFormik } from "formik";
import { FLIGHT_MENU_ROUTES } from "../../AirlineAndFlights.constants";
import { AirlineMenuListTable } from "../../Airlines/AirlineMenu/MenuCard/AirlineMenuListTable";
import { fetchSingleFlightMenuAsync, upsertSingleFlightMenuAsync } from "../../../../redux/airlineAndFlights";
import { FlightAutoSearch } from "../AutoSearch/FlightAutoSearch";
import { AirlineAutoSearch } from "../../Airlines/AutoSearch/AirlineAutoSearch";
import { useAppDispatch, useAppSelector } from "../../../../redux";

export const ManageSingleFlightMenu: React.FC = () => {
  const { uuid: flight_menu_uuid } = useParams() as {
    uuid?: string;
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authInfo } = useAuthProvider()

  const { data: flightMenuInfo, loading, error } = useAppSelector((state) => state.airlinesAndFlights.flightMenu.single_flight_menu);
  const [saveLoading, setSaveLoading] = React.useState(false);

  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: flightMenuInfo,
    validate: (values) => {
      const errors: any = {};
      if (!values.airline_uuid) {
        errors.airline_uuid = "*This field is required.";
      }
      if (!values.flight_uuid) {
        errors.flight_uuid = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      console.log("handle submit values ===>", values)
      setSaveLoading(true);
      dispatch(
        upsertSingleFlightMenuAsync(values)
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(`${FLIGHT_MENU_ROUTES}`)
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  React.useEffect(() => {
    if (flight_menu_uuid) {
      dispatch(fetchSingleFlightMenuAsync(flight_menu_uuid));
    }
  }, [flight_menu_uuid]);

  React.useEffect(() => {
    setValues(flightMenuInfo);
  }, [flightMenuInfo]);

  React.useEffect(() => {
    let subTotal = 0;
    values.item_list.forEach((item) => {
      subTotal += (Number(item.item_price) || 0);
    });
    const totalTax = Number((subTotal * 0.18).toFixed(4))

    setValues({
      ...values,
      sub_total: subTotal,
      tax_ten_percent: totalTax,
      total: (subTotal + totalTax)
    })
  }, [values.item_list]);

  // const SubTotal = React.useMemo(() => {
  //   return flightMenuInfo.item_list.reduce((acc, item) => {
  //     return acc + item.item_price * item.item_quantity;
  //   }, 0);
  // }, [flightMenuInfo.item_list]);

  // const tax = React.useMemo(() => {
  //   return SubTotal * 0.1;
  // }, [SubTotal]);

  return (
    <PageContainer title="Menu" description="Menu page">
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={6} md={12} lg={8}>
            <Stack spacing={2} mb={2} direction={"row"} justifyContent={"flex-start"}>
              <AirlineAutoSearch
                name="airline_name"
                label="Airline Name"
                value={{
                  airline_uuid: values.airline_uuid as string,
                  airline_name: values.airline_name as string,
                }}
                onSelect={(newValue) => {
                  setValues({
                    ...values,
                    airline_uuid: newValue.airline_uuid,
                    airline_name: newValue.airline_name,
                  });
                }}
                error={errors.airline_uuid}
              />
              <FlightAutoSearch
                name="flight_name"
                label="Flight Number"
                airlineUUID={values.airline_uuid || undefined}
                value={{
                  flight_uuid: values.flight_uuid as string,
                  flight_name: values.flight_number as string,
                }}
                onSelect={(newValue) => {
                  setValues({
                    ...values,
                    flight_uuid: newValue.flight_uuid,
                    flight_number: newValue.flight_number,
                  });
                }}
                error={errors.flight_uuid}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={12} lg={4}>
            <Stack spacing={2} mb={1} direction={"row"} justifyContent={"space-between"}>
              <Box>
                <Typography variant="h4" fontWeight={"bold"} letterSpacing={0.5}>
                  {`Flight No. ${values.flight_number}`}
                </Typography>
                <Typography variant="h6" color={"#595959"} fontWeight={"500"}>
                  {authInfo.name}
                </Typography>
              </Box>
              <CustomEditButton />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={12} lg={8}>
            <AirlineMenuListTable
              airline_uuid={values.airline_uuid || ""}
              selectedItems={values.item_list}
              onChange={(newItems) => setFieldValue("item_list", newItems)} />
          </Grid>

          <Grid item xs={12} sm={6} lg={4} sx={{
            minHeight: '80vh',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"

          }}>
            {values.item_list.length > 0 ? (
              <>
                <Box
                  sx={{
                    height: "54vh",
                    overflowY: "scroll",
                    pr: 2,
                    mb: 3,
                  }}
                >
                  {values.item_list.map((item, index) => (
                    <ItemCard
                      key={index}
                      incrementalNumber={index + 1}
                      name={item.item_name}
                      price={item.item_price}
                      quantity={item.item_quantity}
                    />
                  ))}
                </Box>
                <StandardCard
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={1}
                  >
                    <Typography variant="h5">Sub Total</Typography>
                    <Typography variant="h5" fontWeight={"bold"}>
                      ₹{values.sub_total}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={1}
                  >
                    <Typography variant="h5">Tax 18%</Typography>
                    <Typography variant="h5" fontWeight={"bold"}>
                      ₹{values.tax_ten_percent}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                  >
                    <Typography variant="h3" fontWeight={"bold"}>
                      Total
                    </Typography>
                    <Typography variant="h3" fontWeight={"bold"}>
                      ₹{values.total}
                    </Typography>
                  </Stack>

                  {/* <Typography variant="h5">Payment Method</Typography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    mt={2}
                    spacing={2}
                  >
                    <CustomPaymentTypeButton
                      icon={<BiSolidDollarCircle fontSize={"2rem"} />}
                      label="Cash"
                    />
                    <CustomPaymentTypeButton
                      icon={<FaCreditCard fontSize={"2rem"} />}
                      label="Debit Card"
                    />
                    <CustomPaymentTypeButton
                      icon={<FaWallet fontSize={"2rem"} />}
                      label="E-Wallet"
                    />
                  </Stack> */}

                  <CustomRoundButton sx={{ mt: 1 }} type="submit" title="Place Order" disabled={saveLoading} />
                </StandardCard>
              </>
            ) : (
              <RightOrderCard />
            )}
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};
