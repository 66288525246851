// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  font-family: "Quicksand", sans-serif !important;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(190, 188, 188); 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(149, 148, 148); 
  border-radius: 10px;
  behavior: smooth;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(149, 148, 148); 
}
#file_input {
  display: none;
}

.input_file_text {
  cursor: pointer;
  border-radius: 25px;
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  color: black;
  font-weight: bold;
  background-color: #CFDEDB;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,+CAA+C;AACjD;AACA;EACE,SAAS;EACT;;iBAEe;EACf,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA,UAAU;AACV;EACE,4CAA4C;EAC5C,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA,oBAAoB;AACpB;EACE,8BAA8B;AAChC;AACA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;AAC3B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');\n*{\n  font-family: \"Quicksand\", sans-serif !important;\n}\nbody {\n  margin: 0;\n  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif; */\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n::-webkit-scrollbar {\n  width: 8px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 2px rgb(190, 188, 188); \n  border-radius: 10px;\n}\n \n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: rgb(149, 148, 148); \n  border-radius: 10px;\n  behavior: smooth;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: rgb(149, 148, 148); \n}\n#file_input {\n  display: none;\n}\n\n.input_file_text {\n  cursor: pointer;\n  border-radius: 25px;\n  padding: 5px 20px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  font-size: 18px;\n  color: black;\n  font-weight: bold;\n  background-color: #CFDEDB;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
