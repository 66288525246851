import { Navigate } from "react-router-dom";
import { DASHBOARD_ROUTES } from "./Dashboard.constants";
import { Dashboard } from "./Dashboard";
import { INVENTORY_ROUTE } from "../Inventory/Inventory.constants";

export const dashboardRoutes = [
    {
        path: "/",
        index: true,
        exact: true,
        element: <Navigate to={DASHBOARD_ROUTES} replace />,
    },
    {
        path: "/pos",
        index: true,
        exact: true,
        element: <Navigate to={INVENTORY_ROUTE} replace />,
    },
    {
        path: DASHBOARD_ROUTES,
        exact: true,
        element: <Dashboard />,
    },
] 