import { LoadState } from "../../constants/enum";
import { IOrder, IOrderState } from "./orders.types";

export const defaultOrder: IOrder = {
    order_uuid: null,
    order_number: "",
    table_uuid: null,
    table_number: null,
    menu_items: [],
    sub_total: 0,
    total_tax: 0,
    total_amount: 0,
    order_type: "TAKE_AWAY",
    payment_mode: "NONE",
    is_paid: false,
    is_served: false,
    is_ready_to_serve: false
}


export const defaultOrderState: IOrderState = {
    orders_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_order: {
        loading: LoadState.idle,
        data: defaultOrder,
        error: null,
    }
}