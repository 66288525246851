import React from "react";
import { CategoryCards } from "./CategoryCards/CategoryCards";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { ItemCard, ProductCard, StandardCard } from "../../Components/Card";
import { CustomSearchField } from "../../Components/formComponents";
import { RightOrderCard } from "./RightOrderCard/RightOrderCard";
import {
  CustomEditButton,
  CustomPaymentTypeButton,
  CustomRoundButton,
} from "../../Components/CustomButton";
import { MenuBelowCard } from "../../Components/MenuBelowCard/MenuBelowCard";
import { CustomDivider } from "../../Components/CustomDivider/CustomDivider";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { useParams } from "react-router-dom";
import { removeOrderSync, upsertOrderSync } from "../../redux/Order/OrderSlice";
import { BiSolidDollarCircle } from "react-icons/bi";
import { FiCreditCard } from "react-icons/fi";
import { FaCreditCard, FaWallet } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../../redux";

export const Menu: React.FC = () => {
  const { tableNo } = useParams<{ tableNo: string }>();

  const { list } = useAppSelector((state) => state.order);
  const dispatch = useAppDispatch();

  const handleAddOrder =
    (quantity: number, name: string, price: number) => () => {
      dispatch(upsertOrderSync({ quantity, name, price }));
    };

  const handleRemoveOrder = (name: string, price: number) => () => {
    dispatch(removeOrderSync({ name, price }));
  };

  const SubTotal = React.useMemo(() => {
    return list.reduce((acc, item) => {
      return acc + item.price * item.quantity;
    }, 0);
  }, [list]);

  const tax = React.useMemo(() => {
    return SubTotal * 0.1;
  }, [SubTotal]);

  return (
    <PageContainer title="Menu" description="Menu page">
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={6} lg={8}>
          <CustomSearchField />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            <Box>
              <Typography variant="h4" fontWeight={"bold"} letterSpacing={0.5}>
                {tableNo ? `Table ${tableNo}` : "Table 1"}
              </Typography>
              <Typography variant="h6" color={"#595959"} fontWeight={"500"}>
                Lesic K.
              </Typography>
            </Box>
            <CustomEditButton />
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={12} lg={8}>
          <CategoryCards />
          <CustomDivider borderColor="grey" sx={{ my: 2 }} />
          <Grid
            container
            spacing={1.5}
            height={"36vh"}
            sx={{ overflowY: "scroll", pr: 2 }}
          >
            <Grid item xs={12} sm={6} md={3} width={"100%"}>
              <ProductCard
                title={"Fish and Cups"}
                price={"$80"}
                bgIsPurple
                onAddItem={handleAddOrder(1, "Fish and Cups", 80)}
                onRemoveItem={handleRemoveOrder("Fish and Cups", 80)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} width={"100%"}>
              <ProductCard
                title={"Burger"}
                price={"$50"}
                bgIsPurple={false}
                onAddItem={handleAddOrder(1, "Burger", 50)}
                onRemoveItem={handleRemoveOrder("Burger", 50)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} width={"100%"}>
              <ProductCard
                title={"Samosa"}
                price={"$10"}
                bgIsPurple={false}
                onAddItem={handleAddOrder(1, "Samosa", 10)}
                onRemoveItem={handleRemoveOrder("Samosa", 10)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} width={"100%"}>
              <ProductCard
                title={"Idli"}
                price={"$20"}
                bgIsPurple={false}
                onAddItem={handleAddOrder(1, "Idli", 20)}
                onRemoveItem={handleRemoveOrder("Idli", 20)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} width={"100%"}>
              <ProductCard
                title={"Vada Paav"}
                price={"$5"}
                bgIsPurple={false}
                onAddItem={handleAddOrder(1, "Vada Paav", 5)}
                onRemoveItem={handleRemoveOrder("Vada Paav", 5)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} width={"100%"}>
              <ProductCard
                title={"Utthapam"}
                price={"$30"}
                bgIsPurple={false}
                onAddItem={handleAddOrder(1, "Utthapam", 30)}
                onRemoveItem={handleRemoveOrder("Utthapam", 30)}
              />
            </Grid>
          </Grid>

          <CustomDivider borderColor="grey" sx={{ my: 2 }} />

          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={6} md={4}>
              <MenuBelowCard />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <MenuBelowCard />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <MenuBelowCard />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          {list.length > 0 ? (
            <>
              <Box
                sx={{
                  height: "36vh",
                  overflowY: "scroll",
                  pr: 2,
                  mb: 3,
                }}
              >
                {list.map((item, index) => (
                  <ItemCard
                    key={index}
                    incrementalNumber={index + 1}
                    name={item.name}
                    price={item.price}
                    quantity={item.quantity}
                  />
                ))}
              </Box>
              <StandardCard
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "calc(100% - 36vh)",
                }}
              >
                <Box>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={2}
                  >
                    <Typography variant="h5">Sub Total</Typography>
                    <Typography variant="h5" fontWeight={"bold"}>
                      ${SubTotal}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mb={2}
                  >
                    <Typography variant="h5">Tax 10%</Typography>
                    <Typography variant="h5" fontWeight={"bold"}>
                      ${tax}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={2}
                  >
                    <Typography variant="h3" fontWeight={"bold"}>
                      Total
                    </Typography>
                    <Typography variant="h3" fontWeight={"bold"}>
                      ${SubTotal + tax}
                    </Typography>
                  </Stack>
                </Box>

                <Stack>
                  <Typography variant="h5">Payment Method</Typography>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    mt={2}
                    spacing={2}
                  >
                    <CustomPaymentTypeButton
                      icon={<BiSolidDollarCircle fontSize={"2rem"} />}
                      label="Cash"
                    />
                    <CustomPaymentTypeButton
                      icon={<FaCreditCard fontSize={"2rem"} />}
                      label="Debit Card"
                    />
                    <CustomPaymentTypeButton
                      icon={<FaWallet fontSize={"2rem"} />}
                      label="E-Wallet"
                    />
                  </Stack>

                  <Stack mt={3}>
                    <CustomRoundButton title="Place Order" />
                  </Stack>
                </Stack>
              </StandardCard>
            </>
          ) : (
            <RightOrderCard />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};
