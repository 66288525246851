import React, { useState } from 'react'
import { ControlledCustomSelect } from '../../../../Components/formComponents'
import { clearCompanyListSync, fetchCompanyListAsync } from '../../../../redux/settings/company';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { SelectChangeEvent } from '@mui/material';
import { fetchOutletsListAsync } from '../../../../redux/settings/outlets';
import { fetchRegistersListForSingleOutletAsync, fetchSingleOutletRegisterAsync } from '../../../../redux/settings/outlet-registers';

interface IRegistersDropDownProps {
    outletUUID?: string;
    disabled?: boolean;
    value: string | null
    onChange: (data: string) => void
    error?: string
}
export const RegistersDropDown: React.FC<IRegistersDropDownProps> = ({
    outletUUID,
    disabled,
    value,
    onChange,
    error
}) => {
    const {
        data: registersData,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.registers.registers_list);
    const dispatch = useAppDispatch();

    const [registersList, setRegistersList] = useState<{ label: string, value: string | number }[]>([])

    React.useEffect(() => {
        let payload: any = {
            page: 1,
            rowsPerPage: 1000,
        }
        if (outletUUID) {
            payload.outlet_uuid = outletUUID
        }
        dispatch(
            fetchRegistersListForSingleOutletAsync(payload),
        );
    }, [outletUUID]);

    React.useEffect(() => {
        const newList = registersData.map((data) => ({ label: data.register_name || "", value: data.register_uuid || "" }))
        setRegistersList(newList)
    }, [registersData]);

    return (
        <ControlledCustomSelect
            label="Select Register"
            disabled={disabled}
            options={registersList}
            value={value}
            onChange={(evt) => onChange(evt.target.value as string)}
            sx={{ minWidth: 120 }}
            fullWidth size="small"
            error={error ? true : false}
            helperText={error}
        />
    )
}
