import { Box, Paper, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import { IStandardCardProps } from "./interface/interface";

export const StandardCard: React.FC<IStandardCardProps> = (props) => {
  const { heading, rightHeading, sx, variant = "normal", fullHeight } = props;

  const isNode = React.isValidElement(rightHeading);

  return (
    <Paper
      // square
      elevation={1}
      sx={{
        margin: 0,
        width: "100%",
        height: fullHeight ? "100%" : "auto",
        padding: variant === "normal" ? "20px" : "10px",
        // bgcolor: "#272B2C",
        // boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
        ...sx,
      }}
    >
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems="center"
        mb={heading ? 2 : 0}
      >
        {heading ? (
          <Typography variant="h2" fontWeight={"700"}>
            {heading}
          </Typography>
        ) : (
          <></>
        )}
        {isNode && <Box>{rightHeading}</Box>}
        {!isNode && (
          <Typography variant="body1" fontWeight={"400"}>
            {rightHeading}
          </Typography>
        )}
      </Box>
      {/* {heading && <Divider sx={{ mt: 2, mb: 2 }} />} */}
      {props.children}
    </Paper>
  );
};
