import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../redux';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { ItemCard, StandardCard } from '../../../../../Components/Card';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { FaCreditCard, FaHourglassEnd, FaWallet } from 'react-icons/fa';
import { CustomEditButton, CustomPaymentTypeButton, CustomRoundButton } from '../../../../../Components/CustomButton';
import { useAuthProvider } from '../../../../../providers';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { RUPEE_SYMBOL } from '../../../../../constants/constants';
import { POS_ORDERS_ROUTE } from '../../MenuAndOrders.constants';
import { CashPaymentDialog } from './CashPaymentDialog';
import { IOrder, fetchSingleOrderAsync, upsertSingleOrderAsync } from '../../../../../redux/orders';
import { isCardPayMode, isCashPayMode, isOnlinePayMode } from './Orders.helpers';
import { isNotNull } from '../../../../../helpers/isNotNull';
import { CardPaymentDialog } from './CardPaymentDialog';
import { OrderItemCard } from './components/OrderItemCard';

export const MenuOrderLayout = () => {
    const { uuid: order_uuid } = useParams<{ uuid: string }>();
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { authInfo } = useAuthProvider();

    const { data: singleOrderState } = useAppSelector((state) => state.orders.single_order)
    const [openCashDialog, setOpenCashDialog] = React.useState<IOrder | null>(null);
    const [openCardDialog, setOpenCardDialog] = React.useState<IOrder | null>(null);
    const [openOnlineDialog, setOpenOnlineDialog] = React.useState<IOrder | null>(null);
    const [saveLoading, setSaveLoading] = React.useState(false);

    const {
        values,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: { ...singleOrderState },
        validate: (values) => {
            const errors: any = {};
            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);
            dispatch(
                upsertSingleOrderAsync(values)
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    navigate(`${POS_ORDERS_ROUTE}`)
                }
            }).finally(() => {
                setSaveLoading(false);
            });
        },
    });

    React.useEffect(() => {
        if (!order_uuid) return
        dispatch(fetchSingleOrderAsync(order_uuid));
    }, [order_uuid]);

    // React.useEffect(() => {
    //     setValues({ ...singleOrderState })
    // }, [singleOrderState])

    React.useEffect(() => {
        let newState = { ...singleOrderState }
        const tableUUID = searchParams.get('tableUUID');
        const tableNumber = searchParams.get('tableNumber');
        if (tableUUID && tableNumber) {
            newState = {
                ...newState,
                order_type: "DINE_IN",
                table_uuid: tableUUID,
                table_number: Number(tableNumber)
            }
        }
        console.log("React.useEffect(()  =>", newState)
        setTimeout(() => {
            setValues(newState)
        }, 100);
    }, [searchParams, singleOrderState])


    React.useEffect(() => {
        console.log("values.menu_items ==>",values.menu_items)
        let subTotal = 0;
        let totalTax = 0;
        values.menu_items.forEach((item) => {
            const currentSubTotal = isNotNull(item.product_price) * isNotNull(item.quantity)
            totalTax += (currentSubTotal * isNotNull(item.tax_percentage)) / 100;
            subTotal += currentSubTotal
            item.options.forEach((option) => {
                const optionSubTotal = isNotNull(option.modifier_price)
                totalTax += (optionSubTotal * isNotNull(option.tax_percentage)) / 100;
                subTotal += optionSubTotal
            });
        });

        setValues({
            ...values,
            sub_total: subTotal,
            total_tax: Number(totalTax.toFixed(4)),
            total_amount: (subTotal + totalTax)
        })
    }, [values.menu_items]);


    const handelOrderAndCheckout = () => {
        if (isCashPayMode(values.payment_mode)) return setOpenCashDialog(values)
        if (isCardPayMode(values.payment_mode)) return setOpenCardDialog(values)
        if (isOnlinePayMode(values.payment_mode)) return setOpenOnlineDialog(values)

    }

    const placeOrderWithoutPayment = () => {

    }

    console.log("values.menu_items =>", values.payment_mode)

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Stack spacing={2} direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <Box>
                    <Typography variant="h4" fontWeight={"bold"} letterSpacing={0.5}>
                        {values.order_type === "TAKE_AWAY" ? "Take Away Order" : `Table ${values.table_number}`}
                    </Typography>
                    <Typography variant="h6" color={"#595959"} fontWeight={"500"}>
                        {authInfo.name}
                    </Typography>
                </Box>
                <Typography variant="h4" fontWeight={"bold"} letterSpacing={0.5}>
                    {`Order No.  ${values.order_number}`}
                </Typography>
            </Stack>
            <Box sx={{ mt: 1, height: "85vh" }}>
                {values.menu_items.length > 0 ? (
                    <>
                        <Box
                            sx={{
                                height: "36vh",
                                overflowY: "scroll",
                                pr: 2,
                                mb: 3,
                            }}
                        >
                            {values.menu_items.map((item, index) => (
                                <OrderItemCard
                                    key={index}
                                    index={index + 1}
                                    product={item}
                                />
                            ))}
                        </Box>
                        <StandardCard
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "calc(100% - 36vh)",
                            }}
                        >
                            <Box>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={2}
                                >
                                    <Typography variant="h5">Sub Total</Typography>
                                    <Typography variant="h5" fontWeight={"bold"}>
                                        {`${RUPEE_SYMBOL}${values.sub_total}`}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mb={2}
                                >
                                    <Typography variant="h5">Tax</Typography>
                                    <Typography variant="h5" fontWeight={"bold"}>
                                        {`${RUPEE_SYMBOL}${values.total_tax}`}
                                    </Typography>
                                </Stack>
                                <Divider />
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    mt={2}
                                >
                                    <Typography variant="h3" fontWeight={"bold"}>
                                        Total
                                    </Typography>
                                    <Typography variant="h3" fontWeight={"bold"}>
                                        {`${RUPEE_SYMBOL}${values.total_amount}`}
                                    </Typography>
                                </Stack>
                            </Box>

                            <Stack>
                                <Typography variant="h5">Payment Method</Typography>
                                <Stack
                                    direction={"row"}
                                    alignItems={"flex-start"}
                                    justifyContent={"space-evenly"}
                                    mt={2}
                                    spacing={2}
                                >
                                    <CustomPaymentTypeButton
                                        icon={<BiSolidDollarCircle fontSize={"2rem"} />}
                                        label="Cash"
                                        sx={{
                                            background: isCashPayMode(values.payment_mode) ? "#373837" : "inherit",
                                            borderColor: isCashPayMode(values.payment_mode) ? "#373837" : "inherit"
                                        }}
                                        onClick={() => {
                                            setFieldValue("payment_mode", isCashPayMode(values.payment_mode) ? "NONE" : "CASH")
                                        }}
                                    />
                                    <CustomPaymentTypeButton
                                        icon={<FaCreditCard fontSize={"2rem"} />}
                                        label="Credit/Debit Card"
                                        sx={{
                                            background: isCardPayMode(values.payment_mode) ? "#373837" : "inherit",
                                            borderColor: isCardPayMode(values.payment_mode) ? "#373837" : "inherit"
                                        }}
                                        onClick={() => {
                                            setFieldValue("payment_mode", isCardPayMode(values.payment_mode) ? "NONE" : "CREDIT_CARD")
                                        }}
                                    />
                                    <CustomPaymentTypeButton
                                        icon={<FaWallet fontSize={"2rem"} />}
                                        label="E-Wallet"
                                        sx={{
                                            background: values.payment_mode === "G_PAY/APPLE_PAY" ? "#373837" : "inherit",
                                            borderColor: values.payment_mode === "G_PAY/APPLE_PAY" ? "#373837" : "inherit"
                                        }}

                                    />
                                </Stack>

                                <Stack mt={3}>
                                    {values.payment_mode === "NONE" ?
                                        <CustomRoundButton
                                            type='submit'
                                            title="Place Order & Pay Later"
                                            fullWidth
                                            onClick={placeOrderWithoutPayment}
                                        />
                                        :
                                        <CustomRoundButton
                                            title="Place Order & Checkout"
                                            fullWidth
                                            onClick={handelOrderAndCheckout}
                                        />
                                    }
                                </Stack>
                            </Stack>
                        </StandardCard>
                    </>
                ) : (
                    <EmptyOrderCard />
                )}
            </Box>

            {openCashDialog &&
                <CashPaymentDialog
                    open={true}
                    orderInfo={openCashDialog}
                    onClose={() => setOpenCashDialog(null)}
                />
            }
            {openCardDialog &&
                <CardPaymentDialog
                    open={true}
                    orderInfo={openCardDialog}
                    onClose={() => setOpenCardDialog(null)}
                />
            }
        </Box>
    )
}



export const EmptyOrderCard: React.FC = () => {
    return (
        <StandardCard fullHeight>
            <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%", pb: 2 }}>
                <Stack
                    direction={"column"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    margin={"auto"}
                >
                    <FaHourglassEnd fontSize={"2.2rem"} />
                    <Typography variant="h4" fontWeight={"700"}>
                        No Items Added
                    </Typography>
                </Stack>
                <CustomRoundButton title="Place Order" disabled fullWidth />
            </Box>
        </StandardCard>
    );
};
