import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultPosOrderState } from "./pos-orders.state";
import { getPosOrdersFormLocalStore, setPosOrdersInLocalStore } from "./pos-orders.localstore";
import { IPosOrder } from "./pos-orders.types";
import { IPosMenuProduct } from "../pos-menu-product";



const posOrdersSlice = createSlice({
    initialState: defaultPosOrderState,
    name: "pos-orders",
    reducers: {
        getAllPosOrdersListSync: (state) => {
            const data = getPosOrdersFormLocalStore()
            state.pos_orders_list.data = data
        },
        getSinglePosOrderSync: (state, action: PayloadAction<string>) => {
            const orderUUID = action.payload
            const isDataAvailable = getPosOrdersFormLocalStore().find((order) => order.order_uuid === orderUUID)
            if (isDataAvailable) {
                state.single_pos_order.data = isDataAvailable
            }
        },

        // insertItemIntoOrderSync: (state, action: PayloadAction<IPosMenuProduct>) => {
        //     const { product_uuid, product_name, product_price, tax_percentage } = action.payload;
        //     const isPresent = state.single_pos_order.data.menu_items.find((item) => item.product_uuid === product_uuid);
        //     if (isPresent) {
        //         state.single_pos_order.data.menu_items.map((item) => {
        //             if (item.product_uuid === product_uuid) {
        //                 item.quantity = (item.quantity || 0) + 1;
        //                 item.product_price = (item.product_price || 0) + product_price;
        //             }
        //             return item;
        //         });
        //     } else {
        //         state.single_pos_order.data.menu_items.push({
        //             product_uuid: product_uuid as string,
        //             product_name,
        //             product_price,
        //             tax_percentage,
        //             quantity: 1,
        //         });
        //     }
        // },
        // removeItemFromOrderSync: (state, action) => {
        //     const { product_uuid, product_price } = action.payload;
        //     const currentStateItems = [...state.single_pos_order.data.menu_items]
        //     const isPresent = currentStateItems.find((item) => item.product_uuid === product_uuid);
        //     if (isPresent) {
        //         currentStateItems.map((item) => {
        //             if (item.product_uuid === product_uuid) {
        //                 item.quantity = item.quantity - 1;
        //                 item.product_price = item.product_price - product_price;
        //             }
        //             return item;
        //         });
        //         console.log("currentStateItems ==?", currentStateItems)
        //         state.single_pos_order.data.menu_items = currentStateItems.filter((item) => item.quantity > 0);
        //     }
        // },

        upsertPosOrderInfoSync: (state, action: PayloadAction<IPosOrder>) => {
            if (action.payload.order_uuid) {
                const index = state.pos_orders_list.data.findIndex((res) => res.order_uuid === action.payload.order_uuid)
                state.pos_orders_list.data.splice(index, 1, action.payload)
                setPosOrdersInLocalStore(state.pos_orders_list.data)
            } else {
                state.pos_orders_list.data.push({
                    ...action.payload,
                    order_uuid: Date.now().toString(),
                    order_number: Date.now().toString(),
                })
                setPosOrdersInLocalStore(state.pos_orders_list.data)
            }
        },
        // deleteReservationsSync: (state, action) => {
        //     const reservation_uuid = action.payload.reservation_uuid
        //     const index = state.reservations_list.data.findIndex((res) => res.reservation_uuid === reservation_uuid)
        //     state.reservations_list.data.splice(index, 1)
        //     setReservationsInLocalStore(state.reservations_list.data)
        // },
        clearPosOrdersState: (state) => {
            return defaultPosOrderState
        },
    },
    extraReducers: (builder) => {
        //############################# Multiple Product Categories ######################################
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.pending, (state, action) => {
        //     state.category_list.loading = LoadState.pending
        // })
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.fulfilled, (state, action) => {
        //     state.category_list.loading = LoadState.succeeded
        //     state.category_list.data = action.payload.data
        //     state.category_list.totalRecords = action.payload.recordCount
        //     state.category_list.error = null
        // })
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.rejected, (state, action) => {
        //     state.category_list.error = action.error.message as string
        // })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const posOrdersReducer = posOrdersSlice.reducer;
export const {
    getAllPosOrdersListSync,
    getSinglePosOrderSync,
    upsertPosOrderInfoSync,
    clearPosOrdersState,
    // insertItemIntoOrderSync,
    // removeItemFromOrderSync
} = posOrdersSlice.actions;
