import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { IAirlineFlight } from "./flights.types";

// export const fetchAirlinesFlightsAsync = createAsyncThunk<{ recordCount: number, data: IAirlineFlight[] }, IQueryParams>(
//     'airlines/fetchAirlinesFlightsAsync', async (queryParams: IQueryParams, thunkAPI) => {
//         const searchQuery = getSearchQuery(queryParams);
//         const response = await axios_api.get(`/airlines/get-flight${searchQuery}`)
//         const data = response.data.data;
//         const count = response.data.totalRecords
//         return { recordCount: count, data: data }
//     },
// )

export interface IGetAirlineFlights extends IQueryParams {
    airline_uuid: string | null
}
export const fetchAirlinesFlightsAsync = createAsyncThunk<{ recordCount: number, data: IAirlineFlight[] }, IGetAirlineFlights>(
    'airlines/fetchAirlinesFlightsAsync', async (queryParams: IGetAirlineFlights, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        let get_url = `/airlines/get-flight${searchQuery}`;
        if (queryParams.airline_uuid) {
            get_url += `&airline_uuid=${queryParams.airline_uuid}`
        }
        const response = await axios_api.get(get_url)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchSingleAirlineFlightAsync = createAsyncThunk<IAirlineFlight, string>(
    'airlines/fetchSingleAirlineFlightAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`/airlines/get-flight?airline_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)


export const upsertSingleAirlineFlightAsync = createAsyncThunk(
    'airlines/upsertSingleAirlineFlightAsync', async (payload: IAirlineFlight, thunkAPI) => {
        const response = await axios_api.post(`/airlines/upsert-flight`, payload)
        thunkAPI.dispatch(fetchAirlinesFlightsAsync({
            columns: [],
            page: 1,
            status: "-1",
            value: "",
            rowsPerPage: 20,
            airline_uuid: null
        }))
        return response.data
    },
)