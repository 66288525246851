// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shape-node {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 1px solid #000;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.shape-node :global .react-flow__resize-control.handle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.rotateHandle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #3367d9;
  left: 50%;
  top: -30px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: alias;
}

.rotateHandle:after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 30px;
  background: #3367d9;
  left: 4px;
  top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ReactFlowShapes/components/shape-node/shape-node.styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,cAAc;EACd,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,QAAQ;AACV","sourcesContent":[".shape-node {\n  width: 100%;\n  height: 100%;\n  border-radius: 15px;\n  border: 1px solid #000;\n  background-color: #fff;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n.shape-node :global .react-flow__resize-control.handle {\n  width: 10px;\n  height: 10px;\n  border-radius: 100%;\n}\n\n.rotateHandle {\n  position: absolute;\n  width: 10px;\n  height: 10px;\n  background: #3367d9;\n  left: 50%;\n  top: -30px;\n  border-radius: 100%;\n  transform: translate(-50%, -50%);\n  cursor: alias;\n}\n\n.rotateHandle:after {\n  content: '';\n  display: block;\n  position: absolute;\n  width: 1px;\n  height: 30px;\n  background: #3367d9;\n  left: 4px;\n  top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
