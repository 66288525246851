import React from "react";
import { StandardCard } from "../../../Components/Card";
import { CustomSquareButton } from "../../../Components/CustomButton";
import { RiDeleteBin7Line } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomDivider } from "../../../Components/CustomDivider/CustomDivider";
import { IFlightMenu } from "../../../redux/airlineAndFlights";
import { useNavigate } from "react-router-dom";
import { ORDERS_ROUTE } from "../Orders.constants";

interface IOrderTableCardProps {
  order: IFlightMenu
}
export const OrderTableCard: React.FC<IOrderTableCardProps> = ({
  order
}) => {
  let navigate = useNavigate()

  const handleViewOrderClick = () => {
    navigate(`${ORDERS_ROUTE}/view/${order.menu_to_flight_uuid}`)
  }
  return (
    <StandardCard
      heading={order.airline_name || ""}
      rightHeading={`Flight No. ${order.flight_number}`}
      sx={{
        // mt: 2,
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
        // width: "32%"
      }}
    >
      <CustomDivider borderColor="white" />

      <Table>
        <TableHead>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>QT</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>Items</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>Price</Typography>
          </TableCell>
        </TableHead>
        <TableBody sx={{ minHeight: 300 }}>
          {order.item_list.map((order_item, Item_idx) => {
            return (
              <TableRow>
                <TableCell
                  sx={{
                    border: "none",
                    p: 1,
                  }}
                >
                  <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    p: 1,
                  }}
                >
                  <Typography fontWeight={"bold"}>{order_item.item_name}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    p: 1,
                  }}
                >
                  <Typography fontWeight={"bold"}>{`₹${order_item.item_price}`}</Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <CustomDivider borderColor="grey" />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={4}
      >
        <Typography fontWeight={"bold"}>Subtotal</Typography>
        <Typography fontWeight={"bold"}>{`₹${order.sub_total}`}</Typography>
      </Stack>

      <Stack gap={1} direction={"row"}>
        {/* <CustomSquareButton icon={<RiDeleteBin7Line fontSize={"1.5rem"} />} />
        <CustomSquareButton icon={<FiEdit3 fontSize={"1.5rem"} />} /> */}
        <CustomSquareButton title="View" onClick={handleViewOrderClick} />
        <CustomSquareButton title="Print" />
        <CustomSquareButton title="Done" />
      </Stack>
    </StandardCard>
  );
};
