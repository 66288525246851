import { createSlice } from "@reduxjs/toolkit";
import { defaultAirlineState } from "./airlines.state";
import { fetchAirlinesAsync, fetchAllAirlinesAsync, fetchSingleAirlinesAsync } from "./airlines.actions";
import { LoadState } from "../../../constants/enum";


const airlineSlice = createSlice({
    initialState: defaultAirlineState,
    name: "airlines",
    reducers: {
        clearAirlineState: (state) => {
            return defaultAirlineState
        }
    },
    extraReducers: (builder) => {
        //#################################### Multiple Airlines with no args ##############################################
        builder.addCase(fetchAllAirlinesAsync.pending, (state, action) => {
            state.airlines_list.loading = LoadState.pending
        })
        builder.addCase(fetchAllAirlinesAsync.fulfilled, (state, action) => {
            state.airlines_list.loading = LoadState.succeeded
            state.airlines_list.data = action.payload.data
            state.airlines_list.totalRecords = action.payload.recordCount
            state.airlines_list.error = null
        })
        builder.addCase(fetchAllAirlinesAsync.rejected, (state, action) => {
            state.airlines_list.error = action.error.message as string
        })

        //#################################### Multiple Airlines ##############################################
        builder.addCase(fetchAirlinesAsync.pending, (state, action) => {
            state.airlines_list.loading = LoadState.pending
        })
        builder.addCase(fetchAirlinesAsync.fulfilled, (state, action) => {
            state.airlines_list.loading = LoadState.succeeded
            state.airlines_list.data = action.payload.data
            state.airlines_list.totalRecords = action.payload.recordCount
            state.airlines_list.error = null
        })
        builder.addCase(fetchAirlinesAsync.rejected, (state, action) => {
            state.airlines_list.error = action.error.message as string
        })
        //#################################### Single Airline ##############################################
        builder.addCase(fetchSingleAirlinesAsync.pending, (state, action) => {
            state.single_airline.loading = LoadState.pending
        })
        builder.addCase(fetchSingleAirlinesAsync.fulfilled, (state, action) => {
            state.single_airline.loading = LoadState.succeeded
            state.single_airline.data = action.payload
            state.single_airline.error = null
        })
        builder.addCase(fetchSingleAirlinesAsync.rejected, (state, action) => {
            state.single_airline.error = action.error.message as string
        })
    },
});

export const airlinesReducer = airlineSlice.reducer;
export const { clearAirlineState } = airlineSlice.actions;
