import { LoadState } from "../../../constants/enum"
import { IPosMenuProduct, IPosMenuProductState } from "./pos-menu-product.types"



const dummy_products_list: IPosMenuProduct[] = [
    {
        product_uuid: "W&S^*E43449d8y",
        product_name: "Fish and Cups",
        product_price: 80,
        category_uuid: "HDW65DS45sda",
        category_name: "Breakfast",
        tax_percentage: 5,
    },
    {
        product_uuid: "W&S^*ESDtrteFy",
        product_name: "Burger",
        product_price: 50,
        category_uuid: "HDW65DS45sda",
        category_name: "Breakfast",
        tax_percentage: 5,
    },
    {
        product_uuid: "W&S^*ESbdgdgerDFy",
        product_name: "Samosa",
        product_price: 10,
        category_uuid: "HDW65DS45sda",
        category_name: "Breakfast",
        tax_percentage: 5,
    },



    {
        product_uuid: "W&S^*ESDfdggftFy",
        product_name: "Idli",
        product_price: 20,
        category_uuid: "HDW65DS45sdaEF",
        category_name: "Afternoon",
        tax_percentage: 5,
    },

    {
        product_uuid: "W&SKDr43tBDy",
        product_name: "Vada Paav",
        product_price: 5,
        category_uuid: "HDW65DS45sdaEF",
        category_name: "Afternoon",
        tax_percentage: 5,
    },
    {
        product_uuid: "W&DC^%87s7drrgSDFy",
        product_name: "Utthapam",
        product_price: 30,
        category_uuid: "HDW65DS45sdaEF",
        category_name: "Afternoon",
        tax_percentage: 5,
    },

];


export const defaultPosMenuProduct: IPosMenuProduct = {
    product_uuid: null,
    product_name: "",
    category_uuid: "",
    category_name: "",
    product_price: 0,
    tax_percentage: 0
}

export const defaultPosMenuProductState: IPosMenuProductState = {
    menu_products_list: {
        loading: LoadState.idle,
        data: [...dummy_products_list],
        totalRecords: 0,
        error: null,
    },
    single_product: {
        loading: LoadState.idle,
        data: defaultPosMenuProduct,
        error: null,
    }
}