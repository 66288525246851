import { LoadState } from "../../../constants/enum";
import { IAirline, IAirlineState } from "./airlines.types";


export const defaultAirline: IAirline = {
    airline_uuid: null,
    airline_name: null,
    status: "ACTIVE"
}

export const defaultAirlineState: IAirlineState = {
    airlines_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_airline: {
        loading: LoadState.idle,
        data: defaultAirline,
        error: null,
    }
}