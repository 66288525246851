import React from "react";

import { Button } from "@mui/material";
import { FaEdit } from "react-icons/fa";

export const CustomEditButton: React.FC = () => {
  return (
    <Button
      sx={{
        borderRadius: 20,
        p: 0,
        border: "1px solid #E0E0E0",
        py: 0,
        minWidth: 48,
      }}
    >
 
      <FaEdit fontSize={"1.3rem"}/>
    </Button>
  );
};
