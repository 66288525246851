import { ICustomSelectProps } from "../../../../Components/formComponents";

export const order_type_list:ICustomSelectProps["options"] = [
    {label: "Dine-In", value: "DINE_IN"},
    {label: "Take away", value: "TAKE_AWAY"},
]

export const order_Status_list:ICustomSelectProps["options"] = [
    {label: "Paid", value: "PAID"},
    {label: "Un-Paid", value: "UN_PAID"},
    {label: "Draft", value: "DRAFT"},
]