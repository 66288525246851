import React from "react";
import { Divider, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import { StandardChip } from "../CustomChip/StandardChip";

export const MenuBelowCard: React.FC = () => {
  return (
    <Stack
      direction={"row"}
      spacing={0.8}
      alignItems={"flex-start"}
      borderRight={1}
      px={0.4}
    >
      <Box
        sx={{
          border: "1px solid grey",
          borderRadius: 1,
          paddingY: 0.5,
          paddingX: 0.8,
          bgcolor: "white",
          color: "black",
        }}
      >
        <Typography variant="h3" fontWeight={"bold"}>
          T4
        </Typography>
      </Box>
      <Stack
        direction={"column"}
        gap={0.5}
        alignItems={"flex-start"}
        justifyContent={"center"}
      >
        <Typography variant="body1" fontWeight={"bold"} letterSpacing={0.5}>
          Lesic K.
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={"bold"}
          // letterSpacing={0.1}
          display={"flex"}
          alignItems={"center"}
          gap={0.2}
          justifyContent={"center"}
        >
          6 items <TrendingFlatOutlinedIcon fontSize="small" />
          kitchen
        </Typography>
      </Stack>
      <Box>
        <StandardChip label="IN-PROGRESS" />
      </Box>
      {/* <Divider orientation="vertical" sx={{height: "64px", color: "white"}} /> */}
    </Stack>
  );
};
