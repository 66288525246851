import { Box, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ControlledCustomSelect, CustomSearchField } from "../../../../Components/formComponents";
import { POSOrderTableCard } from "./POSOrderTableCard/POSOrderTableCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PageContainer from "../../../../Components/PageContainer/PageContainer";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import moment from "moment";
import { CustomDatePicker } from "../../../../Components/formComponents/CustomDatePicker";
import { IOrder, fetchOrdersListAsync } from "../../../../redux/orders";

export const POSOrdersTable = () => {
  const {
    data: posOrdersList,
  } = useAppSelector((storeState: IStoreState) => storeState.orders.orders_list);
  const dispatch = useAppDispatch();

  const [orderType, setOrderType] = useState("ALL")
  const [dateFilter, setDateFilter] = React.useState<string>("ALL");
  const [customDate, setCustomDate] = React.useState<string | null>(null);
  const [customDateDialog, setCustomDateDialog] = React.useState<boolean>(false);
  const [ordersList, setOrdersList] = useState<IOrder[]>([])

  React.useEffect(() => {
    dispatch(fetchOrdersListAsync());
  }, []);


  useEffect(() => {
    let newList = [...posOrdersList]
    if (orderType === "TAKE_AWAY") {
      newList = newList.filter((order) => order.order_type === "TAKE_AWAY" && !order.is_served)
    }
    if (orderType === "KITCHEN") {
      newList = newList.filter((order) => !order.is_paid && !order.is_served)
    }
    if (dateFilter === "ALL") {
      setOrdersList(newList)
    } else if (dateFilter === "CUSTOM") {
      setCustomDateDialog(true)
    } else {
      newList = newList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === dateFilter)
      setOrdersList(newList)
    }
  }, [orderType, dateFilter, posOrdersList])

  useEffect(() => {
    if (dateFilter === "CUSTOM" && customDate) {
      const newCustomDate = moment(customDate).format("yyyy-MM-DD")
      const newList = posOrdersList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === newCustomDate)
      setOrdersList(newList)
    }
  }, [customDate])




  return (
    <PageContainer title="Orders" description="Orders page">
      <Box>
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          mt={7}
        >
          <Typography variant="h2" sx={{ minWidth: 150 }}>POS Orders</Typography>
          <Box sx={{ minWidth: 180, maxWidth: 180 }}>
            <ControlledCustomSelect
              label="Order Type"
              options={[
                { label: "All Orders", value: "ALL" },
                { label: "Take Away", value: "TAKE_AWAY" },
                { label: "Kitchen", value: "KITCHEN" },
              ]}
              value={orderType}
              onChange={(evt) => setOrderType(evt.target.value as string)}
              fullWidth
              size="small" />
          </Box>
          <Box sx={{ minWidth: 180,maxWidth: 180 }}>
            <ControlledCustomSelect
              label="Search by Date"
              options={[
                { label: "All", value: "ALL" },
                { label: "Today", value: moment().format("yyyy-MM-DD") },
                { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                { label: "Custom", value: "CUSTOM" },
              ]}
              value={dateFilter}
              onChange={(evt) => setDateFilter(evt.target.value as string)}
              fullWidth
              size="small" />
          </Box>

          {customDateDialog &&
            <Box sx={{   minWidth: 180,maxWidth: 220 }}>
              <CustomDatePicker
                label="Select Date"
                fieldName="reservation_date"
                value={customDate || ""}
                onChange={(newValue, formattedDate) => {
                  setCustomDate(formattedDate);
                }}
                sx={{ maxWidth: 200 }}
                fullWidth
              />
            </Box>
          }
        </Stack>

        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
          style={{
            height: "65vh",
            overflowY: "scroll",
            marginTop: "18px",
            paddingRight: "12px",
          }}
        >
          <Masonry gutter="12px">
            {ordersList.map((order, o_idx) => {
              return (<POSOrderTableCard key={o_idx} order={order} orderType={orderType} />)
            })}
          </Masonry>
        </ResponsiveMasonry>
      </Box>
    </PageContainer>
  );
};
