import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { StandardCard } from "../../../Components/Card";
import { ControlledCustomSelect, OutlinedTextField } from "../../../Components/formComponents";
import { useNavigate, useParams } from "react-router-dom";
import { MARKETING_COUPON_CODES_ROUTE } from "../Marketing.constants";
import { clearCampaignStateSync, fetchSingleCampaignAsync, upsertSingleCampaignAsync } from "../../../redux/marketing/campaigns";
import { CustomButton } from "../../../Components/CustomButton";
import { generateCouponCodeAsync } from "../CouponCodes/CouponCodes.helpers";
import { CustomDatePicker } from "../../../Components/formComponents/CustomDatePicker";
import { ComposeNewMail } from "../../../Components/ComposeMail/ComposeNewMail";

export const ManageSingleCampaign = () => {
  const { uuid: campaignUUID } = useParams<{ uuid?: string }>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { data: singleCampaignUUIDInfo } = useAppSelector((storeState: IStoreState) => storeState.marketing.campaigns.single_campaign);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [couponCodeLoading, setCouponCodeLoading] = React.useState(false);

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...singleCampaignUUIDInfo },
    validate: (values) => {
      const errors: any = {};
      if (!values.campaign_name) {
        errors.campaign_name = "*This Field is required.";
      }
      if (!values.campaign_id) {
        errors.campaign_id = "*This Field is required.";
      }
      if (!values.campaign_start_date) {
        errors.campaign_start_date = "*This Field is required.";
      }
      if (!values.campaign_type) {
        errors.campaign_type = "*This Field is required.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleCampaignAsync(values),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(MARKETING_COUPON_CODES_ROUTE)
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });


  React.useEffect(() => {
    if (!campaignUUID) return
    dispatch(fetchSingleCampaignAsync(campaignUUID));
  }, [campaignUUID]);

  React.useEffect(() => {
    setValues(singleCampaignUUIDInfo);
  }, [singleCampaignUUIDInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCampaignStateSync())
    }
  }, []);

  const generateCouponCode = () => {
    setCouponCodeLoading(true)
    setTimeout(() => {
      setFieldValue("campaign_id", generateCouponCodeAsync())
      setCouponCodeLoading(false)
    }, 500)

  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Manage Campaign </Typography>
        <StandardCard
          heading="Campaign Information"
          rightHeading={
            <CustomButton
              sx={{ maxHeight: 42 }}
              variant="contained"
            // onClick={generateCouponCode}
            >
              End Campaign
            </CustomButton>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <OutlinedTextField
                label="Campaign Name"
                id="campaign_name"
                value={values.campaign_name}
                onChange={handleChange}
                error={errors.campaign_name}
              />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Stack direction="row" spacing={2}>
                <OutlinedTextField
                  label="Campaign Id"
                  id="campaign_id"
                  value={values.campaign_id}
                  onChange={handleChange}
                  error={errors.campaign_id}
                />
                <CustomButton
                  sx={{ maxHeight: 42 }}
                  variant="contained"
                  onClick={generateCouponCode}
                  endIcon={couponCodeLoading && <CircularProgress size={16} sx={{ color: "#212121" }} />}>
                  Generate
                </CustomButton>
              </Stack>
            </Grid> */}
            <Grid item xs={12} md={3}>
              <ControlledCustomSelect
                fullWidth
                label="Campaign Type"
                value={values.campaign_type}
                name="campaign_type"
                onChange={handleChange}
                options={[
                  { label: "Email", value: "EMAIL" },
                  { label: "SMS", value: "SMS" },
                  { label: "Whatsapp", value: "WHATSAPP" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <ControlledCustomSelect
                fullWidth
                label="Campaign Repeat"
                value={values.campaign_repeat}
                name="campaign_repeat"
                onChange={handleChange}
                options={[
                  { label: "Everyday", value: "EVERYDAY" },
                  { label: "Every week", value: "EVERY_WEEK" },
                  { label: "Every Month", value: "EVERY_MONTH" },
                  { label: "Every Year", value: "EVERY_YEAR" },
                  { label: "Custom", value: "CUSTOM" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomDatePicker
                fullWidth
                label="Campaign Start Date"
                fieldName="coupon_expiry_date"
                value={values.campaign_start_date}
                onChange={(newValue, formattedDate) => {
                  setFieldValue("campaign_start_date", formattedDate);
                }}
                error={errors.campaign_start_date}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CustomDatePicker
                fullWidth
                label="Campaign End Date"
                fieldName="campaign_end_date"
                value={values.campaign_end_date || ""}
                onChange={(newValue, formattedDate) => {
                  setFieldValue("campaign_end_date", formattedDate);
                }}
              // error={errors.campaign_end_date}
              />
            </Grid>
          </Grid>

        </StandardCard>

        {values.campaign_type === "EMAIL" &&
          <StandardCard
            heading="Compose Mail"
            sx={{
              mt: 2
            }}
            rightHeading={
              <Stack direction="row" spacing={2}>
                <CustomButton
                  sx={{ maxHeight: 42 }}
                  variant="contained"
                // onClick={generateCouponCode}
                >
                  Import Template
                </CustomButton>

                <CustomButton
                  sx={{ maxHeight: 42 }}
                  variant="contained"
                // onClick={generateCouponCode}
                >
                  Import Mails
                </CustomButton>

              </Stack>
            }
          >
            <ComposeNewMail />
          </StandardCard>
        }


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "10%" }}
            disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form >
    </>
  );
};
