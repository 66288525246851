import { LoadState } from "../../../constants/enum";
import { IReceiptComposer, IReceiptComposerState } from "./receipt-composer.types";

export const defaultReceiptComposer: IReceiptComposer = {
    receipt_composer_uuid: null,
    receipt_name: "",
    receipt_logo: "",
    receipt_type: "ORDER",
    receipt_header: "",
    receipt_footer: "",
    page_size: 0,
    padding_top: 0,
    padding_bottom: 0,
    padding_left: 0,
    padding_right: 0,
    outlet_uuid: ""
}


export const defaultReceiptComposerState: IReceiptComposerState = {
    receipts_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_receipt: {
        loading: LoadState.idle,
        data: defaultReceiptComposer,
        error: null
    }
}