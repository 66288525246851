import { Grid, Stack } from "@mui/material";
import React from "react";
import { CategoryCard } from "../../../Components/Card";

export const CategoryCards: React.FC = () => {
  return (
    <Grid
      container
      spacing={1.5}
      height={"36vh"}
      sx={{ overflowY: "scroll", pr: 2 }}
    >
      {exampleArray.map((item, index) => {
        return (
          <Grid item xs={12} sm={12} md={3}>
            <CategoryCard
              key={index}
              title={item.title}
              noOfItems={item.noOfItems}
              bgType={item.color as "green"}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const exampleArray = [
  {
    title: "Breakfast",
    noOfItems: 5,
    color: "green",
  },
  {
    title: "Dinner",
    noOfItems: 5,
    color: "pink",
  },
  {
    title: "Afternoon",
    noOfItems: 5,
    color: "purple",
  },
  {
    title: "Breakfast",
    noOfItems: 5,
    color: "grey",
  },
  {
    title: "Dinner",
    noOfItems: 5,
    color: "pink",
  },
  {
    title: "Dinner",
    noOfItems: 5,
    color: "grey",
  },
  {
    title: "Breakfast",
    noOfItems: 5,
    color: "green",
  },
  {
    title: "Dinner",
    noOfItems: 5,
    color: "pink",
  },
  {
    title: "Afternoon",
    noOfItems: 5,
    color: "purple",
  },
  {
    title: "Dinner",
    noOfItems: 5,
    color: "grey",
  },
];
