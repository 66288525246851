import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { StandardCard } from "../Card";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Masonry from "react-responsive-masonry";
import { INumberDialPadProps } from "./interface/NumberDialPad.types";
import { CustomButton } from "../CustomButton";
import { IShiftPunchInOut } from "../../redux/shifts";

export const NumberDialPad: React.FC<INumberDialPadProps> = (props) => {
  const { forOtp, onSave } = props;

  const [dialNumber, setDialNumber] = React.useState<string[]>([]);
  const dialNumbersArray: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "0",
    "del",
  ];

  const handleDialPadNumbers = (number: string) => () => {
    setDialNumber((prev) => {
      const updatedDialNumber =
        number === "del" ? prev.slice(0, -1) : [...prev, number];
      // if (updatedDialNumber.length === 4 && forOtp) {
      //   onSave();
      // }
      return updatedDialNumber;
    });
  };

  const handleAddNewShifts = (column_name: keyof IShiftPunchInOut) => {
    if(dialNumber.length !== 4 && !forOtp) return
    onSave(column_name, dialNumber.toString())
  }

  return (
    <StandardCard
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="h2" fontWeight={"700"}>
        {forOtp ? "Enter Your PIN" : "Tip Amount"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
          color: "#fff",
          gap: 1,
        }}
      >
        {forOtp ? (
          [...Array(4)].map((_, index) => {
            const pin = !!dialNumber[index];
            return (
              <Typography variant="h2" key={index}>
                {pin ? dialNumber[index] : "*"}
              </Typography>
            );
          })
        ) : (
          <Typography variant="h1" letterSpacing={0.5}>
            {dialNumbersArray.length > 0 ? dialNumber.join("") : "0.00"}
          </Typography>
        )}
      </Box>

      <Masonry columnsCount={3} gutter="12px" style={{ width: "50%" }}>
        {dialNumbersArray.map((number, index) => {
          return (
            <Button
              key={index}
              sx={{
                padding: "10px",
                margin: "5px",
                fontSize: "1.2rem",
                bgcolor: "#444242",
                width: "100%",
                height: "100%",
              }}
              onClick={handleDialPadNumbers(number)}
            >
              {number === "del" ? <BackspaceIcon /> : number}
            </Button>
          );
        })}
      </Masonry>

      <Stack direction="row" spacing={2}>
        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => handleAddNewShifts("punch_in")}
        >
          Punch In
        </CustomButton>

        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => handleAddNewShifts("punch_out")}
        >
          Punch Out
        </CustomButton>

        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => handleAddNewShifts("break_in")}
        >
          Break In
        </CustomButton>

        <CustomButton
          variant="contained"
          color="primary"
          onClick={() => handleAddNewShifts("break_out")}
        >
          Break Out
        </CustomButton>


      </Stack>
    </StandardCard>
  );
};
