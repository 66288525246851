import { Grid } from '@mui/material'
import React from 'react'
import { AirlineMenuCard } from './AirlineMenuCard'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../../redux';
import {
    IAirlineMenu, IFlightMenuItem, clearAirlineMenuState,
    fetchSingleAirlineMenuListAsync
} from '../../../../../redux/airlineAndFlights';

interface IAirlineMenuListTableProps {
    airline_uuid?: string;
    selectedItems: IFlightMenuItem[],
    onChange: (items: IFlightMenuItem[]) => void
}
export const AirlineMenuListTable: React.FC<IAirlineMenuListTableProps> = ({
    airline_uuid,
    selectedItems,
    onChange
}) => {
    const {
        data: airline_menu_list,
    } = useAppSelector((storeState: IStoreState) => storeState.airlinesAndFlights.airlineMenu.airline_menu_list);
    const dispatch = useAppDispatch();


    React.useEffect(() => {
        if (!airline_uuid) return
        dispatch(fetchSingleAirlineMenuListAsync(airline_uuid));
    }, [airline_uuid]);

    React.useEffect(() => {
        return () => {
            dispatch(clearAirlineMenuState());
        };
    }, []);


    const handleAddOrder = (item_to_add: IAirlineMenu) => () => {
        let newList = [...selectedItems]
        const isPresent = newList.find((item) => item.item_name === item_to_add.menu_item_name);
        if (isPresent) {
            newList = newList.map((item) => {
                let newItem = { ...item }
                if (newItem.item_name === item_to_add.menu_item_name) {
                    newItem.item_quantity = newItem.item_quantity + 1;
                    newItem.item_price = newItem.item_price + item_to_add.menu_item_price;
                }
                return newItem;
            });
        } else {
            newList.push({
                item_name: item_to_add.menu_item_name,
                item_price: item_to_add.menu_item_price,
                item_quantity: 1,
            });
        }
        onChange(newList)
    };

    const handleRemoveOrder = (item_to_remove: IAirlineMenu) => () => {
        let newList = [...selectedItems]
        const isPresent = newList.find((item) => item.item_name === item_to_remove.menu_item_name);
        if (isPresent) {
            newList = newList.map((item) => {
                let newItem = { ...item }

                if (newItem.item_name === item_to_remove.menu_item_name) {
                    newItem.item_quantity = item.item_quantity - 1;
                    newItem.item_price = item.item_price - item_to_remove.menu_item_price;
                }
                return newItem;
            });
            newList = newList.filter((item) => item.item_quantity > 0);
        }
        onChange(newList)
    };

    const handleQuantityChange = (selected_item: IAirlineMenu, quantity: number) => {
        let newList = [...selectedItems]
        const isPresent = newList.find((item) => item.item_name === selected_item.menu_item_name);
        if (isPresent) {
            newList = newList.map((item) => {
                let newItem = { ...item }
                if (newItem.item_name === selected_item.menu_item_name) {
                    newItem.item_quantity = quantity;
                    newItem.item_price = Number(selected_item.menu_item_price) * Number(quantity);
                }
                return newItem;
            });
        } else {
            newList.push({
                item_name: selected_item.menu_item_name,
                item_price: Number(selected_item.menu_item_price) * Number(quantity),
                item_quantity: quantity,
            });
        }
        onChange(newList)
    };

    return (
        <Grid
            container
            spacing={2}
        >
            {airline_menu_list.map((menuItem, menu_idx) => {
                return (
                    <Grid item xs={12} sm={6} md={3} width={"100%"}>
                        <AirlineMenuCard
                            key={menu_idx}
                            menuItem={menuItem}
                            quantity={selectedItems.find((item) => item.item_name === menuItem.menu_item_name)?.item_quantity || 0}
                            onAddItem={handleAddOrder(menuItem)}
                            onRemoveItem={handleRemoveOrder(menuItem)}
                            onQuantityChange={(qnt) => handleQuantityChange(menuItem, qnt)}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}
