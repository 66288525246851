import { Box, Stack, Typography, Divider } from '@mui/material'
import React from 'react'
import { FiUser } from 'react-icons/fi'
import { IReservation } from '../../../../redux/table-and-reservations'
import moment from 'moment'

interface IUserReservationCardProps {
    data: IReservation
    selected: boolean
    onClick: () => void
    onDoubleClick: () => void
}

export const UserReservationCard: React.FC<IUserReservationCardProps> = ({
    data,
    selected,
    onClick,
    onDoubleClick
}) => {
    return (
        <div>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                padding={1}
                flexWrap="wrap"
                sx={{
                    background: selected ? "#363636" : "none",
                    cursor: "pointer",
                    borderRadius: 1
                }}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    spacing={2}
                >
                    <FiUser
                        style={{
                            border: "1px solid #FFFFFF",
                            backgroundColor: "#FFFFFF",
                            color: "#000000",
                            borderRadius: "50%",
                            padding: "0.3rem",
                            fontSize: "2rem",
                        }}
                    />
                    <Typography variant="h6" fontWeight={"bold"}>
                        {data.user_name}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    spacing={2}
                >
                    <Typography variant="h6">{`T${data.table_no}`}</Typography>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderColor: "white" }}
                    />

                    <Typography variant="h6">{`${data.number_of_people}x`}</Typography>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderColor: "white" }}
                    />
                    <Typography minWidth={70} textAlign="right" variant="h6">{moment(data.reservation_from_time).format("hh:mm A")}</Typography>
                </Stack>
            </Box>
            <Divider />
        </div>
    )
}
