import React from "react";
import { CustomChip } from "./CustomChip";
import { IStandardChipProps } from "./interfaces/interface";

export const StandardChip: React.FC<IStandardChipProps> = (props) => {
  const { label } = props;
  const capitalizeLabel = label.toUpperCase();
  switch (capitalizeLabel) {
    case "IN-PROGRESS":
      return (
        <CustomChip
          label={label}
          color="success"
        />
      );
    default:
      return <CustomChip label={label} color="success" />;
  }
};
