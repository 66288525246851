import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultCategoryState } from "./categories.state";
import { fetchCategoriesListAsync, fetchCategoriesListWithArgsAsync } from "./categories.actions";

const categoriesSlice = createSlice({
    initialState: defaultCategoryState,
    name: "inventory-categories",
    reducers: {
        selectSingleCategorySync: (state, action) => {
            state.selected_category = action.payload
        },
        clearCategoriesStateSync: (state) => {
            return defaultCategoryState
        },
        clearSelectedCategorySync: (state) => {
            state.single_category.data = defaultCategoryState["single_category"]["data"]
            state.single_category.loading = defaultCategoryState["single_category"]["loading"]
            state.single_category.error = defaultCategoryState["single_category"]["error"]
        },
    },
    extraReducers: (builder) => {

        //############################# fetchCategoriesListAsync ######################################
        builder.addCase(fetchCategoriesListAsync.pending, (state, action) => {
            state.category_list.loading = LoadState.pending
        })
        builder.addCase(fetchCategoriesListAsync.fulfilled, (state, action) => {
            state.category_list.loading = LoadState.succeeded
            state.category_list.data = action.payload.data
            state.category_list.totalRecords = action.payload.recordCount
            state.category_list.error = null
        })
        builder.addCase(fetchCategoriesListAsync.rejected, (state, action) => {
            state.category_list.error = action.error.message as string
        })

        //############################# fetchCategoriesListWithArgsAsync ######################################
        builder.addCase(fetchCategoriesListWithArgsAsync.pending, (state, action) => {
            state.category_list.loading = LoadState.pending
        })
        builder.addCase(fetchCategoriesListWithArgsAsync.fulfilled, (state, action) => {
            state.category_list.loading = LoadState.succeeded
            state.category_list.data = action.payload.data
            state.category_list.totalRecords = action.payload.recordCount
            state.category_list.error = null
        })
        builder.addCase(fetchCategoriesListWithArgsAsync.rejected, (state, action) => {
            state.category_list.error = action.error.message as string
        })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const categoriesReducer = categoriesSlice.reducer;
export const {
    clearCategoriesStateSync,
    clearSelectedCategorySync
} = categoriesSlice.actions;
