import { useRoutes } from "react-router-dom";
import "./App.css";
import { Router } from "./router/router";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { baseTheme } from "./Theme/ThemeVariable";
import 'reactflow/dist/style.css';

function App() {
  const routing = useRoutes(Router);

  return (
    <ThemeProvider theme={baseTheme}>
      <CssBaseline />
      {routing}
    </ThemeProvider>
  );
}

export default App;