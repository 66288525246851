import React from "react";
import { Dialog } from "../../../../../Components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../../redux";
import { OutlinedTextField } from "../../../../../Components/formComponents";
import { IPosBankInfo, upsertSinglePosBankInfoAsync } from "../../../../../redux/accounting";


export interface IUpsertBankInfoDialogProps {
  open: boolean;
  initialState: IPosBankInfo;
  onClose: () => void;
}
export const UpsertBankInfoDialog: React.FC<IUpsertBankInfoDialogProps> = (props) => {
  const { open, initialState, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...initialState },
    validate: (values) => {
      const errors: any = {};
      if (!values.bank_name) {
        errors.bank_name = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSinglePosBankInfoAsync(values)
      ).then((res) => {
        console.log("upsertSinglePaymentReceivedAsync ==>", res)
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  return (
    <Dialog
      open={open}
      title="Add/Edit Bank Information"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Bank Name"
              id="bank_name"
              value={values.bank_name}
              onChange={handleChange}
              error={errors.bank_name}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Account number"
              id="account_number"
              value={values.account_number}
              onChange={handleChange}
              error={errors.account_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Transit Number"
              id="transit_number"
              value={values.transit_number}
              onChange={handleChange}
              error={errors.transit_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Institution Number"
              id="institution_number"
              value={values.institution_number}
              onChange={handleChange}
              error={errors.institution_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Opening Balance"
              id="opening_balance"
              type="number"
              value={values.opening_balance}
              onChange={handleChange}
              error={errors.opening_balance}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Bank Address"
              id="bank_address"
              multiline
              minRows={4}
              value={values.bank_address}
              onChange={handleChange}
              error={errors.bank_address}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
