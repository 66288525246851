import React, { useEffect } from 'react'
import PageContainer from '../../../Components/PageContainer/PageContainer'
import { StandardCard } from '../../../Components/Card'
import { QuillEditor } from '../../../Components/QuillEditor/QuillEditor'
import { useFormik } from 'formik'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux'
import { clearSingleOutletReceiptsSync, fetchSingleOutletReceiptInfoAsync, upsertSingleOutletReceiptAsync } from '../../../redux/settings/receipt-composer'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { OutlinedTextField } from '../../../Components/formComponents'
import { useNavigate, useParams } from 'react-router-dom'
import { RECEIPT_COMPOSER_ROUTE } from './ReceiptComposer.constants'
import { ProfileIntroCard } from '../../../Components/Profile/ProfileIntoCard'
import { OutletsDropDown } from '../CompanyOutlets/components/OutletsDropDown'

export const ManageSingleReceipt = () => {
  const { uuid: receiptUUID } = useParams<{ uuid?: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: receiptComposerInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.receiptComposer.single_receipt);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...receiptComposerInfo,
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.receipt_header) {
        errors.receipt_header = "*This Field is required.";
      }
      if (!values.receipt_footer) {
        errors.receipt_footer = "*This Field is required";
      }
      if (!values.page_size) {
        errors.page_size = "*This Field is required";
      }
      if (!values.padding_top) {
        errors.padding_top = "*This Field is required";
      }
      if (!values.padding_bottom) {
        errors.padding_bottom = "*This Field is required";
      }
      if (!values.padding_left) {
        errors.padding_left = "*This Field is required";
      }
      if (!values.padding_right) {
        errors.padding_right = "*This Field is required";
      }

      return errors;
    },
    onSubmit: async (values) => {
      dispatch(upsertSingleOutletReceiptAsync({...values, file: fileToUpload})).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(RECEIPT_COMPOSER_ROUTE)
        }
      })
    },
  });


  useEffect(() => {
    if (!receiptUUID) return
    dispatch(fetchSingleOutletReceiptInfoAsync(receiptUUID))
  }, [receiptUUID])

  useEffect(() => {
    setValues(receiptComposerInfo)
  }, [receiptComposerInfo])

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleOutletReceiptsSync());
    };
  }, []);


  return (
    <PageContainer title='receipt-composer' description='' >
      <Box component="form" onSubmit={handleSubmit} mb={3}>
        <Typography variant='h2'>Receipt Composer</Typography>

        <Box sx={{paddingBottom: 2}}>
          <Grid container spacing={2} >
            <Grid item xs={12} md={3}>
              <StandardCard
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  mt: 2
                }}
              >
                <ProfileIntroCard
                  fileUrl={values.receipt_logo}
                  title="Change Logo"
                  subTitle="Change Receipt logo from here"
                  onChange={(file) => setFileToUpload(file)}
                  onFileReset={() => setFieldValue("receipt_logo", null)}

                />
              </StandardCard>
            </Grid>
            <Grid item xs={12} md={9}>
              <StandardCard heading="Page Information" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <OutletsDropDown
                      value={values.outlet_uuid}
                      onChange={(newValue) => setFieldValue("outlet_uuid", newValue)}
                      error={errors.outlet_uuid}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OutlinedTextField
                      label="Page Size"
                      id="page_size"
                      type="number"
                      value={values.page_size}
                      onChange={handleChange}
                      error={errors.page_size}
                    />
                  </Grid>
                  <Grid item xs={12} />

                  <Grid item xs={12} md={3}>
                    <OutlinedTextField
                      label="Padding Top"
                      id="padding_top"
                      type="number"
                      value={values.padding_top}
                      onChange={handleChange}
                      error={errors.padding_top}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OutlinedTextField
                      label="Padding Bottom"
                      id="padding_bottom"
                      type="number"
                      value={values.padding_bottom}
                      onChange={handleChange}
                      error={errors.padding_bottom}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OutlinedTextField
                      label="Padding Left"
                      id="padding_left"
                      type="number"
                      value={values.padding_left}
                      onChange={handleChange}
                      error={errors.padding_left}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <OutlinedTextField
                      label="Padding Right"
                      id="padding_right"
                      type="number"
                      value={values.padding_right}
                      onChange={handleChange}
                      error={errors.padding_right}
                    />
                  </Grid>
                </Grid>
              </StandardCard>
            </Grid>
          </Grid>
        </Box>


        <StandardCard heading="Header" sx={{ mt: 2 }}>
          <QuillEditor
            value={values.receipt_header}
            onChange={(html) => setFieldValue("receipt_header", html)}
            error={errors.receipt_header ? true : false}
          />

        </StandardCard>

        <StandardCard heading="Footer" sx={{ mt: 2 }}>
          <QuillEditor
            value={values.receipt_footer}
            onChange={(html) => setFieldValue("receipt_footer", html)}
            error={errors.receipt_footer ? true : false}
          />

        </StandardCard>



        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "15%" }}
            // disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </Box>

    </PageContainer>
  )
}
