import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getShiftsFormLocalStore, setShiftsInLocalStore } from "./shifts.localStore";
import { defaultShiftPunchInOutState } from "./shifts.states";
import { LoadState } from "../../constants/enum";
import { fetchShiftPunchInOutAsync } from "./shifts.actions";
import { IShiftPunchInOut } from "./shifts.types";


const shiftsSlice = createSlice({
    initialState: defaultShiftPunchInOutState,
    name: "shifts",
    reducers: {
        getShiftsListSync: (state) => {
            const data = getShiftsFormLocalStore()
            state.shifts_list.data = data
            state.shifts_list.totalRecords = data.length
        },
        addNewShiftsSync: (state, action) => {
            const data = getShiftsFormLocalStore()
            state.shifts_list.data = [action.payload, ...data]
            setShiftsInLocalStore(state.shifts_list.data)
        },

        clearShiftsState: (state) => {
            return defaultShiftPunchInOutState
        },
    },
    extraReducers: (builder) => {
        // ############################# Multiple Shifts List ######################################
        builder.addCase(fetchShiftPunchInOutAsync.pending, (state, action) => {
            state.shifts_list.loading = LoadState.pending
        })
        builder.addCase(fetchShiftPunchInOutAsync.fulfilled, (state, action: PayloadAction<{data: IShiftPunchInOut[], recordCount: number}>) => {
            state.shifts_list.loading = LoadState.succeeded
            state.shifts_list.data = action.payload.data
            state.shifts_list.totalRecords = action.payload.recordCount
            state.shifts_list.error = null
        })
        builder.addCase(fetchShiftPunchInOutAsync.rejected, (state, action) => {
            state.shifts_list.error = action.error.message as string
        })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const shiftsReducer = shiftsSlice.reducer;
export const { 
    addNewShiftsSync,
    clearShiftsState,
    getShiftsListSync

 } = shiftsSlice.actions;
