import { defaultCustomTable } from "../../../Components/ReactFlowShapes/ReactFlowShapes.state";
import { ICustomTableNode } from "../../../Components/ReactFlowShapes/ReactFlowShapes.types";
import { LoadState } from "../../../constants/enum";
import { ITablesLayout, ITablesLayoutState } from "./tables.types";


export const defaultTablesLayout: ITablesLayout = {
    layout_uuid: null,
    layout_name: "",
    tables: [],   // json type array

}

export const defaultTablesLayoutState: ITablesLayoutState = {
    tables_layouts_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_tables_layout: {
        loading: LoadState.idle,
        data: defaultTablesLayout,
        error: null,
    },
    selected_table: defaultCustomTable
}