import { LoadState } from "../../../constants/enum";
import { IFlightMenu, IFlightMenuState } from "./flight-menu.types";


export const defaultFlightMenu: IFlightMenu = {
    menu_to_flight_uuid: null,
    airline_name: null,
    airline_uuid: null,
    flight_number: null,
    flight_uuid: null,
    boarding_date: null,
    item_list: [],
    sub_total: 0,
    tax_ten_percent: 0,
    total: 0,
    status: "ACTIVE"
}

export const defaultFlightMenuState: IFlightMenuState = {
    flight_menu_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_flight_menu: {
        loading: LoadState.idle,
        data: defaultFlightMenu,
        error: null,
    }
}