import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./ReservationCalendar.css";

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);

interface IReservationCalendarProps {
  // selectedDate: string
  // onDateChange: (newDate: string) => void
}
const ReservationCalendar: React.FC<IReservationCalendarProps> = () => {
  const myEventsList = [
    {
      id: 1,
      title: "Long Event",
      start: moment("2024-03-18T16:00:00").toDate(),
      end: moment("2024-03-18T17:00:00").toDate(),
    },
  ];

  return (
    <Calendar
      localizer={localizer}
      events={myEventsList}
      startAccessor="start"
      endAccessor="end"
      views={["month", "agenda"]}
      scrollToTime={moment("2020-01-01T00:00:00").toDate()}
      style={{ height: 650 }}
      defaultDate={new Date()}

      // onSelecting={(range) => {
      //   console.log("onSelecting", start, end)
      // }}
    />
  );
};

export default ReservationCalendar;
