import { LoadState } from "../../../constants/enum";
import { IPosOrder, IPosOrderState } from "./pos-orders.types";

export const defaultPosOrder: IPosOrder = {
    order_uuid: null,
    order_number: "",
    table_uuid: null,
    table_number: null,
    menu_items: [],
    sub_total: 0,
    total_tax: 0,
    total_amount: 0,
    order_type: "TAKE_AWAY",
    payment_mode: "NONE",
    is_paid: false,
    is_served: false,
    is_ready_to_serve: false
}


export const defaultPosOrderState: IPosOrderState = {
    pos_orders_list: {
        loading: LoadState.idle,
        data: [
            {
                ...defaultPosOrder,
                order_type: "TAKE_AWAY",
                menu_items: [
                    { product_name: "Burger", product_uuid: "352525re", product_price: 23, quantity: 2, tax_percentage: 5 }
                ],
                sub_total: 100,
                order_number: "1342",
                total_tax: 10,
                total_amount: 110,
                is_ready_to_serve: false,
                is_served: false,
                create_ts: new Date().toISOString()
            },
            {
                ...defaultPosOrder,
                order_type: "DINE_IN",
                menu_items: [
                    { product_name: "Burger Burger Burger Burger", product_uuid: "352525re", product_price: 23, quantity: 2, tax_percentage: 5 }
                ],
                sub_total: 100,
                order_number: "1334",
                total_tax: 10,
                total_amount: 110,
                is_ready_to_serve: false,
                is_served: false,
                is_paid: false,
                create_ts: new Date().toISOString()
            }
        ],
        totalRecords: 0,
        error: null,
    },
    single_pos_order: {
        loading: LoadState.idle,
        data: defaultPosOrder,
        error: null,
    }
}