import React from "react";
import { StandardCard } from "./StandardCard";
import { Avatar, Stack, Typography } from "@mui/material";
import { IItemCardProps } from "./interface/interface";

export const ItemCard: React.FC<IItemCardProps> = (props) => {
  const { name, price, quantity, incrementalNumber } = props;
  return (
    <StandardCard
      sx={{
        mb: 1,
        p: 2,
      }}
    >
      <Stack
        spacing={0.5}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Stack spacing={2} direction={"row"}>
          <Avatar sx={{ width: 24, height: 24, bgcolor: "#fff" }}>
            {incrementalNumber}
          </Avatar>
          <Typography variant="h5" color={"#fff"} fontWeight={"500"}>
            {name} x{quantity}
          </Typography>
        </Stack>
        <Typography variant="h5" color={"#fff"} fontWeight={"700"}>
          ₹{price}
        </Typography>
      </Stack>
    </StandardCard>
  );
};
