/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import { COMPANY_OUTLETS_ROUTE } from "./CompanyOutlets.constants";
import { clearSingleOutletsSync, fetchSingleOutletAsync, upsertCompanyOutletAsync } from "../../../redux/settings/outlets";
import { StandardCard } from "../../../Components/Card";
import { ProfileIntroCard } from "../../../Components/Profile/ProfileIntoCard";
import { OutlinedTextField } from "../../../Components/formComponents";
import { OutlinedTimePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { CompaniesDropDown } from "../Company/components/CompaniesDropDown";

export const ManageSingleOutlet = () => {
  const { uuid: outlet_uuid } = useParams<{ uuid?: string }>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { data: singleOutletInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.outlets.single_outlet);

  const [saveLoading, setSaveLoading] = React.useState(false);
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...singleOutletInfo },
    validate: (values) => {
      const errors: any = {};
      // if (!values.company_name) {
      //   errors.company_name = "*First Name is required";
      // }
      // if (!values.company_opening_timing) {
      //   errors.company_opening_timing = "*This Field is required.";
      // }
      // if (!values.company_closing_timing) {
      //   errors.company_closing_timing = "*This Field is required.";
      // }
      // if (values.company_phone_number && values.company_phone_number.toString().length !== 10) {
      //   errors.company_phone_number = "*Phone number must be 10 digits";
      // }
      // if (!values.company_email) {
      //   errors.company_email = "*Email is required.";
      // } else if (
      //   values.company_email !== "" &&
      //   !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      //     values.company_email,
      //   )
      // ) {
      //   errors.company_email = "*Invalid email address.";
      // }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertCompanyOutletAsync({...values, file: fileToUpload}),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(COMPANY_OUTLETS_ROUTE)
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });


  React.useEffect(() => {
    if (!outlet_uuid) return
    dispatch(fetchSingleOutletAsync(outlet_uuid));
  }, [outlet_uuid]);

  React.useEffect(() => {
    setValues(singleOutletInfo);
  }, [singleOutletInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleOutletsSync())
    }
  }, []);


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Manage Outlet</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StandardCard
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ProfileIntroCard
                fileUrl={values.outlet_logo}
                title="Change Logo"
                subTitle="Change Outlet logo from here"
                onChange={(file) => setFileToUpload(file)}
                onFileReset={() => setFieldValue("outlet_logo", null)}

              />
            </StandardCard>
          </Grid>
          <Grid item xs={12} md={9}>
            <StandardCard
              heading="Outlet Information"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Name"
                    id="outlet_name"
                    value={values.outlet_name}
                    onChange={handleChange}
                    error={errors.outlet_name}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Code"
                    id="outlet_code"
                    value={values.outlet_code}
                    onChange={handleChange}
                    error={errors.outlet_code}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CompaniesDropDown
                    value={values.company_uuid}
                    onChange={(newValue) => setFieldValue("company_uuid", newValue)}
                    error={errors.company_uuid}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Email"
                    id="outlet_email"
                    value={values.outlet_email}
                    onChange={handleChange}
                    error={errors.outlet_email}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Phone Number"
                    id="outlet_phone_number"
                    type="number"
                    value={values.outlet_phone_number}
                    onChange={handleChange}
                    error={errors.outlet_phone_number}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTimePicker
                    label="Outlet Opening Time"
                    fullWidth
                    format="hh:mm A"
                    value={moment(values.outlet_opening_time)}
                    onChange={(newValue) => {
                      setFieldValue("outlet_opening_time", newValue.toISOString());
                    }}
                    error={errors.outlet_opening_time}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTimePicker
                    label="Outlet Closing Time"
                    fullWidth
                    format="hh:mm A"
                    value={moment(values.outlet_closing_time)}
                    onChange={(newValue) => {
                      setFieldValue("outlet_closing_time", newValue.toISOString());
                    }}
                    error={errors.outlet_closing_time}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet GST Number"
                    id="outlet_gsthst_number"
                    value={values.outlet_gsthst_number}
                    onChange={handleChange}
                    error={errors.outlet_gsthst_number}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Default Tax Rate"
                    id="default_tax_rate"
                    type="number"
                    value={values.default_tax_rate}
                    onChange={handleChange}
                    error={errors.default_tax_rate}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <OutlinedTextField
                    label="Outlet Address"
                    id="outlet_address"
                    multiline
                    minRows={3}
                    value={values.outlet_address}
                    onChange={handleChange}
                    error={errors.outlet_address}
                  />
                </Grid>
              </Grid>
            </StandardCard>
          </Grid>
        </Grid>


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "10%" }}
            disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </>
  );
};
