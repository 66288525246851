import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { ICategoryCardProps } from "./interface/interface";
import { FaCoffee } from "react-icons/fa";

export const CategoryCard: React.FC<ICategoryCardProps> = (props) => {
  const { title, noOfItems, bgType } = props;

  const [bgColor, setBgColor] = React.useState<string>("#C1E9DE");

  const handleEvent = (type: string) => {
    if (type === "green") {
      setBgColor("#C1E9DE");
    } else if (type === "pink") {
      setBgColor("#FBC1D9");
    } else if (type === "grey") {
      setBgColor("#E5DADE");
    } else {
      setBgColor("#E5CDEE");
    }
  };

  React.useEffect(() => {
    handleEvent(bgType);
  }, [bgType]);

  return (
    <Box display={"flex"}>
      <Paper
        elevation={1}
        square={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "space-between",
          gap: 4,
          bgcolor: bgColor,
          color: "black",
          pl: 1.5,
          paddingY: 2,
          borderRadius: 1,
          width: "100%",
          // height: "150px",
        }}
      >
        <FaCoffee fontSize={"2.2rem"}/>
        <Box>
          <Typography variant="h4" fontWeight={"bold"} letterSpacing={-0.5}>
            {title}
          </Typography>
          <Typography variant="h6" color={"#595959"} fontWeight={"500"}>
            {noOfItems} Items
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};
