import { LoadState } from "../../../constants/enum";
import { ICampaign, ICampaignState } from "./campaigns.types";


export const defaultCampaign: ICampaign = {
    campaign_uuid: null,
    campaign_id: "",
    campaign_name: "",
    campaign_type: "EMAIL",
    campaign_repeat: "EVERYDAY",

    campaign_start_date: "",
    campaign_end_date: null,
    run_daily_at: ""
}


export const defaultCampaignState: ICampaignState = {
    campaign_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_campaign: {
        loading: LoadState.idle,
        data: defaultCampaign,
        error: null
    }
}
