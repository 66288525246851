import { initialOrderState } from "./Order/Order.types";
import { defaultChartOfAccountState, defaultPaymentPaidState, defaultPaymentReceivedState, defaultPosBankInfoState } from "./accounting";
import { defaultAirlineFlightState, defaultAirlineMenuState, defaultAirlineState, defaultFlightMenuState } from "./airlineAndFlights";
import { defaultCustomizerState } from "./customizer";
import { defaultInventoryState, defaultProductCustomizationState, defaultProductState } from "./inventory";
import { defaultCategoryState } from "./inventory/categories/categories.state";
import { defaultPosMenuCategoryState, defaultPosMenuProductState, defaultPosOrderState } from "./pos-menu-and-orders";
import { defaultReceiptComposerState } from "./settings/receipt-composer";
import { defaultShiftPunchInOutState } from "./shifts";
import { IStoreState } from "./store.types";
import { defaultReservationState, defaultTablesLayoutState } from "./table-and-reservations";
import { defaultUserProfileState } from "./settings/user-profile";
import { defaultTaxInfoState } from "./settings/tax-info";
import { defaultCompanyState } from "./settings/company";
import { defaultCompanyOutletState } from "./settings/outlets";
import { defaultOutletRegisterState } from "./settings/outlet-registers";
import { defaultSecurityState } from "./settings/security";
import { defaultCouponState } from "./marketing";
import { defaultCampaignState } from "./marketing/campaigns";
import { defaultOrderState } from "./orders";

export const initialStoreState: IStoreState = {
  // Add your initial state here
  // order: initialOrderState,
  airlinesAndFlights: {
    airlines: defaultAirlineState,
    flights: defaultAirlineFlightState,
    airlineMenu: defaultAirlineMenuState,
    flightMenu: defaultFlightMenuState
  },
  inventory: {
    categories: defaultCategoryState,
    products: defaultProductState,
    inventory: defaultInventoryState,
    customization: defaultProductCustomizationState
  },
  tableAndReservation: {
    tables: defaultTablesLayoutState,
    reservations: defaultReservationState
  },


  pointOfSale: {
    shifts: defaultShiftPunchInOutState,
    accounting: {
      paymentReceived: defaultPaymentReceivedState,
      paymentPaid: defaultPaymentPaidState,
      bankInfo: defaultPosBankInfoState,
      chartOfAccount: defaultChartOfAccountState
    },
    menu: {
      categories: defaultPosMenuCategoryState,
      products: defaultPosMenuProductState
    },
    orders: defaultPosOrderState
  },
  customizer: defaultCustomizerState,
  settings: {
    receiptComposer: defaultReceiptComposerState,
    companies: defaultCompanyState,
    outlets: defaultCompanyOutletState,
    registers: defaultOutletRegisterState,
    tax: defaultTaxInfoState,
    userProfile: defaultUserProfileState,
    security: defaultSecurityState
  },
  marketing: {
    campaigns: defaultCampaignState,
    coupons: defaultCouponState
  },
  orders: defaultOrderState
};
