import React from "react";
import { useLocation } from "react-router-dom";
import { ISidebarMenuItem } from "../layout/FullLayout/sidebar/Siderbar.types";
import { AirKitchenMenu, posMenu } from "../layout/FullLayout/sidebar/menuItems";

export const useSecureMenuList = () => {
  const { pathname } = useLocation();

  const [sidebarMenuItems, setSidebarMenuItems] = React.useState<ISidebarMenuItem[]>(AirKitchenMenu)

  React.useEffect(() => {
    console.log("pathname ===>", pathname)
    if (pathname.includes('/pos')) {
      setSidebarMenuItems(posMenu)
    } else {
      setSidebarMenuItems(AirKitchenMenu)
    }
  }, [pathname])

  // const { roles } = userRoles();

  // React.useEffect(() => {
  //   if (roles.length > 0) {
  //     const accessibleModuleIds = roles
  //       .filter(
  //         (module) =>
  //           module.show_module ===
  //           1 /* Add more conditions for other permissions if needed */,
  //       )
  //       .map((module) => module.module_key);

  //     // Function to filter menu items recursively
  //     const filterMenuItems = (menuList: IMenuItem[]) => {
  //       return menuList
  //         .map((item) => {
  //           if (item.children) {
  //             const filteredChildren = filterMenuItems(item.children);
  //             item.children = filteredChildren.filter(
  //               (childItem) =>
  //                 childItem.moduleIds.some((id) =>
  //                   accessibleModuleIds.includes(id),
  //                 ) || childItem.allowFullAccess,
  //             );
  //           }
  //           return item;
  //         })
  //         .filter(
  //           (item) =>
  //             item.moduleIds.some((id) => accessibleModuleIds.includes(id)) ||
  //             item.allowFullAccess,
  //         );
  //     };

  //     // Filter the static menu list based on accessibleModuleIds
  //     const filteredMenu = filterMenuItems(Menuitems);
  //     setMenuList(filteredMenu);
  //   }
  // }, [roles]);

  return sidebarMenuItems;
};
