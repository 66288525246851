import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { fetchAirlinesFlightsAsync, fetchSingleAirlineFlightAsync } from "./flights.actions";
import { defaultAirlineFlightState } from "./flights.state";


const airlineFlightsSlice = createSlice({
    initialState: defaultAirlineFlightState,
    name: "airlines",
    reducers: {
        clearAirlineFlightsState: (state) => {
            return defaultAirlineFlightState
        }
    },
    extraReducers: (builder) => {
        //#################################### Multiple Airline Flights ##############################################
        builder.addCase(fetchAirlinesFlightsAsync.pending, (state, action) => {
            state.flights_list.loading = LoadState.pending
        })
        builder.addCase(fetchAirlinesFlightsAsync.fulfilled, (state, action) => {
            state.flights_list.loading = LoadState.succeeded
            state.flights_list.data = action.payload.data
            state.flights_list.totalRecords = action.payload.recordCount
            state.flights_list.error = null
        })
        builder.addCase(fetchAirlinesFlightsAsync.rejected, (state, action) => {
            state.flights_list.error = action.error.message as string
        })
        //#################################### Single Airline Flight ##############################################
        builder.addCase(fetchSingleAirlineFlightAsync.pending, (state, action) => {
            state.single_flight.loading = LoadState.pending
        })
        builder.addCase(fetchSingleAirlineFlightAsync.fulfilled, (state, action) => {
            state.single_flight.loading = LoadState.succeeded
            state.single_flight.data = action.payload
            state.single_flight.error = null
        })
        builder.addCase(fetchSingleAirlineFlightAsync.rejected, (state, action) => {
            state.single_flight.error = action.error.message as string
        })
    },
});

export const airlineFlightsReducer = airlineFlightsSlice.reducer;
export const { clearAirlineFlightsState} = airlineFlightsSlice.actions;
