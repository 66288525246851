import React from 'react'
import { ProductCard } from '../../../../../Components/Card'
import { Box, Grid } from '@mui/material'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../../redux';
import { MenuProductCard } from './MenuProductCard';
import { IPosMenuProduct } from '../../../../../redux/pos-menu-and-orders';
import { OutlinedTextField } from '../../../../../Components/formComponents';
import { IProduct, fetchProductListAsync } from '../../../../../redux/inventory';
import { fetchOrdersListAsync } from '../../../../../redux/orders';

export const MenuProductList = () => {
  const {
    data: menu_products_list,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.products.product_list);
  const selectedCategory = useAppSelector((storeState: IStoreState) => storeState.inventory.categories.selected_category);
const dispatch = useAppDispatch()
  const [searchText, setSearchText] = React.useState("")
  const [menuProductList, setMenuProductList] = React.useState<IProduct[]>([])

  React.useEffect(() => {
    dispatch(fetchProductListAsync())
  }, [])

  React.useEffect(() => {
    let newFilteredList: IProduct[] = menu_products_list
    if (selectedCategory) {
      newFilteredList = menu_products_list.filter((product) => product.category_uuid === selectedCategory)
    }
    if (searchText.length > 0) {
      newFilteredList = newFilteredList.filter((product) => product.product_name.toLowerCase().includes(searchText.toLowerCase()))
    }
    setMenuProductList(newFilteredList)
  }, [searchText, selectedCategory, menu_products_list])

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <OutlinedTextField
          label="Search Products"
          id="account_name"
          value={searchText}
          onChange={(evt) => setSearchText(evt.target.value)}
        />
      </Box>
      <Grid
        container
        spacing={1.5}
        // height={"85vh"}
        sx={{ overflowY: "auto", pr: 1, mt: 0.5 }}
      >
        {menuProductList.map((product) => {
          return <Grid item xs={12} sm={6} md={3} width={"100%"}>
            <MenuProductCard
              data={product}
            />
          </Grid>
        })}
      </Grid>
    </>

  )
}
