import React from "react";
import { DataTable } from "../../../Components/Table/DataTable";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../Components/Table/interfaces/IDataTableProps";
import { Avatar } from "@mui/material";
import { IProduct, clearProductStateSync, fetchProductListWithArgsAsync } from "../../../redux/inventory";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useDateFilter } from "../../../Components/Table/hooks/useDateFilter";
import { useNavigate } from "react-router-dom";
import { formatDateWithTime } from "../../../helpers";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { PRODUCTS_ROUTE } from "../Inventory.constants";

export const ProductsTable = () => {
  const {
    data: productsList,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.products.product_list);

  const [status, setStatus] = React.useState<IProduct["status"] | "-1">("-1");
  const [searchColumns, setSearchColumns] = React.useState<string[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });
  const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
    useDateFilter({ defaultType: "last28Days" });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const productsTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IProduct) => {
          return (
            <StandardTableActions
              onEditClick={() => navigate(`${PRODUCTS_ROUTE}/manage/${row.product_uuid}`)}
            />
          );
        },
      },
      {
        key: "product_name",
        headerName: "Product Name",
        fieldName: "product_name",
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "product_description",
        headerName: "Description",
        fieldName: "product_description",
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "category_name",
        headerName: "Category",
        fieldName: "category_name",
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "product_sale_price",
        headerName: "Sell Price",
        fieldName: "product_sale_price",
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "product_price",
        headerName: "Cost Price",
        fieldName: "product_price",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "product_type",
        headerName: "Product Type",
        fieldName: "product_type",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "insert_ts",
        headerName: "Date",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_DARK_COLOR,
        exportCellWidth: 25,
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },
    ],

    items: productsList,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };


  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, date, status, search, date]);

  React.useEffect(() => {
    return () => {
      dispatch(clearProductStateSync());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchProductListWithArgsAsync({
        status: status,
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
        columns: searchColumns,
        value: search,
        fromDate: date.fromDate,
        toDate: date.toDate,
      }),
    );
  };

  return <DataTable {...productsTableProps} />;
};
