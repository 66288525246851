import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ICampaign } from "./campaigns.types"

const GET_MARKETING_CAMPAIGNS_BASE_URL = "/marketing/get-campaigns"
const UPSERT_MARKETING_CAMPAIGNS_BASE_URL = "/marketing/upsert-campaigns"


export const fetchAllCampaignsListAsync = createAsyncThunk<{ data: ICampaign[], count: number }, IQueryParams>(
    'campaigns/fetchAllCampaignsListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_MARKETING_CAMPAIGNS_BASE_URL}${searchQuery}`)
        return response.data as { data: ICampaign[], count: number }
    },
)

export const fetchSingleCampaignAsync = createAsyncThunk<ICampaign, string>(
    'campaigns/fetchSingleCampaignAsync', async (uuid: string) => {
        const response = await axios_api.get(`${GET_MARKETING_CAMPAIGNS_BASE_URL}?campaign_uuid=${uuid}`)
        return response.data.data[0]
    },
)


export const upsertSingleCampaignAsync = createAsyncThunk<ICampaign, ICampaign>(
    'campaigns/upsertSingleCampaignAsync', async (payload: ICampaign, thunkAPI) => {
        const response = await axios_api.post(UPSERT_MARKETING_CAMPAIGNS_BASE_URL, payload)
        return response.data
    },
)


