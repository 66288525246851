import { LoadState } from "../../../constants/enum";
import { IAirlineFlight, IAirlineFlightState } from "./flights.types";


export const defaultAirlineFlight: IAirlineFlight = {
    flight_uuid: null,
    airline_name: null,
    airline_uuid: null,
    flight_name: null,
    flight_number: null,
    flight_time: null,
    status: "ACTIVE"
}

export const defaultAirlineFlightState: IAirlineFlightState = {
    flights_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_flight: {
        loading: LoadState.idle,
        data: defaultAirlineFlight,
        error: null,
    }
}