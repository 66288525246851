import React, { useState } from 'react'
import { Dialog } from '../../../../../Components/Dialogs/Dialog';
import { IProduct, IProductCustomization, IProductModifier, clearProductModifierListSync, fetchCustomizedModifiersListAsync } from '../../../../../redux/inventory';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../../redux';
import { RUPEE_SYMBOL } from '../../../../../constants/constants';
import AddIcon from '@mui/icons-material/Add';
import { addProductModifierIntoOrderSync, changeOrderItemNoteFieldSync, removeProductModifierFromOrderSync } from '../../../../../redux/orders';
import { Delete } from '@mui/icons-material';
import { OutlinedTextField } from '../../../../../Components/formComponents';

export interface ICustomizeProductDialogProps {
    open: boolean;
    product: IProduct;
    onClose: () => void;
}

export const CustomizeProductDialog: React.FC<ICustomizeProductDialogProps> = (props) => {
    const { open, product, onClose } = props;
    const dispatch = useAppDispatch();

    const currentOrderInfo = useAppSelector((state) => state.orders.single_order.data)
    const currentOrderProductInfo = currentOrderInfo.menu_items.find((item) => item.product_uuid === product.product_uuid);

    const [selectedCustomization, setSelectedCustomization] = useState<string | null>(null)

    const handleProductNotesChange = (value: string) => {
        dispatch(changeOrderItemNoteFieldSync({
            product,
            note: value
        }))
    }

    return (
        <Dialog
            open={open}
            title="Customize Product"
            size="sm"
            onClose={onClose}
            actions={[
                {
                    type: "button",
                    variant: "outlined",
                    label: "Close",
                    onClick: onClose,
                },
            ]}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "flex-start",
                    alignItems: 'flex-start',
                    gap: 2,
                    // border: "1px solid #fff",
                    // borderRadius: 1,
                    padding: 1,
                    marginBottom: 2
                }}
            >
                {(product.product_customizations || []).map((cmz) => {
                    return (
                        <Chip
                            sx={{ py: 1, px: 2 }}
                            key={cmz.customization_uuid}
                            label={cmz.customization_name}
                            variant={cmz.customization_uuid === selectedCustomization ? "filled" : "outlined"}
                            onClick={() => setSelectedCustomization(cmz.customization_uuid)}
                        />
                    )
                })}
            </Box>

            {selectedCustomization &&
                <CustomizationModifiers
                    product={product}
                    customizationUUID={selectedCustomization}

                />}
            <Box sx={{paddingInline: 2, mt: 1}}>
                <OutlinedTextField
                    label="Notes"
                    multiline
                    minRows={4}
                    value={currentOrderProductInfo?.notes}
                    onChange={(evt) => handleProductNotesChange(evt.target.value)}
                />
            </Box>
        </Dialog>
    );
};


interface ICustomizationModifiers {
    customizationUUID: string;
    product: IProduct;
}
const CustomizationModifiers: React.FC<ICustomizationModifiers> = ({
    customizationUUID,
    product
}) => {
    const {
        data: product_modifiers_list,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.inventory.customization.product_modifiers_list);

    const dispatch = useAppDispatch();

    const currentOrderInfo = useAppSelector((state) => state.orders.single_order.data)
    const customizedOptions = currentOrderInfo.menu_items.find((item) => item.product_uuid === product.product_uuid)?.options;


    React.useEffect(() => {
        dispatch(
            fetchCustomizedModifiersListAsync(customizationUUID),
        );
    }, [customizationUUID]);

    React.useEffect(() => {
        return () => {
            dispatch(clearProductModifierListSync());
        };
    }, []);


    const handleAddModifierClick = (option: IProductModifier) => {
        dispatch(addProductModifierIntoOrderSync({
            product,
            modifier: option
        }))
    }

    const handleDeleteModifierClick = (option: IProductModifier) => {
        dispatch(removeProductModifierFromOrderSync({
            product,
            modifier: option
        }))
    }

    console.log("currentOrderInfo ===>", currentOrderInfo)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "flex-start",
                alignItems: 'flex-start',
                gap: 2,
                borderTop: product_modifiers_list.length > 0 ? "2px solid #fff" : "none",
            }}
        >
            {product_modifiers_list.map((opt, opt_index) => {
                return (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            alignItems: 'center',
                            gap: 2,
                            // border: "1px solid #fff",
                            // borderRadius: 1,
                            paddingBlock: 1,
                            paddingInline: 2,
                        }}
                    >
                        <Typography
                            variant='h5'
                        >{opt.modifier_name}</Typography>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: 'row',
                                justifyContent: "flex-end",
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Typography
                                variant='h5'
                            >{`${RUPEE_SYMBOL} ${opt.modifier_price}`}</Typography>

                            {customizedOptions?.findIndex(item => item.modifier_uuid === opt.modifier_uuid) === opt_index ?
                                <Button
                                    variant="outlined"
                                    color="error"
                                    endIcon={<Delete />}
                                    onClick={() => handleDeleteModifierClick(opt)}
                                >
                                    Delete
                                </Button>
                                :
                                <Button
                                    variant="outlined"
                                    endIcon={<AddIcon />}
                                    onClick={() => handleAddModifierClick(opt)}
                                >
                                    Add
                                </Button>
                            }
                        </Box>
                        {/* <Chip
                            key={opt.modifier_uuid}
                            label={`${opt.modifier_name}  ${RUPEE_SYMBOL} ${opt.modifier_price}`}
                            variant="outlined"
                            // onClick={() => setSelectedCustomization(cmz.customization_uuid)}
                            // onDelete={handleDelete}
                            deleteIcon={ }
                        /> */}
                    </Box>
                )
            })}
        </Box>
    )
}