import { IPosOrder } from "./pos-orders.types"

export const POS_ORDERS_KEY = "pos-orders"
export const setPosOrdersInLocalStore = (data: IPosOrder[]) => {
    localStorage.setItem(POS_ORDERS_KEY, JSON.stringify(data))
}

export const getPosOrdersFormLocalStore = (): IPosOrder[] => {
    const data = localStorage.getItem(POS_ORDERS_KEY)
    if (data) {
        return JSON.parse(data)
    }
    return []
}