import { ORDERS_ROUTE } from "./Orders.constants";
import { OrdersTable } from "./OrdersTable";
import { ViewSingleOrder } from "./ViewSingleOrder";

export const orderRoutes = [
    {
        path: ORDERS_ROUTE,
        exact: true,
        element: <OrdersTable />
    },
    {
        path: `${ORDERS_ROUTE}/view/:uuid`,
        exact: true,
        element: <ViewSingleOrder />
    },
] 