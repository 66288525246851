import React, { useEffect, useState } from "react";
import { Grid, } from "@mui/material";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { ControlledCustomSelect, OutlinedTextField } from "../../../../Components/formComponents";
import { useFormik } from "formik";
import { IReservation, defaultReservation, reserveTableByNumberSync, upsertNewReservationsSync } from "../../../../redux/table-and-reservations";
import { useAppDispatch } from "../../../../redux";
import { CustomDatePicker, OutlinedTimePicker } from "../../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { ICustomTableNode } from "../../../../Components/ReactFlowShapes/ReactFlowShapes.types";
import { IShiftPunchInOut, addNewShiftsSync, defaultShiftPunchInOut, upsertSingleShiftPunchInOutAsync } from "../../../../redux/shifts";


export interface IUpsertReservationDialogProps {
  open: boolean;
  shift?: IShiftPunchInOut
  onClose: () => void;
}


export const UpsertUserShiftDialog: React.FC<IUpsertReservationDialogProps> = (
  props
) => {
  const { open, shift, onClose } = props;
  const dispatch = useAppDispatch();



  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: shift ? shift : defaultShiftPunchInOut,
    validate: (values) => {
      const errors: any = {};
      if (!values.user_name) {
        errors.user_name = "*This field is required.";
      }
      // if (!values.user_email) {
      //   errors.user_email = "*This field is required.";
      // }
      // if (!values.user_phone_no) {
      //   errors.user_phone_no = "*This field is required.";
      // }
      // if (!values.reservation_date) {
      //   errors.reservation_date = "*This field is required.";
      // }
      // if (!values.reservation_from_time) {
      //   errors.reservation_from_time = "*This field is required.";
      // }
      // if (!values.reservation_to_time) {
      //   errors.reservation_to_time = "*This field is required.";
      // }
      // if (!values.number_of_people) {
      //   errors.number_of_people = "*This field is required.";
      // }
      // if (!values.table_no) {
      //   errors.table_no = "*This field is required.";
      // }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(upsertSingleShiftPunchInOutAsync({
        ...values,
        file: null
      })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      })
    },
  });

  return (
    <Dialog
      open={open}
      title="Add Reservation"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              label="User Name"
              id="user_name"
              value={values.user_name}
              onChange={handleChange}
              error={errors.user_name}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTimePicker
              label="Punch In"
              fullWidth
              value={moment(values.punch_in)}
              onChange={(newValue) => {
                setFieldValue("punch_in", newValue.toISOString());
              }}
            // error={errors.reservation_date}
            />
          </Grid>

          <Grid item xs={12}>
            <OutlinedTimePicker
              label="Break In"
              fullWidth
              value={moment(values.break_in)}
              onChange={(newValue) => {
                setFieldValue("break_in", newValue.toISOString());
              }}
            // error={errors.reservation_date}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTimePicker
              label="Break out"
              fullWidth
              value={moment(values.break_out)}
              onChange={(newValue) => {
                setFieldValue("break_out", newValue.toISOString());
              }}
            // error={errors.reservation_date}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTimePicker
              label="Punch Out"
              fullWidth
              value={moment(values.punch_out)}
              onChange={(newValue) => {
                setFieldValue("punch_out", newValue.toISOString());
              }}
            // error={errors.reservation_date}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomDatePicker
              label="Punch Date"
              fullWidth
              fieldName="punch_date"
              value={values.punch_date}
              onChange={(newValue, formattedDate) => {
                setFieldValue("punch_date", formattedDate);
              }}
            // error={errors.punch_date}
            />
          </Grid>

        </Grid>
      </form>
    </Dialog>
  );
};
