import { LoadState } from "../../constants/enum";
import { getUniqueId } from "../../helpers";

export interface IOrderState {
  list: IOrder[];
  data: IOrder;
  loading: LoadState;
}

export interface IOrder {
  id: string;
  name: string;
  price: number;
  quantity: number;
}

export const initialOrderState: IOrderState = {
  list: [],
  data: {
    id: getUniqueId(),
    name: "Apple",
    price: 0,
    quantity: 0,
  },
  loading: LoadState.idle,
};
