import { Box, Button, Grid, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableManagementCard } from "../Reservations/components/TableManagementCard/TableManagementCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  TablesReserveChip,
} from "../../Components/CustomChip";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { ControlledCustomSelect, CustomSelect } from "../../Components/formComponents";
import { useNavigate } from "react-router-dom";
import { TABLES_LAYOUT_ROUTE, defaultTablesList } from "./Tables.constants";
import { StandardTableCard } from "./components";
import { ICustomTableNode } from "../../Components/ReactFlowShapes/ReactFlowShapes.types";
import { ReactFlowShapesView } from "../../Components/ReactFlowShapes/ReactFlowShapesView";
import { TableBottom } from "./components/TableBottom";
import { IStoreState, useAppDispatch, useAppSelector } from "../../redux";
import { fetchSingleTableLayoutAsync, getTablesListSync } from "../../redux/table-and-reservations";

export const Tables: React.FC = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  const {
    data: tablesLayout,
  } = useAppSelector((storeState: IStoreState) => storeState.tableAndReservation.tables.single_tables_layout);

  const [tablesList, setTablesList] = useState<ICustomTableNode[]>([])

  const [searchFilter, setSearchFilter] = useState<ICustomTableNode["data"]["availability"] | "-1">("-1")

  useEffect(() => {
    dispatch(fetchSingleTableLayoutAsync(""))
  }, [])


  useEffect(() => {
    setTablesList(tablesLayout.tables)
  }, [tablesLayout])

  useEffect(() => {
    if(searchFilter.length > 0) {
      const newList = tablesLayout.tables.map((table) => {
        if(table.data.availability === searchFilter) {
          return table
        }
        return {
          ...table,
          data: {
            ...table.data,
            disabled: true
          }
        }
      })
      setTablesList(newList)
    }
  }, [searchFilter])

  return (
    <PageContainer title="Tables" description="Tables page">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={12} md={9}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction={"row"} alignItems="center" spacing={2}>
              <Typography variant="h1" fontWeight={"bold"}>
                Tables
              </Typography>
              <Button variant="contained" size="small" onClick={() => navigate(TABLES_LAYOUT_ROUTE)}  >Edit Layout</Button>
            </Stack>

            <Stack direction={"row"} alignItems="center" spacing={2}>
              <TableBottom />
              <ControlledCustomSelect
                options={[
                  { label: "None", value: "-1" },
                  { label: "Free", value: "FREE" },
                  { label: "Reserved", value: "RESERVED" },
                  { label: "Checked-In", value: "CHECKED_IN" },
                ]}

                value={searchFilter}
                onChange={(evt) => setSearchFilter(evt.target.value as any)}
                sx={{ minWidth: 120 }}
                fullWidth size="small" />
            </Stack>
          </Stack>
          {/* <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            style={{
              height: "65vh",
              overflowY: "scroll",
              marginTop: "18px",
              paddingRight: "12px",
            }}
          >
            <Masonry gutter="12px">
              {tablesList.map((table, t_idx) => {
                return <StandardTableCard data={table} />
              })}
            </Masonry>
          </ResponsiveMasonry> */}

          <Box sx={{
            height: "85vh"
          }}>
            <ReactFlowShapesView
              initialNodes={tablesList}
            />
          </Box>


        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TableManagementCard />
        </Grid>
      </Grid>
    </PageContainer>
  );
};
