/* eslint-disable react-hooks/exhaustive-deps */
import React, { SyntheticEvent } from "react";
import {
    Autocomplete,
    CircularProgress,
    Stack,
} from "@mui/material";
import { debounce } from "lodash";
import { CustomTextField, OutlinedTextField } from "../../../../Components/formComponents";
import { axios_api } from "../../../../api/axios-api";
import { IAirlineFlight, defaultAirlineFlight } from "../../../../redux/airlineAndFlights";

export interface IFlightAutoSearchProps {
    label?: string;
    name: string;
    airlineUUID?: string;
    value: {
        flight_uuid: string | null,
        flight_name: string | null,
    };
    onSelect: (data: IAirlineFlight) => void;
    disabled?: boolean;
    error?: string;
}

const INITIAL_STATE: IAirlineFlight = defaultAirlineFlight;

export const FlightAutoSearch: React.FC<IFlightAutoSearchProps> = ({
    label, name, value, airlineUUID, disabled, error, onSelect
}) => {

    const [options, setOptions] = React.useState<readonly IAirlineFlight[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [search, setSearchText] = React.useState<string>("");
    const fetchSuggestion = async (enteredValue: string, airline_uuid?: string) => {
        setLoading(true);
        try {
            let searchQuery = "";
            if (airline_uuid) {
                searchQuery += `airline_uuid=${airline_uuid}&`;
            }
            if (enteredValue.length > 0) {
                searchQuery += `columns=flight_name&value=${enteredValue}&`;
            }
            const res = await axios_api.get(
                `/airlines/get-flight?${searchQuery}`,
            );
            const finalData: IAirlineFlight[] = res.data.data;
            if (value?.flight_uuid && value?.flight_uuid?.length > 0 && search.length === 0) {
                const option: IAirlineFlight = {
                    ...INITIAL_STATE,
                    airline_uuid: value.flight_uuid,
                    airline_name: value.flight_name,
                };
                finalData.push(option);
            }
            setOptions(finalData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };
    const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

    const getOptionLabel = (option: IAirlineFlight | string) => {
        if (typeof option === "string") return option || "";
        return `${option.flight_number || ""} ${option.flight_name ? `${option.flight_name}` : ""}`
    };

    const getValue = () => {
        return (
            options.find((option) => option.flight_uuid === value.flight_uuid) ||
            null
        );
    };

    const handleDropDownOnFocus = () => {
        debounceFn("", airlineUUID);
    };


    React.useEffect(() => {
        if (search && search.length > 0 && search !== value.flight_name) {
            debounceFn(search, airlineUUID);
        }
    }, [search, airlineUUID]);

    React.useEffect(() => {
        if (value.flight_uuid && value.flight_uuid.length > 0) {
            const option: IAirlineFlight = {
                ...INITIAL_STATE,
                flight_uuid: value.flight_uuid,
                flight_name: value.flight_name,
            };
            setOptions([option])
        } else {
            setOptions([]);
        }
    }, [value]);

    return (
        <>
            <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
                <Autocomplete
                    disablePortal
                    fullWidth
                    freeSolo
                    disabled={disabled}
                    sx={{
                        minWidth: 180,
                        ".MuiOutlinedInput-root": {
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            fontSize: "0.8rem",
                            color: "rgb(38, 38, 38)",
                            width: "100%",
                            backgroundColor: disabled ? "#f8f9fb" : "inherit",
                        },
                    }}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={(option, value) =>
                        typeof option !== "string" && typeof value !== "string"
                            ? option.airline_name === value.airline_name
                            : option === value
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    value={getValue()}
                    noOptionsText="No flight info"
                    onFocus={handleDropDownOnFocus}
                    onChange={(
                        event: SyntheticEvent<Element, Event>,
                        newValue: string | IAirlineFlight | null,
                    ) => {
                        if (newValue && typeof newValue !== "string") {
                            onSelect(newValue);
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        if ((event && event.type === "change") || !newInputValue) {
                            setSearchText(newInputValue);
                        }
                    }}
                    renderInput={(params) => (
                        <OutlinedTextField
                            name={name}
                            {...params}
                            fullWidth
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading && <CircularProgress color="inherit" size={20} />}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            disabled={disabled}
                            error={error}
                        />
                    )}
                />

            </Stack>
        </>
    );
};
