import React from "react";
// import { DataTable, ITableColumn } from "../../../Components/Tables/Table";
import { Avatar, Button } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../Components/Table/DataTable";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { useNavigate } from "react-router-dom";
import { StandardTableActions } from "../../../../Components/StandardTableActions/StandardTableActions";
import { UpsertPaymentsReceivedDialog } from "./dialogs/UpsertPaymentsReceivedDialog";
import { CustomButton } from "../../../../Components/CustomButton";
import { IPaymentReceived, clearPaymentReceivedStateSync, defaultPaymentReceived, fetchPaymentReceivedListWithArgsAsync } from "../../../../redux/accounting";

export const PaymentsReceivedTable = () => {
  const {
    data: payments_received_list,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.pointOfSale.accounting.paymentReceived.payments_received_list);

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUpsertDialog, setOpenUpsertDialog] = React.useState<IPaymentReceived | null>(null);


  const dataTableProps: IDataTableProps = {
    isPagination: true,
    rowsPerPageOptions: 25,
    isDataLoading: false,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    initialSortConfig: {
      insert_ts: {
        direction: "desc",
        filedName: "insert_ts",
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPaymentReceived) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "order_type",
        headerName: "Order Type",
        fieldName: "order_type",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "order_number",
        headerName: "Order Number",
        fieldName: "order_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "invoice_number",
        headerName: "Invoice Number",
        fieldName: "invoice_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "total_tax",
        headerName: "Total Tax",
        fieldName: "total_tax",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "total_amount",
        headerName: "Total Amount",
        fieldName: "total_amount",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "order_status",
        headerName: "Order Status",
        fieldName: "order_status",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      // {
      //   key: "insert_ts",
      //   headerName: "Date",
      //   fieldName: "insert_ts",
      //   enableSorting: true,
      //   renderType: RenderType.DATE_DARK_COLOR,
      //   exportCellWidth: 25,
      //   onExportRender: (values) => {
      //     return formatDateWithTime(values.value);
      //   },
      // },
      {
        key: "view",
        headerName: "View",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IPaymentReceived) => {
          return (
            <Button variant="contained" >View Invoice</Button>
          );
        },
      },
    ],

    items: payments_received_list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };


  React.useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearPaymentReceivedStateSync());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchPaymentReceivedListWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      }),
    );
  };

  return (
    <>
      <CustomButton
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          marginLeft: "auto",
          marginY: 2,
        }}
        onClick={() => setOpenUpsertDialog(defaultPaymentReceived)}
      >
        Add New
      </CustomButton>
      <DataTable {...dataTableProps} />
      {openUpsertDialog && <UpsertPaymentsReceivedDialog open={true} initialState={openUpsertDialog} onClose={() => setOpenUpsertDialog(null)} />}
    </>
  );
};
