import React from "react";
import { TabPanel } from "../../../Components/Tab/TabPanel";
import { TabsList } from "../../../Components/Tab/Tab";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { ACCOUNTING_BANK_INFO_ROUTE, ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE, ACCOUNTING_PAYMENT_PAID_ROUTE, ACCOUNTING_PAYMENT_RECEIVED_ROUTE } from "./Accounting.constants";
import { PaymentsReceivedTable } from "./PaymentsReceived";
import { PaymentsPaidTable } from "./PaymentsPaid";
import { BankInfoTable } from "./BankInfo";
import { ChartOfAccountTable } from "./ChartOfAccount";

export interface IAccountingTabsPanelProps {
  activeTab: number;
}

export const AccountingTabsPanel: React.FC<IAccountingTabsPanelProps> = (props) => {
  const { activeTab } = props;
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(activeTab || 0);

  const handleTabChange = (url: string) => {
    navigate(url);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) handleTabChange(ACCOUNTING_PAYMENT_RECEIVED_ROUTE);
    if (newValue === 1) handleTabChange(ACCOUNTING_PAYMENT_PAID_ROUTE);
    if (newValue === 2) handleTabChange(ACCOUNTING_BANK_INFO_ROUTE);
    if (newValue === 3) handleTabChange(ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE);
  };


  return (
    <PageContainer title="Accounting" description="inventory page" sx={{paddingInline: 2}}>
      <TabsList
        selectedTab={tab}
        tabData={[
          { tabLabel: "Payments Received", tabLink: ACCOUNTING_PAYMENT_RECEIVED_ROUTE },
          { tabLabel: "Payments Paid", tabLink: ACCOUNTING_PAYMENT_PAID_ROUTE },
          { tabLabel: "Bank Information", tabLink: ACCOUNTING_BANK_INFO_ROUTE },
          { tabLabel: "Chart of Accounts", tabLink: ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE },
        ]}
        onChange={handleChange}
      />
      <TabPanel value={tab} index={0}>
        <PaymentsReceivedTable />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <PaymentsPaidTable />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <BankInfoTable />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <ChartOfAccountTable />
      </TabPanel>

      {/* {categoryDialogOpen && (
        <ManageCategoryDialog
          open={true}
          category={defaultProductCategory}
          onClose={() => setCategoryDialogOpen(false)}
        />
      )}

      {inventoryListDialogOpen && (
        <ManageInventoryDialog
          open={true}
          inventory={defaultInventory}
          onClose={() => setInventoryListDialogOpen(false)}
        />
      )}
      {customizationDialogOpen && (
        <ManageCustomizationDialog
          open={true}
          customization={defaultProductCustomization}
          onClose={() => setCustomizationDialogOpen(false)}
        />
      )} */}
    </PageContainer>
  );
};
