import { createSlice } from "@reduxjs/toolkit";
import { initialOrderState } from "./Order.types";
import { getUniqueId } from "../../helpers";

const orderSlice = createSlice({
  initialState: initialOrderState,
  name: "order",
  reducers: {
    upsertOrderSync: (state, action) => {
      const { name, price, quantity } = action.payload;
      const isPresent = state.list.find((item) => item.name === name);
      if (isPresent) {
        state.list = state.list.map((item) => {
          if (item.name === name) {
            item.quantity = item.quantity + quantity;
            item.price = item.price + price;
          }
          return item;
        });
      } else {
        state.list.push({
          id: getUniqueId(),
          name,
          price,
          quantity,
        });
      }
    },
    removeOrderSync: (state, action) => {
      const { name, price } = action.payload;
      const isPresent = state.list.find((item) => item.name === name);
      if (isPresent) {
        state.list = state.list.map((item) => {
          if (item.name === name) {
            item.quantity = item.quantity - 1;
            item.price = item.price - price;
          }
          return item;
        });
        state.list = state.list.filter((item) => item.quantity > 0);
      }
    },
  },
});

export default orderSlice.reducer;
export const { upsertOrderSync, removeOrderSync } = orderSlice.actions;
