import React from "react";

import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider, renderTimeViewClock } from "@mui/x-date-pickers";
import { Typography } from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

export const CustomDatePicker: React.FC<{
  label?: string
  fieldName?: string;
  value: string;
  sx?: any;
  disabled?: boolean;
  views?: ("day" | "month" | "year")[];
  inputFormat?: string;
  fullWidth?: boolean;
  onChange: (value: any, formattedDate: string) => void;
  error?: string;
}> = (props) => {
  const {
    fieldName,
    value,
    sx,
    views = ["year", "month", "day"],
    inputFormat = "DD-MM-YYYY",
    fullWidth,
    disabled,
    error,
    label
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        label={label}
        name={fieldName}
        views={views}
        key={value}
        disabled={disabled}
        format={inputFormat}
        sx={{
          width: fullWidth ? "100%" : "unset",
          "& .MuiOutlinedInput-input": {
            padding: "9px 13px",
            fontSize: "0.8rem",
            color: "#fff" //"rgb(38, 38, 38)",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: (theme) => `${theme.palette.mode === "dark" ? "#fff" : "#dee3e9"}`,
            borderRadius: "5px",
          },
          "& .Mui-error": {
            color: '#fff !important',
          },
          '& label': {
            color: '#fff',
          },
          "& .MuiOutlinedInput-root": {
            padding: "2px 10px",
            fontSize: "1rem",
            '& fieldset': {
              borderColor: '#fff !important',
            },
          },
          "& .MuiFormHelperText-root": {
            color: "#ff0000 !important",
          },
        }}
        value={(moment(value) && value.length > 0 ? moment(value) : null) as any}
        onChange={(newValue) => {
          const formattedDate = moment(newValue).format("YYYY-MM-DD");
          props.onChange(newValue, formattedDate);
        }}
        slotProps={{
          textField: {
            size: "small",
            label: label,
            error: !!error,
            helperText: error,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const OutlinedTimePicker: React.FC<{
  label: string;
  value: Moment;
  sx?: any;
  fullWidth?: boolean;
  onChange: (value: Moment) => void;
  error?: string;
  format?: string 
}> = (props) => {
  const { value, sx, error, label, fullWidth, format = "HH:mm:ss" } = props;
  return (

    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        label={label}
        value={moment(value) as any}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        format={format}
        onChange={(newValue) => {
          if (newValue) {
            props.onChange(newValue);
          }
        }}
        sx={{
          width: fullWidth ? "100%" : "unset",
          "& .MuiOutlinedInput-input": {
            padding: "9px 13px",
            fontSize: "1rem",
            color: "#fff" //"rgb(38, 38, 38)",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: (theme) => `${theme.palette.mode === "dark" ? "#fff" : "#dee3e9"}`,
            borderRadius: "5px",
          },
          "& .Mui-error": {
            color: '#fff !important',
          },
          '& label': {
            color: '#fff',
          },
          "& .MuiOutlinedInput-root": {
            '& fieldset': {
              borderColor: '#fff !important',
            },
          },
          "& .MuiFormHelperText-root": {
            color: "#ff0000 !important",
          },
          ...sx
        }}
        slotProps={{
          textField: {
            size: "small",
            error: !!error,
            helperText: error,
            label: label
          },
        }}
      />
    </LocalizationProvider>


  );
};
