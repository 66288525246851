import React, { useEffect, useState } from "react";
import { Grid, } from "@mui/material";
import { Dialog } from "../../../Components/Dialogs/Dialog";
import { ControlledCustomSelect, OutlinedTextField } from "../../../Components/formComponents";
import { useFormik } from "formik";
import { IReservation, defaultReservation, fetchSingleTableLayoutAsync, reserveTableByNumberSync, upsertNewReservationsSync, upsertSingleReservationAsync } from "../../../redux/table-and-reservations";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { CustomDatePicker, OutlinedTimePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { ICustomTableNode } from "../../../Components/ReactFlowShapes/ReactFlowShapes.types";


export interface IUpsertReservationDialogProps {
  open: boolean;
  reservation?: IReservation
  onClose: () => void;
}


export const UpsertReservationDialog: React.FC<IUpsertReservationDialogProps> = (
  props
) => {
  const { open, reservation, onClose } = props;
  const dispatch = useAppDispatch();
  const {
    data: tablesLayout,
  } = useAppSelector((storeState: IStoreState) => storeState.tableAndReservation.tables.single_tables_layout);
  const [tablesList, setTablesList] = useState<{ label: string, value: string }[]>([])


  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: reservation ? reservation : defaultReservation,
    validate: (values) => {
      const errors: any = {};
      if (!values.user_name) {
        errors.user_name = "*This field is required.";
      }
      if (!values.user_email) {
        errors.user_email = "*This field is required.";
      }
      if (!values.user_phone_no) {
        errors.user_phone_no = "*This field is required.";
      }
      if (!values.reservation_date) {
        errors.reservation_date = "*This field is required.";
      }
      if (!values.reservation_from_time) {
        errors.reservation_from_time = "*This field is required.";
      }
      if (!values.reservation_to_time) {
        errors.reservation_to_time = "*This field is required.";
      }
      if (!values.number_of_people) {
        errors.number_of_people = "*This field is required.";
      }
      if (!values.table_no) {
        errors.table_no = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      // dispatch(reserveTableByNumberSync(Number(values.table_no)))
      // dispatch(upsertNewReservationsSync(values))

      setSaveLoading(true);
      dispatch(
        upsertSingleReservationAsync(values)
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      }).finally(() => {
        setSaveLoading(false);
        // onClose()
      });
    },
  });


  useEffect(() => {
    dispatch(fetchSingleTableLayoutAsync(""))
  }, [])



  useEffect(() => {
    const newList = tablesLayout.tables.filter((table) => table.data.nodeType === "TABLE").map((table) => ({
      label: table.data.tableNumber.toString(), value: table.data.tableNumber.toString()
    }))
    setTablesList(newList)
  }, [tablesLayout])


  return (
    <Dialog
      open={open}
      title="Add Reservation"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Name"
              id="user_name"
              value={values.user_name}
              onChange={handleChange}
              error={errors.user_name}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Email"
              id="user_email"
              value={values.user_email}
              onChange={handleChange}
              error={errors.user_email}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Phone Number"
              id="user_phone_no"
              value={values.user_phone_no}
              onChange={handleChange}
              error={errors.user_phone_no}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDatePicker
              label="Reservation Date"
              fullWidth
              fieldName="reservation_date"
              value={values.reservation_date}
              onChange={(newValue, formattedDate) => {
                setFieldValue("reservation_date", formattedDate);
              }}
              error={errors.reservation_date}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTimePicker
              label="From Time"
              value={moment(values.reservation_from_time)}
              fullWidth
              format="HH:mm A"
              onChange={(date) => {
                setValues({
                  ...values,
                  reservation_from_time: date.toISOString(),
                  reservation_to_time: date.add("hour", 1).add("minute", 30).toISOString()
                })
              }}
              error={errors.reservation_from_time}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTimePicker
              label="To Time"
              value={moment(values.reservation_to_time)}
              fullWidth
              format="HH:mm A"
              onChange={(date) => {
                setFieldValue("reservation_to_time", date.toISOString());
              }}
              error={errors.reservation_to_time}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="No. of People"
              id="number_of_people"
              value={values.number_of_people}
              onChange={handleChange}
              error={errors.number_of_people}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Table Number"
              name="table_no"
              fullWidth
              options={tablesList}
              placeholder="Select Table"
              value={values.table_no.toString()}
              onChange={(evt) => {
                setFieldValue("table_no", Number(evt.target.value))
              }}
              error={errors.table_no ? true : false}
              helperText={errors.table_no}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
