import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { TextField, TextFieldProps } from "@mui/material";

export const CustomTextField = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .Mui-error": {
    color: '#fff !important',
  },

  '& label.Mui-focused': {
    color: '#fff',
  },
  "& label": {
    color: '#fff',
  },
  "& .MuiOutlinedInput-input": {
    padding: "9px 13px",
    fontSize: "1rem",
    color: "#fff",
    letterSpacing: "0.5px",
    '& fieldset': {
      borderColor: '#fff',
    },
  },
  "& .MuiOutlinedInput-root": {
    '& fieldset': {
      borderColor: '#fff',
    },
  },
  "& .MuiOutlinedInput-root.Mui-error": {
    '& fieldset': {
      borderColor: '#fff',
    },
  },
  "& .MuiOutlinedInput-input.Mui-error": {
    '& fieldset': {
      borderColor: '#fff',
      color: "red"
    },
  },
  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-input::-webkit-autofill": {
    "-webkit-box-shadow": "#000",
    background: "red"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.palette.mode === "dark" ? "#fff" : "#dee3e9"
      }`,
    borderRadius: "5px",
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${theme.palette.mode === "dark" ? "none" : "#f8f9fb "
      }`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.12) ',
    },
  },
  "& .MuiFormHelperText-root": {
    color: "#ff0000 !important",
  },
}));

type IOutlinedTextField = Omit<TextFieldProps, 'error'> & {
  error?: string
}
export const OutlinedTextField = (props: IOutlinedTextField) => {

  const [value, setValue] = useState<unknown>(null)

  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <CustomTextField
      {...props}
      label={props.label}
      id={props.id}
      size={props.size ? props.size : "small"}
      fullWidth
      InputLabelProps={{
        shrink: props.value ? true : undefined
      }}
      value={value?.toString()}
      onChange={(evt) => {
        if (!props.onChange) return
        if (props.type === "number") {
          let newEvt = { ...evt }
          const onlyNums = newEvt.target.value.replace(/[^0-9]/g, "");
          newEvt.target.value = Number(onlyNums) as any
          props.onChange(newEvt)
        } else {
          props.onChange(evt)
        }
      }}
      error={props.error ? true : false}
      helperText={props.error}
    />
  )
}









export const CustomTextFieldV2 = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  "& input[type=number]": {
    "-moz-appearance": "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "& .MuiInputLabel-root": {
    color: "#fff",
    background: "#252525",
    paddingInline: "4px",
    borderRadius: "2px"
  },
  "& .MuiOutlinedInput-root": {
    background: "#252525",
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "1rem",
    color: "#fff",
    letterSpacing: "0.5px",
  },
  "& .MuiOutlinedInput-input::-webkit-autofill": {
    "-webkit-box-shadow": "#000",
  },
  "& .MuiOutlinedInput-input::-webkit-input-placeholder": {
    color: "#767e89",
    opacity: "1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${theme.palette.mode === "dark" ? "#000 !important" : "#dee3e9"
      }`,
    borderRadius: "5px",
    borderWidth: "0px !important"
  },

  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.12) " : "#f8f9fb "
      }`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled::-webkit-input-placeholder": {
    color: "#000",
    opacity: "1",
  },

}));