import React from "react";
import { ICustomerNameCardProps } from "../interface/interface";
import { Avatar, Stack, Typography } from "@mui/material";
import { getRandomColors } from "../../../helpers/getRandomColors";
import { NavLink } from "react-router-dom";
import "./CustomerNameCard.css";

export const CustomerNameCard: React.FC<ICustomerNameCardProps> = (props) => {
  const { name, url } = props;
  return (
    <NavLink to={url} style={{ textDecoration: "none" }}>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        sx={{
          border: "1px solid #E0E0E0",
          p: 3,
          gap: 2,
          my: 2,
          borderRadius: 1,
        }}
        className="customer_name_card"
      >
        <Avatar
          sx={{
            bgcolor: getRandomColors(),
            fontWeight: "bold",
            fontSize: "1.5rem",
            width: "54px",
            height: "54px",
          }}
        >
          {name.charAt(0)}
        </Avatar>
        <Typography
          variant="h3"
          fontWeight={"bold"}
          letterSpacing={0.5}
          color={"#fff"}
          className="customer_name_card__name"
        >
          {name}
        </Typography>
      </Stack>
    </NavLink>
  );
};
