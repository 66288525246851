import { APP_MODULE_ACCESS_LIST } from "../../../constants/enum";
import { AIRLINE_ROUTES, AIRLINE_FLIGHTS_ROUTES, FLIGHT_MENU_ROUTES } from "../../../views/AirlineAndFlights/AirlineAndFlights.constants";
import { DASHBOARD_ROUTES } from "../../../views/Dashboard/Dashboard.constants";
import { PRODUCTS_ROUTE } from "../../../views/Inventory/Inventory.constants";
import { MARKETING_CAMPAIGNS_ROUTE, MARKETING_COUPON_CODES_ROUTE } from "../../../views/Marketing";
import { ORDERS_ROUTE } from "../../../views/Orders/Orders.constants";
import { COMPANY_ROUTE } from "../../../views/Settings/Company/Companies.constants";
import { COMPANY_OUTLETS_ROUTE } from "../../../views/Settings/CompanyOutlets/CompanyOutlets.constants";
import { RECEIPT_COMPOSER_ROUTE } from "../../../views/Settings/ReceiptComposer/ReceiptComposer.constants";
import { SECURITY_APPROVALS_ROUTE, SECURITY_GROUPS_ROUTE, SECURITY_ROLE_GROUPS_ROUTE } from "../../../views/Settings/Security/Security.constatnts";
import { TAX_INFO_ROUTE } from "../../../views/Settings/TaxInfo/TaxInfo.constatnts";
import { ALL_USERS_LIST_ROUTE } from "../../../views/Settings/UserProfile/UserProfile.constant";
import { ALL_SHIFTS_ROUTE, SHIFTS_PUNCH_IN_OUT_ROUTE } from "../../../views/ShiftAndPunchInOut/ShiftAndPunchInOut.constants";
import { ACCOUNTING_PAYMENT_RECEIVED_ROUTE } from "../../../views/pos/Accounting/Accounting.constants";
import { POS_ORDER_MENU_ROUTE, POS_ORDERS_ROUTE } from "../../../views/pos/MenuAndOrders/MenuAndOrders.constants";
import { ISidebarMenuItem } from "../../Sidebar/Siderbar.types";

export const AirKitchenMenu: ISidebarMenuItem[] = [
  {
    title: "Dashboard",
    icon: "Dashboard",
    href: DASHBOARD_ROUTES,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Airline Menu",
    icon: "Dashboard",
    href: AIRLINE_ROUTES,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Flights",
    icon: "Dashboard",
    href: AIRLINE_FLIGHTS_ROUTES,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Flight Orders",
    icon: "Dashboard",
    href: FLIGHT_MENU_ROUTES,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Kitchen Orders",
    icon: "Orders",
    href: ORDERS_ROUTE,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Profile",
    icon: "Profile",
    href: "/profile",
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
];


export const posMenu: ISidebarMenuItem[] = [
  {
    title: "Dashboard",
    icon: "Dashboard",
    href: DASHBOARD_ROUTES,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
  },
  {
    title: "Menu",
    icon: "Menu",
    href: `${POS_ORDER_MENU_ROUTE}/new`,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Orders",
    icon: "Inventory",
    href: POS_ORDERS_ROUTE,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Tables",
    icon: "Table",
    href: "/pos/tables",
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Reservations",
    icon: "Reservations",
    href: "/pos/reservations",
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Accounting",
    icon: "Accounting",
    href: ACCOUNTING_PAYMENT_RECEIVED_ROUTE,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  {
    title: "Inventory",
    icon: "Inventory",
    href: PRODUCTS_ROUTE,
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  },
  // {
  //   title: "Profile",
  //   icon: "Profile",
  //   href: POS_USER_PROFILE_ROUTE,
  //   accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS]
  // },
  {
    title: "Shifts",
    icon: "Profile",
    href: "/pos/shifts",
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    children: [
      {
        title: "All Shifts",
        icon: "Profile",
        href: ALL_SHIFTS_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
        children: [

        ]
      },
      {
        title: "Punch In/Out",
        icon: "Profile",
        href: SHIFTS_PUNCH_IN_OUT_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
    ]
  },
  {
    title: "Marketing",
    icon: "Marketing",
    href: "/pos/Marketing",
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    children: [
      {
        title: "Coupons",
        icon: "Setting",
        href: MARKETING_COUPON_CODES_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
      {
        title: "Campaigns",
        icon: "Setting",
        href: MARKETING_CAMPAIGNS_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
    ]
  },
  {
    title: "Setting",
    icon: "Setting",
    href: "/pos/setting",
    accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
    children: [
      {
        title: "company",
        icon: "Setting",
        href: COMPANY_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
      {
        title: "Outlets",
        icon: "Setting",
        href: COMPANY_OUTLETS_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
      // {
      //   title: "Tax",
      //   icon: "Setting",
      //   href: TAX_INFO_ROUTE,
      //   accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      // },
      {
        title: "Receipts",
        icon: "Setting",
        href: RECEIPT_COMPOSER_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
      {
        title: "Users",
        icon: "Setting",
        href: ALL_USERS_LIST_ROUTE,
        accessibleModules: [APP_MODULE_ACCESS_LIST.CUSTOMERS],
      },
      {
        title: "Security",
        icon: "shields",
        href: SECURITY_GROUPS_ROUTE,
        navLabel: false,
        accessibleModules: [APP_MODULE_ACCESS_LIST.SECURITY],
      },
      // {
      //   title: "Approval",
      //   icon: "shield",
      //   href: SECURITY_APPROVALS_ROUTE,
      //   navLabel: false,
      //   accessibleModules: [APP_MODULE_ACCESS_LIST.SECURITY],
      // },
      {
        title: "Role Groups",
        icon: "boxs",
        href: SECURITY_ROLE_GROUPS_ROUTE,
        navLabel: false,
        accessibleModules: [APP_MODULE_ACCESS_LIST.SECURITY],
      },
    ]
  },


];
