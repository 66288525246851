import { createSlice } from "@reduxjs/toolkit";
import { defaultPosMenuProductState } from "./pos-menu-product.state";



const posMenuProductSlice = createSlice({
    initialState: defaultPosMenuProductState,
    name: "pos-menu-product",
    reducers: {
        // getPosBankInfoListSync: (state) => {
        //     const data = getPosBankInfoListFormLocalStore()
        //     state.bank_info_list.data = data
        // },

        // upsertPosBankInfoSync: (state, action) => {
        //     if (action.payload.bank_uuid) {
        //         const index = state.bank_info_list.data.findIndex((res) => res.bank_uuid === action.payload.bank_uuid)
        //         state.bank_info_list.data.splice(index, 1, action.payload)
        //         setPosBankInfoListInLocalStore(state.bank_info_list.data)
        //     } else {
        //         state.bank_info_list.data.push({
        //             ...action.payload,
        //             bank_uuid: Date.now().toString(),
        //             opening_date: new Date().toISOString()
        //         })
        //         setPosBankInfoListInLocalStore(state.bank_info_list.data)
        //     }
        // },
        // // deleteReservationsSync: (state, action) => {
        // //     const reservation_uuid = action.payload.reservation_uuid
        // //     const index = state.reservations_list.data.findIndex((res) => res.reservation_uuid === reservation_uuid)
        // //     state.reservations_list.data.splice(index, 1)
        // //     setReservationsInLocalStore(state.reservations_list.data)
        // // },
        // clearPosBankInfoState: (state) => {
        //     return defaultPosMenuCategoryState
        // },
    },
    extraReducers: (builder) => {
        //############################# Multiple Product Categories ######################################
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.pending, (state, action) => {
        //     state.category_list.loading = LoadState.pending
        // })
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.fulfilled, (state, action) => {
        //     state.category_list.loading = LoadState.succeeded
        //     state.category_list.data = action.payload.data
        //     state.category_list.totalRecords = action.payload.recordCount
        //     state.category_list.error = null
        // })
        // builder.addCase(fetchProductCategoriesListWithArgsAsync.rejected, (state, action) => {
        //     state.category_list.error = action.error.message as string
        // })


        //#################################### Single Product Category ##############################################
        // builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.pending
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
        //     state.single_airline_menu.loading = LoadState.succeeded
        //     state.single_airline_menu.data = action.payload
        //     state.single_airline_menu.error = null
        // })
        // builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
        //     state.single_airline_menu.error = action.error.message as string
        // })
    },
});

export const posMenuProductReducer = posMenuProductSlice.reducer;
// export const { } = posMenuCategorySlice.actions;
