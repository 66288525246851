import { IPaymentMode } from "../../../../../redux/common";

type PaymentMode = "CASH" | "CARD" | "ONLINE"
export const isCashPayMode = (payMode: IPaymentMode): boolean => {
    return payMode === "CASH" ? true : false
}

export const isCardPayMode = (payMode: IPaymentMode): boolean => {
    return payMode === "CREDIT_CARD" || payMode === "DEBIT_CARD" ? true : false
}

export const isOnlinePayMode = (payMode: IPaymentMode): boolean => {
    return payMode === "G_PAY/APPLE_PAY" || payMode === "NET_BANKING" ? true : false
}