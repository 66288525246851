import { combineReducers } from "@reduxjs/toolkit";
import OrderReducer from "./Order/OrderSlice";
import { userProfileReducer } from "./settings/user-profile";
import { airlineFlightsReducer } from "./airlineAndFlights/flights";
import { airlinesReducer } from "./airlineAndFlights/airlines";
import { airlineMenuReducer } from "./airlineAndFlights/airline-menu";
import { flightMenuReducer } from "./airlineAndFlights/flight-menu";
import { productReducer, categoriesReducer, inventoryReducer, productCustomizationReducer } from "./inventory";
import { reservationsReducer, tablesLayoutReducer } from "./table-and-reservations";
import { shiftsReducer } from "./shifts";
import { chartsOfAccountReducer, paymentPaidReducer, paymentReceivedReducer, posBankInfoReducer } from "./accounting";
import { posMenuCategoryReducer } from "./pos-menu-and-orders/pos-menu-category/pos-menu-category.reducer";
import { posMenuProductReducer, posOrdersReducer } from "./pos-menu-and-orders";
import { receiptComposerReducer } from "./settings/receipt-composer";
import { customizerReducer } from "./customizer";
import { taxInfoReducer } from "./settings/tax-info";
import { outletsReducer } from "./settings/outlets";
import { companyReducer } from "./settings/company";
import { outletRegisterReducer } from "./settings/outlet-registers";
import { securityReducer } from "./settings/security";
import { couponsReducer } from "./marketing";
import { campaignsReducer} from "./marketing/campaigns";
import { ordersReducer } from "./orders";
export const rootReducer = combineReducers({
  // Add your reducers here
  order: OrderReducer,
  airlinesAndFlights: combineReducers({
    airlines: airlinesReducer,
    flights: airlineFlightsReducer,
    airlineMenu: airlineMenuReducer,
    flightMenu: flightMenuReducer
  }),
  inventory: combineReducers({
    categories: categoriesReducer,
    products: productReducer,
    inventory: inventoryReducer,
    customization: productCustomizationReducer
  }),
  tableAndReservation: combineReducers({
    tables: tablesLayoutReducer,
    reservations: reservationsReducer,
  }),


  pointOfSale: combineReducers({
    shifts: shiftsReducer,
    accounting: combineReducers({
      paymentReceived: paymentReceivedReducer,
      paymentPaid: paymentPaidReducer,
      bankInfo: posBankInfoReducer,
      chartOfAccount: chartsOfAccountReducer
    }),
    menu: combineReducers({
      categories: posMenuCategoryReducer,
      products: posMenuProductReducer,
    }),
    orders: posOrdersReducer
  }),
  customizer: customizerReducer,
  settings: combineReducers({
    receiptComposer: receiptComposerReducer,
    companies: companyReducer,
    outlets: outletsReducer,
    tax: taxInfoReducer,
    userProfile: userProfileReducer,
    registers: outletRegisterReducer,
    security: securityReducer
  }),
  marketing: combineReducers({
    campaigns: campaignsReducer,
    coupons: couponsReducer,
  }),
  orders: ordersReducer
});
