import { LoadState } from "../../constants/enum";
import { IShiftPunchInOut, IShiftPunchInOutState } from "./shifts.types";


export const defaultShiftPunchInOut: IShiftPunchInOut = {
    shift_uuid: null,
    shift_code: "",

    user_name: "",
    user_uuid: "",
    user_image: "",
    punch_in: "",
    punch_out: "",
    break_in: "",
    break_out: "",
    
    punch_date: new Date().toISOString(),

    total_time: ""
}

export const defaultShiftPunchInOutState: IShiftPunchInOutState = {
    shifts_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_punch_in_out: {
        loading: LoadState.idle,
        data: defaultShiftPunchInOut,
        error: null
    }
}