import React, { useEffect, useState } from 'react'
import { ReactFlowShapes } from '../../Components/ReactFlowShapes/ReactFlowShapes';
import { ICustomTableNode } from '../../Components/ReactFlowShapes/ReactFlowShapes.types';
import { useControls } from 'leva';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TABLES_ROUTE } from './Tables.constants';
import { IStoreState, useAppDispatch, useAppSelector } from '../../redux';
import { fetchSingleTableLayoutAsync, upsertSingleTableLayoutAsync } from '../../redux/table-and-reservations';


export const TableMenuLayout: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        data: tableData,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.tableAndReservation.tables.single_tables_layout);

    const [initialNodes, setInitialNodes] = useState<ICustomTableNode[]>([])
 

    useEffect(() => {
        dispatch(fetchSingleTableLayoutAsync(""));
    }, [])


    React.useEffect(() => {
        setInitialNodes(tableData.tables)
    }, [tableData]);


    const handleSaveButtonClick = (data: ICustomTableNode[]) => {
        dispatch(upsertSingleTableLayoutAsync({
            ...tableData,
            tables: data
        })).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                handleCloseLayout()
            }
        });
    }

    const handleCloseLayout = () => {
        navigate(TABLES_ROUTE)
    }

    return (
        <Box sx={{
            height: "80vh"
        }}>
            <ReactFlowShapes

                initialNodes={initialNodes}
                onSaveClick={handleSaveButtonClick}
                onCancel={handleCloseLayout}
            />
        </Box>
    )
}
