import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../api/axios-api"
import { IShiftPunchInOut } from "./shifts.types"
import { IQueryParams, getSearchQuery } from "../../common"
import { uploadSingleFileAsync } from "../../helpers/uploadFilesAsync"



const GET_RECEIPT_COMPOSER_BASE_URL = "/shift/get-shift"
const UPSERT_RECEIPT_COMPOSER_BASE_URL = "/shift/upsert-shift"

export const fetchShiftPunchInOutAsync = createAsyncThunk<{ data: IShiftPunchInOut[], recordCount: number }, IQueryParams>(
    'shifts/fetchShiftPunchInOutAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_RECEIPT_COMPOSER_BASE_URL}${searchQuery}`)
        return response.data
    },
)

interface IUpsertShiftPunchInOut extends IShiftPunchInOut {
    file: File | null,
}

export const upsertSingleShiftPunchInOutAsync = createAsyncThunk<IShiftPunchInOut, IUpsertShiftPunchInOut>(
    'shifts/upsertSingleShiftPunchInOutAsync', async (payload: IUpsertShiftPunchInOut, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "SHIFT",
                asPayload: {
                    shift_uuid: payload.shift_uuid
                }
            })
            restPayload.user_image = path
        }

        const response = await axios_api.post(UPSERT_RECEIPT_COMPOSER_BASE_URL, restPayload);
        thunkAPI.dispatch(fetchShiftPunchInOutAsync({
            page: 1,
            rowsPerPage: 10
        }))
        return response.data
    },
)