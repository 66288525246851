import { IShiftPunchInOut } from "./shifts.types"



export const setShiftsInLocalStore = (data: IShiftPunchInOut[]) => {
    localStorage.setItem("shifts", JSON.stringify(data))
}

export const getShiftsFormLocalStore = (): IShiftPunchInOut[] => {
    const data = localStorage.getItem("shifts")
    if (data) {
        return JSON.parse(data)
    }
    return []
}