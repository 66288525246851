import React from "react";
import { Avatar, Box, Button, MenuItem, Typography } from "@mui/material";
import { IDataTableProps, MobileLogoRenderType, RenderType } from "../../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../../Components/Table/DataTable";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { useDateFilter } from "../../../../Components/Table/hooks/useDateFilter";
import { IAirline } from "../../../../redux/airlineAndFlights/airlines";
import { useNavigate, useParams } from "react-router-dom";
import { CSVExportPlugin, RefreshPlugin, SearchPlugin } from "../../../../Components/Table/plugins";
import moment from "moment";
import { DateFilterDropdown } from "../../../../Components/Table/components/DateFilterDropdown/DateFilterDropdown";
import { CustomSelect } from "../../../../Components/formComponents";
import { CustomDatePicker } from "../../../../Components/formComponents/CustomDatePicker";
import PageContainer from "../../../../Components/PageContainer/PageContainer";
import { Breadcrumb } from "../../../../layout/breadcrumb/Breadcrumb";
import { IBreadcrumbProps } from "../../../../layout/breadcrumb/IBreadcrumbProps";
import { formatDateWithTime } from "../../../../helpers";
import { StandardTableActions } from "../../../../Components/StandardTableActions/StandardTableActions";
import { AIRLINE_MENU_ROUTES, AIRLINE_ROUTES } from "../../AirlineAndFlights.constants";
import { IAirlineMenu, fetchSingleAirlineMenuListAsync, fetchSingleAirlineMenuListWithArgsAsync } from "../../../../redux/airlineAndFlights/airline-menu";
import { DASHBOARD_ROUTES } from "../../../Dashboard/Dashboard.constants";

const BCrumb: IBreadcrumbProps["items"] = [
    {
        to: DASHBOARD_ROUTES,
        title: "dashboard",
    },
    {
        to: AIRLINE_ROUTES,
        title: "airlines",
    },
    {
        title: "airline Menu",
    },
];

interface IAirlineMenuTableProps {
    airline_name?: string;
}
export const AirlineMenuTable: React.FC<IAirlineMenuTableProps> = ({
    airline_name,
}) => {
    const { uuid: airline_uuid } = useParams() as {
        uuid?: string;
    };
    const {
        data: airlinesMenuList,
        totalRecords,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.airlinesAndFlights.airlineMenu.airline_menu_list);

    const [status, setStatus] = React.useState<IAirline["status"] | "-1">("-1");
    const [searchColumns, setSearchColumns] = React.useState<string[]>(["menu_item_name"]);
    const [search, setSearch] = React.useState<string>("");
    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });
    const { date, type, handleDateSelectChange, dateDropdownList, setDate } =
        useDateFilter({ defaultType: "last28Days" });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();


    const customTableProps: IDataTableProps = {
        isPagination: true,
        rowsPerPageOptions: 25,
        isDataLoading: false,
        tableCommandBarProps: {
            leftItems: {
                customPlugins: [
                    // {
                    //     key: "status",
                    //     onRender: () => (
                    //         <CustomSelect
                    //             sx={{ minWidth: "120px" }}
                    //             value={status}
                    //             size="small"
                    //             onChange={(e: any) => setStatus(e.target.value as "ACTIVE")}
                    //         >
                    //             <MenuItem value="-1">All Status</MenuItem>
                    //             <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                    //             <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                    //         </CustomSelect>
                    //     ),
                    // },
                    // {
                    //     key: "byDate",
                    //     onRender: () => (
                    //         <DateFilterDropdown
                    //             value={type}
                    //             options={dateDropdownList}
                    //             onChange={handleDateSelectChange}
                    //         />
                    //     ),
                    // },
                    // {
                    //     key: "fromDate",
                    //     onRender: () =>
                    //         type === "custom" ? (
                    //             <CustomDatePicker
                    //                 sx={{ minWidth: "150px" }}
                    //                 value={date.fromDate}
                    //                 onChange={(newValue) => {
                    //                     const newDate = moment(newValue).format("YYYY-MM-DD");
                    //                     setDate({ ...date, fromDate: newDate });
                    //                 }}
                    //             />
                    //         ) : (
                    //             <></>
                    //         ),
                    // },
                    // {
                    //     key: "toDate",
                    //     onRender: () =>
                    //         type === "custom" ? (
                    //             <CustomDatePicker
                    //                 sx={{ minWidth: "150px" }}
                    //                 value={date.toDate}
                    //                 onChange={(newValue) => {
                    //                     const newDate = moment(newValue).format("YYYY-MM-DD");
                    //                     setDate({ ...date, toDate: newDate });
                    //                 }}
                    //             />
                    //         ) : (
                    //             <></>
                    //         ),
                    // },
                    {
                        key: "csvExport",
                        onRender: (columns, items) => (
                            <CSVExportPlugin
                                columns={columns}
                                items={items}
                                filePrefixName="Report"
                            />
                        ),
                    },
                    {
                        key: "refresh",
                        onRender: () => <RefreshPlugin onClick={fetchList} />,
                    },
                ],
            },
            rightItems: {
                plugins: {},
                customPlugins: [
                    {
                        key: "search",
                        onRender: () => {
                            return (
                                <SearchPlugin
                                    selectedDropdownValue={searchColumns}
                                    dropdownOptions={[
                                        { label: "Item Name", value: "menu_item_name" },
                                    ]}
                                    onDropdownChange={(value) => setSearchColumns(value)}
                                    onChange={(newValue) => {
                                        setSearch(newValue);
                                    }}
                                />
                            );
                        },
                    },
                ],
            },
        },
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },
        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IAirlineMenu) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => navigate(`${AIRLINE_MENU_ROUTES}/manage/${row.menu_item_uuid}`)}
                        />
                    );
                },
            },
            // {
            //     key: "airline_name",
            //     headerName: "Airline Name",
            //     fieldName: "airline_name",
            //     exportCellWidth: 25,
            //     enableSorting: true,
            //     renderType: RenderType.CUSTOM_RENDER,
            // },
            {
                key: "menu_item_name",
                headerName: "Item Name",
                fieldName: "menu_item_name",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },

            {
                key: "menu_item_size",
                headerName: "Item Size",
                fieldName: "menu_item_size",
                exportCellWidth: 25,
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "menu_item_price",
                headerName: "Item Price",
                fieldName: "menu_item_price",
                exportCellWidth: 25,
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IAirlineMenu) => {
                    return (
                        <Typography variant="body1">{`₹${row.menu_item_price}`}</Typography>
                    );
                },
            },
            // {
            //     key: "status",
            //     headerName: "Status",
            //     fieldName: "status",
            //     exportCellWidth: 25,
            //     enableSorting: true,
            //     renderType: RenderType.CUSTOM_RENDER,
            // },
            // {
            //     key: "insert_ts",
            //     headerName: "Date",
            //     fieldName: "insert_ts",
            //     enableSorting: true,
            //     renderType: RenderType.DATE_DARK_COLOR,
            //     exportCellWidth: 25,
            //     onExportRender: (values) => {
            //         return formatDateWithTime(values.value);
            //     },
            // },
        ],

        items: airlinesMenuList,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };
    //   return <DataTable columns={columns} />;
    React.useEffect(() => {
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, date, searchColumns, status, search, date]);


    const fetchList = () => {
        dispatch(
            fetchSingleAirlineMenuListWithArgsAsync({
                status: status,
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
                columns: searchColumns,
                value: search,
                fromDate: date.fromDate,
                toDate: date.toDate,
                airline_uuid: airline_uuid
            }),
        );
    };

    return (
        <PageContainer title="View Airline Menus" description="this is inner page">
            <Breadcrumb title="View Airline Menus" items={BCrumb} />
            <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                mb={2}
            >
                <Typography variant="h2" fontWeight={"700"}>
                    {`${airline_name} Airline Menu`}
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate(`${AIRLINE_MENU_ROUTES}/manage?airline_uuid=${airline_uuid}&airline_name=${airline_name}`)}
                >
                    Add Menu Item
                </Button>
            </Box>

            <DataTable {...customTableProps} />
        </PageContainer>
    )
};
