import { ICustomTableNode } from "../../Components/ReactFlowShapes/ReactFlowShapes.types";
import { POS_ROUTE } from "../../constants/constants";


export const TABLES_ROUTE = POS_ROUTE + "/tables"
export const TABLES_LAYOUT_ROUTE = POS_ROUTE + "/tables/layout"


export const defaultTablesList: ICustomTableNode[] = [
    {
        "id": "1716897843412",
        "type": "shape",
        "position": {
            "x": 330,
            "y": 100
        },
        "style": {
            "width": 100,
            "height": 100
        },
        "data": {
            "type": "rectangle",
            "color": "#3F8AE2",
            "title": "Table One",
            "availability": "FREE",
            "mode": "editable",
            "nodeType": "TABLE",
            "tableNumber": 1,
        },
        "selected": false,
        "width": 100,
        "height": 100,

    },
    {
        "id": "1716897985715",
        "type": "shape",
        "position": {
            "x": 220,
            "y": 230
        },
        "style": {
            "width": 100,
            "height": 100
        },
        "data": {
            "type": "rectangle",
            "color": "#3F8AE2",
            "title": "Table Two",
            "availability": "RESERVED",
            "mode": "editable",
            "nodeType": "TABLE",
            "tableNumber": 2,
        },
        "selected": false,
        "width": 100,
        "height": 100,

    },
    {
        "id": "1716897987995",
        "type": "shape",
        "position": {
            "x": 330,
            "y": -20
        },
        "style": {
            "width": 100,
            "height": 100
        },
        "data": {
            "type": "circle",
            "color": "#3F8AE2",
            "title": "Table Three",
            "availability": "CHECKED_IN",
            "mode": "editable",
            "nodeType": "TABLE",
            "tableNumber": 3,
        },
        "selected": false,
        "width": 100,
        "height": 100,
        "positionAbsolute": {
            "x": 330,
            "y": -20
        },
        "dragging": false,

    }
]