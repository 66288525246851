import { ICustomSelectProps } from "../../../../Components/formComponents";

export const coa_detail_type_list: ICustomSelectProps["options"] = [
    { label: "Type One", value: "TYPE_ONE" },
    { label: "Type Two", value: "TYPE_TWO" },
]

export const coa_account_type_list: ICustomSelectProps["options"] = [
    { label: "Type One", value: "TYPE_ONE" },
    { label: "Type Two", value: "TYPE_TWO" },
]

export const coa_currency_type_list: ICustomSelectProps["options"] = [
    { label: "Type One", value: "TYPE_ONE" },
    { label: "Type Two", value: "TYPE_TWO" },
]