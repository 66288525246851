import { AirlinesTable } from "./Airlines/AirlinesTable";
import { AirlineFlightsTable } from "./Flights/AirlineFlightsTable";
import { AIRLINE_FLIGHTS_ROUTES, AIRLINE_MENU_ROUTES, AIRLINE_ROUTES, FLIGHT_MENU_ROUTES } from "./AirlineAndFlights.constants";
import { ManageSingleAirline } from "./Airlines/ManageSingleAirline";
import { AirlineMenuTable } from "./Airlines/AirlineMenu/AirlineMenuTable";
import { ManageAirlineSingleMenu } from "./Airlines/AirlineMenu/ManageAirlineSingleMenu";
import { FlightMenuTable } from "./Flights/FlightMenu/FlightMenuTable";
import { ManageSingleFlightMenu } from "./Flights/FlightMenu/ManageSingleFlightMenu";

export const airlineAndFlightsRoutes = [
    {
        path: AIRLINE_ROUTES,
        exact: true,
        element: <AirlinesTable />
    },
    {
        path: `${AIRLINE_ROUTES}/manage/:uuid`,
        exact: true,
        element: <ManageSingleAirline />
    },
    // ##################### Airline Menu Route ############################
    {
        path: `${AIRLINE_MENU_ROUTES}`,
        exact: true,
        element: <AirlineMenuTable />
    },
    {
        path: `${AIRLINE_MENU_ROUTES}/manage`,
        exact: true,
        element: <ManageAirlineSingleMenu />
    },
    {
        path: `${AIRLINE_MENU_ROUTES}/manage/:uuid`,
        exact: true,
        element: <ManageAirlineSingleMenu />
    },



    // ##################### Flights Route ############################
    {
        path: AIRLINE_FLIGHTS_ROUTES,
        exact: true,
        element: <AirlineFlightsTable />
    },
    // ##################### Flights Menu Route ############################
    {
        path: FLIGHT_MENU_ROUTES,
        exact: true,
        element: <FlightMenuTable />
    },
    {
        path: `${FLIGHT_MENU_ROUTES}/manage`,
        exact: true,
        element: <ManageSingleFlightMenu />
    },
    {
        path: `${FLIGHT_MENU_ROUTES}/manage/:uuid`,
        exact: true,
        element: <ManageSingleFlightMenu />
    },
] 