// import {
//   THEME_COLOR,
//   NAVBAR_BG,
//   SIDEBAR_BG,
//   DIRECTION,
//   DARK_THEME,
// } from "../constants";
// import { action } from "typesafe-actions";

// export const setTheme = (payload: any) => action(THEME_COLOR, payload);

// export const setDarkMode = (payload: any) => action(DARK_THEME, payload);
// export const setNavbarBg = (payload: any) => action(NAVBAR_BG, payload);

// export const setSidebarBg = (payload: any) => action(SIDEBAR_BG, payload);

// export const setDir = (payload: any) => action(DIRECTION, payload);
export const typeada = " "