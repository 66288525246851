import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultAirlineMenuState } from "./airline-menu.state";
import { fetchAirlineSingleMenuAsync, fetchSingleAirlineMenuListAsync, fetchSingleAirlineMenuListWithArgsAsync } from "./airline-menu.actions";

const airlineMenuSlice = createSlice({
    initialState: defaultAirlineMenuState,
    name: "menu-items",
    reducers: {
        clearAirlineMenuState: (state) => {
            return defaultAirlineMenuState
        },
        clearAirlineMenu: (state) => {
            state.single_airline_menu.data = defaultAirlineMenuState.single_airline_menu.data
            state.single_airline_menu.loading = defaultAirlineMenuState.single_airline_menu.loading
            state.single_airline_menu.error = defaultAirlineMenuState.single_airline_menu.error
        }
    },
    extraReducers: (builder) => {
        //############################# Multiple Menu for particular airline ######################################
        builder.addCase(fetchSingleAirlineMenuListAsync.pending, (state, action) => {
            state.airline_menu_list.loading = LoadState.pending
        })
        builder.addCase(fetchSingleAirlineMenuListAsync.fulfilled, (state, action) => {
            state.airline_menu_list.loading = LoadState.succeeded
            state.airline_menu_list.data = action.payload.data
            state.airline_menu_list.totalRecords = action.payload.recordCount
            state.airline_menu_list.error = null
        })
        builder.addCase(fetchSingleAirlineMenuListAsync.rejected, (state, action) => {
            state.airline_menu_list.error = action.error.message as string
        })

        //############################# Multiple Menu Items ######################################
        builder.addCase(fetchSingleAirlineMenuListWithArgsAsync.pending, (state, action) => {
            state.airline_menu_list.loading = LoadState.pending
        })
        builder.addCase(fetchSingleAirlineMenuListWithArgsAsync.fulfilled, (state, action) => {
            state.airline_menu_list.loading = LoadState.succeeded
            state.airline_menu_list.data = action.payload.data
            state.airline_menu_list.totalRecords = action.payload.recordCount
            state.airline_menu_list.error = null
        })
        builder.addCase(fetchSingleAirlineMenuListWithArgsAsync.rejected, (state, action) => {
            state.airline_menu_list.error = action.error.message as string
        })


        //#################################### Single Menu Utem ##############################################
        builder.addCase(fetchAirlineSingleMenuAsync.pending, (state, action) => {
            state.single_airline_menu.loading = LoadState.pending
        })
        builder.addCase(fetchAirlineSingleMenuAsync.fulfilled, (state, action) => {
            state.single_airline_menu.loading = LoadState.succeeded
            state.single_airline_menu.data = action.payload
            state.single_airline_menu.error = null
        })
        builder.addCase(fetchAirlineSingleMenuAsync.rejected, (state, action) => {
            state.single_airline_menu.error = action.error.message as string
        })
    },
});

export const airlineMenuReducer = airlineMenuSlice.reducer;
export const { clearAirlineMenu, clearAirlineMenuState } = airlineMenuSlice.actions;
