import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import { IAirline, defaultAirline, upsertSingleAirlineAsync } from "../../../../redux/airlineAndFlights/airlines";
import { useAppDispatch } from "../../../../redux";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { ControlledCustomSelect, CustomFormLabel, CustomTextField, OutlinedTextField } from "../../../../Components/formComponents";
import { Stack } from "@mui/material";

export interface IManageAirlineDialogProps {
    airline?: IAirline;
    open: boolean;
    onClose: () => void;
}
export const ManageAirlineDialog: React.FC<IManageAirlineDialogProps> = (props) => {
    const { open, airline, onClose } = props;

    const [saveLoading, setSaveLoading] = React.useState(false);

    const dispatch = useAppDispatch();
    const {
        values,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: { ...defaultAirline },
        validate: (values) => {
            const errors: any = {};
            if (!values.airline_name) {
                errors.airline_name = "*This field is required.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);
            dispatch(
                upsertSingleAirlineAsync(values)
            ).finally(() => {
                setSaveLoading(false);
                onClose()
            });
        },
    });

    useEffect(() => {
        if (!airline) return
        setValues(airline)
    }, [airline])

    return (
        <Dialog
            open={open}
            title={`Add/Edit Airline`}
            onClose={onClose}
            size="sm"
            contentWrappedWithForm={{ onSubmit: handleSubmit }}
            actions={[
                { type: "button", label: "Close", variant: "text", onClick: onClose },
                {
                    type: "submit",
                    label: "Save",
                    variant: "contained",
                    disabled: saveLoading,
                },
            ]}
        >
            <Stack direction="column" gap={2}>
                <OutlinedTextField
                    label="Airline Name"
                    id="airline_name"
                    value={values.airline_name}
                    onChange={handleChange}
                    error={errors.airline_name}
                />
                {values.airline_uuid && (
                    <ControlledCustomSelect
                        label="Status"
                        name="status"
                        fullWidth
                        value={values.status}
                        onChange={handleChange}
                        options={[
                            { label: "Active", value: "ACTIVE" },
                            { label: "Inactive", value: "INACTIVE" },
                        ]}
                        displayEmpty
                        placeholder="Select Status"
                        error={errors.status ? true : false}
                        helperText={errors.status}
                    />
                )}
            </Stack>
        </Dialog >
    );
};
