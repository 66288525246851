import { LoadState } from "../../../constants/enum";
import { IAirlineMenu, IAirlineMenuState } from "./airline-menu.types";



export const defaultAirlineMenu: IAirlineMenu = {
    menu_item_uuid: null,
    airline_uuid: "",
    airline_name: "",
    menu_item_name: "",
    menu_item_price: 0,
    menu_item_size: "Small",
    created_by_id: "",
    status: "ACTIVE",
}

export const defaultAirlineMenuState: IAirlineMenuState = {
    airline_menu_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_airline_menu: {
        loading: LoadState.idle,
        data: defaultAirlineMenu,
        error: null,
    }
}