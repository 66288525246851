import React, { useState } from "react";
import { StandardCard } from "../../../../../Components/Card";
import { CustomSquareButton } from "../../../../../Components/CustomButton";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomDivider } from "../../../../../Components/CustomDivider/CustomDivider";
import { POS_ORDERS_ROUTE, POS_ORDER_MENU_ROUTE } from "../../MenuAndOrders.constants";
import { RUPEE_SYMBOL } from "../../../../../constants/constants";
import Edit from "@mui/icons-material/Edit";
import { Print } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import { OrderPrintDialog } from "../OrderPrintDialog";
import { IOrder } from "../../../../../redux/orders";
import { useNavigate } from "react-router-dom";

interface IOrderTableCardProps {
  order: IOrder;
  orderType: string
}
export const POSOrderTableCard: React.FC<IOrderTableCardProps> = ({
  order,
  orderType
}) => {
  let navigate = useNavigate()
  const [openPrintDialog, setOpenPrintDialog] = useState(false)

  const handleViewOrderClick = () => {
    navigate(`${POS_ORDERS_ROUTE}/view/${order.order_uuid}`)
  }

  const handleEditOrderClick = () => {
    navigate(`${POS_ORDER_MENU_ROUTE}/edit/${order.order_uuid}`)
  }

  const handleCheckOutClick = () => {
    navigate(`${POS_ORDER_MENU_ROUTE}/edit/${order.order_uuid}`)
  }

  const handlePrintOrderClick = () => {
    setOpenPrintDialog(true)
  }


  if (orderType === "TAKE_AWAY") {
    return <StandardCard
      heading={order.order_type === "TAKE_AWAY" ? "Take-Away" : "Dine-In"}
      rightHeading={<Typography variant="h3" fontWeight={"bold"}>{`Order No. ${order.order_number}`}</Typography>}
      sx={{
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
      }}
    >
    </StandardCard>
  }



  if (orderType === "KITCHEN") {
    return <StandardCard
      heading={order.order_type === "TAKE_AWAY" ? "Take-Away" : "Dine-In"}
      rightHeading={`Order No. ${order.order_number}`}
      sx={{
        // mt: 2,
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
        // width: "32%"
      }}
    >
      <CustomDivider borderColor="white" />

      <Table>
        <TableHead>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>QT</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>Items</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
          </TableCell>
        </TableHead>
        <TableBody sx={{ minHeight: 300 }}>
          {(order.menu_items || []).map((order_item, Item_idx) => {
            return (
              <TableRow>
                <TableCell
                  sx={{
                    border: "none",
                    p: 1,
                  }}
                >
                  <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    p: 1,
                  }}
                >
                  <Typography fontWeight={"bold"}>{order_item.product_name}</Typography>
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    p: 1,
                  }}
                >
                  <Typography fontWeight={"bold"}></Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>

      <CustomDivider borderColor="grey" />


      <Stack gap={1} direction={"row"}>
        <CustomSquareButton title="Ready To Serve" />
      </Stack>
    </StandardCard>
  }


  return (
    <StandardCard
      heading={order.order_type === "TAKE_AWAY" ? "Take-Away" : "Dine-In"}
      rightHeading={`Order No. ${order.order_number}`}
      sx={{
        // mt: 2,
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
        // width: "32%"
      }}
    >
      <CustomDivider borderColor="white" />

      <Table>
        <TableHead>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>QT</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>Items</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>Price</Typography>
          </TableCell>
        </TableHead>
        <TableBody sx={{ minHeight: 300 }}>
          {(order.menu_items || []).map((order_item, Item_idx) => {
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 1,
                    }}
                  >
                    <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 1,
                    }}
                  >
                    <Typography fontWeight={"bold"}>{order_item.product_name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 1,
                    }}
                  >
                    <Typography fontWeight={"bold"}>{`${RUPEE_SYMBOL}${order_item.product_price}`}</Typography>
                  </TableCell>
                </TableRow>
                {(order_item.options || []).map((option, option_idx) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 1,
                          }}
                        >
                          {/* <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography> */}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 1,
                          }}
                        >
                          <Typography >{`${option.modifier_name} [add-onn]`}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 1,
                          }}
                        >
                          <Typography  fontWeight={"bold"}  >{`${RUPEE_SYMBOL}${option.modifier_price}`}</Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </>
            )
          })}
          <TableRow sx={{mt: 1}}>
            <TableCell
              sx={{
                border: "none",
                p: 1,
                borderTop: "2px solid grey"
              }}
            >
              <Typography fontWeight={"bold"}>Subtotal</Typography>
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                p: 1,
                borderTop: "2px solid grey"
              }}
            >
              {/* <Typography fontWeight={"bold"}>{order_item.product_name}</Typography> */}
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                p: 1,
                borderTop: "2px solid grey"
              }}
            >
              <Typography fontWeight={"bold"}>{`${RUPEE_SYMBOL}${order.sub_total}`}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <CustomDivider borderColor="grey" />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={4}
      >
        <Typography fontWeight={"bold"}>Subtotal</Typography>
        <Typography fontWeight={"bold"}>{`${RUPEE_SYMBOL}${order.sub_total}`}</Typography>
      </Stack> */}

      <Stack gap={1} direction={"row"}>
        {/* <CustomSquareButton icon={<RiDeleteBin7Line fontSize={"1.5rem"} />} />
        <CustomSquareButton icon={<FiEdit3 fontSize={"1.5rem"} />} /> */}
        <CustomSquareButton icon={<Edit />} onClick={handleEditOrderClick} />
        <CustomSquareButton icon={<Visibility />} onClick={handleViewOrderClick} />
        <CustomSquareButton icon={<Print />} onClick={handlePrintOrderClick} />
        <CustomSquareButton title="Checkout" onClick={handleCheckOutClick} />
      </Stack>

      {openPrintDialog && <OrderPrintDialog
        open={openPrintDialog}
        orderUUID={order.order_uuid || ""}
        onClose={() => setOpenPrintDialog(false)} />}
    </StandardCard>
  );
};
