import React from 'react'
import { RightPanel } from '../../../../Components/RightPanel';
import { Grid, Button } from '@mui/material';
import { CustomFormLabel } from '../../../../Components/formComponents';

interface IOrderPrintDialogProps {
    open: boolean,
    onClose: () => void,
    orderUUID: string
}
export const OrderPrintDialog: React.FC<IOrderPrintDialogProps> = ({
    open,
    onClose,
    orderUUID
}) => {


    const printCurrentOrderDetails = () => {
        window.print()
    }

    return (
        <RightPanel
            open={open}
            heading="Print"
            onClose={onClose}
            isWrappedWithForm
            onFormSubmit={printCurrentOrderDetails}
            actionButtons={() => {
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <Button
                                variant="contained"
                                type="submit"
                                // disabled={loading}
                                fullWidth
                            >
                                Print
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                );
            }}
        >
            <CustomFormLabel>Append Printable Data here</CustomFormLabel>
        </RightPanel>
    )
}
