import React from "react";
import ReservationCalendar from "./ReservationCalendar/ReservationCalendar";
import { StandardCard } from "../../Components/Card";
import { CustomButton } from "../../Components/CustomButton";
import { Grid } from "@mui/material";
import { TableManagementCard } from "./components/TableManagementCard/TableManagementCard";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { UpsertReservationDialog } from "./dialogs/UpsertReservationDialog";

export const Reservations = () => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const handleReservationDialog = () => {
    setOpenDialog(true);
  };

  return (
    <PageContainer title="Reservations" description="reservation page">
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <StandardCard
            heading="Reservations"
            rightHeading={
              <CustomButton
                variant="contained"
                onClick={handleReservationDialog}
              >
                Add Reservation
              </CustomButton>
            }
          >
            <ReservationCalendar />
          </StandardCard>
        </Grid>
        <Grid item xs={3}>
          <TableManagementCard/>
        </Grid>
      </Grid>
      {openDialog && (
        <UpsertReservationDialog
          open={true}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </PageContainer>
  );
};
