import { LoadState } from "../../../constants/enum";
import { IReservation, IReservationState } from "./reservations.types";


export const defaultReservation: IReservation = {
    reservation_uuid: null,
    user_name: "user one",
    user_email: "user@gmail.com",
    user_phone_no: "1234567890",

    reservation_date: new Date().toISOString(),
    reservation_from_time: "",
    reservation_to_time: "",

    number_of_people: 5,
    table_no: "",

    outlet_uuid: null,
    status: "ACTIVE"

}

export const defaultReservationState: IReservationState = {
    reservations_list: {
        loading: LoadState.idle,
        data: [],
        totalRecords: 0,
        error: null,
    },
    single_reservation: {
        loading: LoadState.idle,
        data: defaultReservation,
        error: null,
    }
}