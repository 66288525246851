import React from 'react'
import { StandardCard } from '../../Components/Card'
import { IStoreState, useAppDispatch, useAppSelector } from '../../redux';
import { clearFlightMenuState, fetchSingleFlightMenuAsync } from '../../redux/airlineAndFlights';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDivider } from '../../Components/CustomDivider/CustomDivider';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CustomSquareButton } from '../../Components/CustomButton';
import { ORDERS_ROUTE } from './Orders.constants';

export const ViewSingleOrder = () => {
    const { uuid: order_uuid } = useParams() as {
        uuid?: string | null;
    };
    const {
        data: flight_menu_info,
    } = useAppSelector((storeState: IStoreState) => storeState.airlinesAndFlights.flightMenu.single_flight_menu);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!order_uuid) return
        dispatch(fetchSingleFlightMenuAsync(order_uuid));

    }, [order_uuid]);

    React.useEffect(() => {
        return () => {
            dispatch(clearFlightMenuState());
        };
    }, []);

    return (
        <StandardCard
            heading={flight_menu_info.airline_name || ""}
            rightHeading={`Flight No. ${flight_menu_info.flight_number}`}
            sx={{
                // mt: 2,
                display: "block",
                pageBreakInside: "avoid",
                breakInside: "avoid",
                // width: "32%"
            }}
        >
            <CustomDivider borderColor="white" />

            <Table>
                <TableHead>
                    <TableCell
                        sx={{
                            border: "none",
                            p: 1,
                        }}
                    >
                        <Typography fontWeight={"bold"}>QT</Typography>
                    </TableCell>
                    <TableCell
                        sx={{
                            border: "none",
                            p: 1,
                        }}
                    >
                        <Typography fontWeight={"bold"}>Items</Typography>
                    </TableCell>
                    <TableCell
                        sx={{
                            border: "none",
                            p: 1,
                        }}
                    >
                        <Typography fontWeight={"bold"}>Price</Typography>
                    </TableCell>
                </TableHead>
                <TableBody>
                    {flight_menu_info.item_list.map((order_item, Item_idx) => {
                        return (
                            <TableRow>
                                <TableCell
                                    sx={{
                                        border: "none",
                                        p: 1,
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>{order_item.item_quantity}</Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "none",
                                        p: 1,
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>{order_item.item_name}</Typography>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        border: "none",
                                        p: 1,
                                    }}
                                >
                                    <Typography fontWeight={"bold"}>{`₹${order_item.item_price}`}</Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}

                    <TableRow sx={{ borderTop: "2px solid gray" }}>
                        <TableCell
                            sx={{
                                border: "none",
                                p: 1,
                            }}
                        >
                            <Typography fontWeight={"bold"}>Subtotal</Typography>
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "none",
                                p: 1,
                            }}
                        >
                        </TableCell>
                        <TableCell
                            sx={{
                                border: "none",
                                p: 1,
                            }}
                        >
                            <Typography fontWeight={"bold"}>{`₹${flight_menu_info.sub_total}`}</Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Stack gap={1} mt={2} direction={"row"}>
                <CustomSquareButton title="Print" />
                <CustomSquareButton title="Done" />
                <CustomSquareButton title="Close" onClick={() => navigate(ORDERS_ROUTE)} />
            </Stack>
        </StandardCard>
    )
}
