import React from "react";
import {
  Grid,
  Typography,
  Box,
  Breadcrumbs,
  Link,
  Theme,
  Chip,
  emphasize,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IBreadcrumbProps } from "./IBreadcrumbProps";
import { ArrowBack } from "@mui/icons-material";
import styled from "@emotion/styled";

export const Breadcrumb: React.FC<IBreadcrumbProps> = ({
  subtitle,
  items,
  title,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <Box display={"flex"} sx={{ mb: 2 }}>
      <Box
        sx={(theme: Theme) => ({
          pl: 1,
          pr: 1,
          mr: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <ArrowBack
          color="action"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
      </Box>
      <Box>
        <Typography color="textSecondary" fontWeight="400" variant="h4">
          {subtitle}
        </Typography>

        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {items
            ? items.map((item) => (
                <div key={item.title}>
                  {item.to ? (
                    <StyledBreadcrumb
                      component="a"
                      href={item.to}
                      label={item.title}
                    />
                  ) : (
                    <Typography
                      color="textPrimary"
                      textTransform={"capitalize"}
                    >
                      {item.title}
                    </Typography>
                  )}
                </div>
              ))
            : ""}
        </Breadcrumbs>
        {/* <Typography fontWeight="700" variant="h3">
        {title}
      </Typography> */}
      </Box>
    </Box>
  );
};

const StyledBreadcrumb = styled(Chip)(({ theme }: any) => {
  return {
    // backgroundColor: theme.palette.primary.main,
    height: theme.spacing(3),
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    // textTransform: "capitalize",
    cursor: 'pointer'
  };
}) as typeof Chip;
