import { Navigate } from "react-router-dom";
import { FullLayout } from "../layout/FullLayout/FullLayout";
import { Dashboard } from "../views/Dashboard/Dashboard";
import { Menu } from "../views/Menu/Menu";
import { Shifts } from "../views/pos/Shifts/Shifts";
import { Reservations } from "../views/Reservations/Reservations";
import { Tables } from "../views/Tables/Tables";
import { authenticationRoutes } from "../views/Authentication/Authentication.router";
import { ProtectedRoute } from "./ProtectedRoutes";
import { userProfileRoutes } from "../views/Settings/UserProfile/UserProfile.router";
import { airlineAndFlightsRoutes } from "../views/AirlineAndFlights/AirlineAndFlights.router";
import { orderRoutes } from "../views/Orders/Orders.router";
import { DASHBOARD_ROUTES } from "../views/Dashboard/Dashboard.constants";
import { inventoryRoutes } from "../views/Inventory/Inventory.router";
import { dashboardRoutes } from "../views/Dashboard/Dashboard.router";
import { AIR_KITCHEN_ROUTE } from "../constants/constants";
import { tablesRoutes } from "../views/Tables/Tables.router";
import { accountingRoutes } from "../views/pos/Accounting/Accounting.router";
import { posOrderRoutes } from "../views/pos/MenuAndOrders/MenuAndOrders.router";
import { receiptComposerRoutes } from "../views/Settings/ReceiptComposer/ReceiptComposer.router";
import { shiftAndPunchInOutRoutes } from "../views/ShiftAndPunchInOut/ShiftAndPunchInOut.router";
import { settingRoutes } from "../views/Settings";
import { marketingsRoutes } from "../views/Marketing";

export const Router = [
  {
    path: "/",
    exact: true,
    element: <ProtectedRoute><FullLayout /></ProtectedRoute>,
    children: dashboardRoutes
      .concat(userProfileRoutes)
      .concat(airlineAndFlightsRoutes)
      .concat(orderRoutes)
  },
  {
    path: "/pos",
    exact: true,
    element: <ProtectedRoute><FullLayout /></ProtectedRoute>,
    children: [
      {
        path: "/pos/menu",
        exact: true,
        element: <Menu />,
      },
      // {
      //   path: "/pos/menu/table/:tableNo",
      //   exact: true,
      //   element: <Menu />,
      // },
      {
        path: "/pos/reservations",
        exact: true,
        element: <Reservations />,
      },
      // {
      //   path: "/pos/shifts",
      //   exact: true,
      //   element: <Shifts />,
      // },
    ].concat(inventoryRoutes)
      .concat(tablesRoutes)
      .concat(shiftAndPunchInOutRoutes)
      .concat(accountingRoutes)
      .concat(posOrderRoutes)
      .concat(settingRoutes)
      .concat(marketingsRoutes)
  },

  // --------------------------------AUTH ROUTING----------------------------------------------
  ...authenticationRoutes
]
