import { createTheme } from "@mui/material";
import { typography } from "./Typography";


export const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#CFDEDB",
      light: "#C8CAF1",
      dark: "#c4c7c4",
      contrastText: "#000000",
    },
    secondary: {
      main: "#B800CC", //dark purple
      light: "#D0D0D0",
      dark: "#292C2D", //order card black bg color
      contrastText: "#FFFFFF",
    },
    // tertiary: {
    //     main: "#595959",
    //     light: "#f2f2f2",
    //     dark: "#2c2d2c",
    // },
    success: {
      main: "#00CC52", //dark green
      light: "#C1E9DE", //light green
      dark: "#98E59A", //green
      contrastText: "#000000",
      //   contrastText: "#ffffff",
    },
    info: {
      main: "#FBC1D9",
      light: "#595959",
      contrastText: "#000000",
    },
    error: {
      main: "#FF0000",
      light: "#fdf3f5",
      dark: "#e45a68",
    },
    warning: {
      main: "#fec90f",
      light: "#fff4e5",
      dark: "#dcb014",
      contrastText: "#ffffff",
    },
    text: {
      secondary: "#777e89",
    },
    grey: {
      A100: "#ecf0f2",
      A200: "#99abb4",
      A400: "#767e89",
      A700: "#e6f4ff",
    },
    action: {
      disabledBackground: "rgba(73,82,88,0.12)",
      hoverOpacity: 0.02,
      hover: "rgba(0, 0, 0, 0.03)",
    },
    mode: "dark",
  },
  typography: typography,
  shape: {
    borderRadius: 5,
  },
});
