import { Box, Paper, TextField, Typography } from "@mui/material";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { HiMinusSm } from "react-icons/hi";
import { useAppSelector } from "../../../../../redux";
import { IAirlineMenu } from "../../../../../redux/airlineAndFlights";
import { OutlinedTextField } from "../../../../../Components/formComponents";
import { useEffect, useState } from "react";

interface ISingleMenuCardProps {
    menuItem: IAirlineMenu;
    quantity: number
    onAddItem: () => void;
    onRemoveItem: () => void;
    onQuantityChange: (qnt: number) => void
}
export const AirlineMenuCard: React.FC<ISingleMenuCardProps> = (props) => {
    const { menuItem, quantity, onAddItem, onQuantityChange, onRemoveItem } = props;
    // const { list: ordersInfo } = useAppSelector((state) => state.order)
    // const quantity = ordersInfo.find((item) => item.name === menuItem.menu_item_name)?.quantity;
    const [showEditable, setShowEditable] = useState(false)

    const [itemQuantity, setItemQuantity] = useState(0)

    useEffect(() => {
        setItemQuantity(Number(quantity))
    }, [quantity])


    const bgIsPurple = false

    const handleQuantityChange = () => {
        console.log("handleQuantityChange Child ===>||", itemQuantity)
        setShowEditable(false)
        onQuantityChange(itemQuantity)
    }
    return (
        <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Paper
                square={false}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "space-between",
                    bgcolor: bgIsPurple ? "#FBC1D9" : "#2C2D2C" /* FBC1D9, 2C2D2C */,
                    color: bgIsPurple ? "#000000" : "#FFFFFF",
                    borderRadius: 1,
                    width: "100%",
                }}
            >
                <Box
                    width={"4%"}
                    bgcolor={"#FBC1D9"}
                    borderRadius={"8px 0 0 8px"}
                ></Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1.8}
                    width={"96%"}
                    pl={1.5}
                >
                    <Box pt={1}>
                        <Typography
                            color={bgIsPurple ? "#6C6C6C" : "#D0D0D0"}
                            variant="h6"
                            fontWeight={"bold"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={0.5}
                            justifyContent={"flex-start"}
                        >
                            order
                            {/* <TrendingFlatOutlinedIcon fontSize="small" /> */}
                            <FaLongArrowAltRight fontSize={"1rem"} />
                            kitchen
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h4" fontWeight={"bold"} letterSpacing={-0.5}>
                            {menuItem.menu_item_name}
                        </Typography>
                        <Typography
                            variant="h6"
                            color={bgIsPurple ? "#6C6C6C" : "#D0D0D0"}
                            fontWeight={"600"}
                        >
                            {`₹${menuItem.menu_item_price}`}
                        </Typography>
                    </Box>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        gap={2}
                        pb={2}
                    >
                        <HiMinusSm
                            style={{
                                color: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
                                border: "1px solid",
                                borderColor: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
                                borderRadius: "0.3rem",
                                padding: "0.1rem",
                                fontSize: "1.5rem",
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = bgIsPurple
                                    ? "#6C6C6C"
                                    : "#D0D0D0";
                                e.currentTarget.style.color = bgIsPurple
                                    ? "#FBC1D9"
                                    : "#2C2D2C";
                                e.currentTarget.style.cursor = "pointer";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = bgIsPurple
                                    ? "#FBC1D9"
                                    : "#2C2D2C";
                                e.currentTarget.style.color = bgIsPurple
                                    ? "#6C6C6C"
                                    : "#D0D0D0";
                                e.currentTarget.style.cursor = "pointer";
                            }}
                            onClick={onRemoveItem}
                        />

                        {showEditable ?
                            <TextField
                                id="quantity"
                                sx={{
                                    maxWidth: 50,
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                      },
                                      "& input[type=number]::-webkit-outer-spin-button": {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                      "& input[type=number]::-webkit-inner-spin-button": {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                      },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "1px 6px",
                                    },
                                }}
                                type="number"
                                value={itemQuantity}
                                autoFocus
                                onChange={(evt) => setItemQuantity(Number(evt.target.value))}
                                onBlur={handleQuantityChange}
                                onKeyDown={(evt) => {
                                    if (evt.key === "Enter") {
                                        handleQuantityChange();
                                        return
                                    }
                                }}
                            />
                            :
                            <Box sx={{ cursor: "pointer" }} onClick={() => setShowEditable(true)}>
                                <Typography
                                    variant="h6"
                                    color={bgIsPurple ? "#6C6C6C" : "#D0D0D0"}
                                    fontWeight={"500"}
                                >
                                    {quantity || 0}
                                </Typography>
                            </Box>
                        }

                        <FaPlus
                            style={{
                                color: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
                                border: "1px solid",
                                borderColor: bgIsPurple ? "#6C6C6C" : "#D0D0D0",
                                borderRadius: "0.3rem",
                                padding: "0.3rem",
                                fontSize: "1.5rem",
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = bgIsPurple
                                    ? "#6C6C6C"
                                    : "#D0D0D0";
                                e.currentTarget.style.color = bgIsPurple
                                    ? "#FBC1D9"
                                    : "#2C2D2C";
                                e.currentTarget.style.cursor = "pointer";
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = bgIsPurple
                                    ? "#FBC1D9"
                                    : "#2C2D2C";
                                e.currentTarget.style.color = bgIsPurple
                                    ? "#6C6C6C"
                                    : "#D0D0D0";
                                e.currentTarget.style.cursor = "pointer";
                            }}
                            onClick={onAddItem}
                        />
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

