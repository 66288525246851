import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { IFlightMenu } from "./flight-menu.types"


export const fetchFlightsMenuListWithoutArgsAsync = createAsyncThunk<{ recordCount: number, data: IFlightMenu[] }>(
    'airline-menu/fetchFlightsMenuListWithoutArgsAsync', async () => {
        const response = await axios_api.get(`/airlines/get-menu-to-flight`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export interface IGetFlightMenu extends IQueryParams {
    airline_uuid?: string
}
export const fetchFlightsMenuListAsync = createAsyncThunk<{ recordCount: number, data: IFlightMenu[] }, IGetFlightMenu>(
    'airlines/fetchFlightsMenuListAsync', async (queryParams: IGetFlightMenu, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        let get_url = `/airlines/get-menu-to-flight${searchQuery}`;
        if (queryParams.airline_uuid) {
            get_url += `&airline_uuid=${queryParams.airline_uuid}`
        }
        const response = await axios_api.get(get_url)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchSingleFlightMenuAsync = createAsyncThunk<IFlightMenu, string>(
    'airline-menu/fetchSingleFlightMenuAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`/airlines/get-menu-to-flight?menu_to_flight_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)

export const upsertSingleFlightMenuAsync = createAsyncThunk<IFlightMenu, IFlightMenu>(
    'airline-menu/upsertSingleFlightMenuAsync', async (payload: IFlightMenu, thunkAPI) => {
        const response = await axios_api.post(`/airlines/upsert-menu-to-flight`, payload)
        return response.data
    },
)