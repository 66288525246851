import React, { useEffect } from "react";
import { Dialog } from "../../../../../Components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../../redux";
import { ControlledCustomSelect, OutlinedTextField } from "../../../../../Components/formComponents";
import { IChartOfAccount } from "../../../../../redux/accounting";
import { coa_account_type_list, coa_currency_type_list, coa_detail_type_list } from "../ChartOfAccount.constants";
import { upsertSingleChartOfAccountAsync } from "../../../../../redux/accounting/charts-of-account/charts-of-account.actions";


export interface IUpsertChartOfAccountDialogProps {
  open: boolean;
  initialState: IChartOfAccount;
  onClose: () => void;
}
export const UpsertChartOfAccountDialog: React.FC<IUpsertChartOfAccountDialogProps> = (props) => {
  const { open, initialState, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues
  } = useFormik({
    initialValues: { ...initialState },
    validate: (values) => {
      const errors: any = {};
      if (!values.detail_type) {
        errors.detail_type = "*This field is required.";
      }
      if (!values.account_name) {
        errors.account_name = "*This field is required.";
      }
      if (!values.account_number) {
        errors.account_number = "*This field is required.";
      }
      if (!values.account_type) {
        errors.account_type = "*This field is required.";
      }
      if (!values.currency) {
        errors.currency = "*This field is required.";
      }
      if (!values.current_balance) {
        errors.current_balance = "*This field is required.";
      }
      if (!values.month_opening_balance) {
        errors.month_opening_balance = "*This field is required.";
      }
      if (!values.year_opening_balance) {
        errors.year_opening_balance = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSingleChartOfAccountAsync(values)
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  useEffect(() => {
    setValues(initialState)
  }, [initialState])

  
  return (
    <Dialog
      open={open}
      title="Add/Edit Chart Of Accounts"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Detail Type"
              name="detail_type"
              value={values.detail_type}
              onChange={handleChange}
              options={coa_detail_type_list}
              error={errors.detail_type ? true : false}
              helperText={errors.detail_type}
            />
          </Grid>
          <Grid item xs={12}>

            <OutlinedTextField
              label="Account Name"
              id="account_name"
              value={values.account_name}
              onChange={handleChange}
              error={errors.account_name}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Account number"
              id="account_number"
              value={values.account_number}
              onChange={handleChange}
              error={errors.account_number}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Account Type"
              name="account_type"
              value={values.account_type}
              onChange={handleChange}
              options={coa_account_type_list}
              error={errors.account_type ? true : false}
              helperText={errors.account_type}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              disabled={values.charts_of_account_uuid ? true : false}
              label="Currency"
              name="currency"
              value={values.currency}
              onChange={handleChange}
              options={coa_currency_type_list}
              error={errors.currency ? true : false}
              helperText={errors.currency}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              disabled={values.charts_of_account_uuid ? true : false}
              label="Current Balance"
              id="current_balance"
              value={values.current_balance}
              onChange={handleChange}
              error={errors.current_balance}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              disabled={values.charts_of_account_uuid ? true : false}
              label="Month Opening Balance"
              id="month_opening_balance"
              value={values.month_opening_balance}
              onChange={handleChange}
              error={errors.month_opening_balance}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              disabled={values.charts_of_account_uuid ? true : false}
              label="Year Opening Balance"
              id="year_opening_balance"
              value={values.year_opening_balance}
              onChange={handleChange}
              error={errors.year_opening_balance}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
