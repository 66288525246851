import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { CustomRoundButton } from "../../../Components/CustomButton";
import { StandardCard } from "../../../Components/Card";

import { FaHourglassEnd } from "react-icons/fa";

export const RightOrderCard: React.FC = () => {
  return (
    <StandardCard fullHeight>
      <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%" }}>
        <Stack
          direction={"column"}
          gap={2}
          alignItems={"center"}
          justifyContent={"center"}
          margin={"auto"}
        >
          <FaHourglassEnd fontSize={"2.2rem"} />
          <Typography variant="h4" fontWeight={"700"}>
            No Items Added
          </Typography>
        </Stack>
        <CustomRoundButton title="Place Order & Pay Later" disabled fullWidth />
        {/* <CustomRoundButton title="Place Order & Checkout" disabled fullWidth /> */}
      </Box>
    </StandardCard>
  );
};
