/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../../redux";
import { StandardCard } from "../../../../Components/Card";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { OutlinedTimePicker } from "../../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { ProfileIntroCard } from "../../../../Components/Profile/ProfileIntoCard";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSingleCompanyAsync, upsertCompanyInfoAsync } from "../../../../redux/settings/company";
import { clearSingleOutletRegisterSync, fetchSingleOutletRegisterAsync, upsertOutletRegisterAsync } from "../../../../redux/settings/outlet-registers";
import { COMPANY_OUTLETS_ROUTE } from "../CompanyOutlets.constants";
import { CompaniesDropDown } from "../../Company/components/CompaniesDropDown";
import { OutletsDropDown } from "../components/OutletsDropDown";
import { RegisterStaffTable } from "../RegisterStaff/RegisterStaffTable";

export const ManageSingleRegister = () => {
  const { uuid: outletUUID, register_uuid: registerUUID } = useParams<{ uuid?: string, register_uuid?: string }>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { data: singleRegisterInfo } = useAppSelector((storeState: IStoreState) => storeState.settings.registers.single_register);

  const [saveLoading, setSaveLoading] = React.useState(false);

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      ...singleRegisterInfo,
      outlet_uuid: outletUUID || ""
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.register_name) {
        errors.register_name = "*First Name is required";
      }
      if (!values.register_type) {
        errors.register_type = "*This Field is required.";
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertOutletRegisterAsync(values),
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate(`${COMPANY_OUTLETS_ROUTE}/${outletUUID}/registers`)
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });


  React.useEffect(() => {
    if (!registerUUID) return;
    dispatch(fetchSingleOutletRegisterAsync(registerUUID));
  }, [registerUUID]);

  React.useEffect(() => {
      setValues({
        ...singleRegisterInfo,
        outlet_uuid: singleRegisterInfo.outlet_uuid ? singleRegisterInfo.outlet_uuid : outletUUID || ""
      });
  }, [singleRegisterInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleOutletRegisterSync())
    }
  }, []);


  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Manage Register</Typography>
        <StandardCard
          heading="Register Information"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <OutlinedTextField
                label="Register Name"
                id="register_name"
                value={values.register_name}
                onChange={handleChange}
                error={errors.register_name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <OutlinedTextField
                label="Register Type"
                id="register_type"
                value={values.register_type}
                onChange={handleChange}
                error={errors.register_type}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <OutletsDropDown
                value={values.outlet_uuid}
                onChange={(newValue) => setFieldValue("outlet_uuid", newValue)}
                error={errors.outlet_uuid}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <CompaniesDropDown
                value={values.company_uuid}
                onChange={(newValue) => setFieldValue("company_uuid", newValue)}
                error={errors.company_uuid}
              />
            </Grid>

          </Grid>
        </StandardCard>


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "10%" }}
            disabled={saveLoading}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>

        {registerUUID && <RegisterStaffTable />}

      </form>
    </>
  );
};
