import { LoadState } from "../../../constants/enum";
import { IPosMenuCategory, IPosMenuCategoryState } from "./pos-menu-category.types";


const dummy_categories_list: IPosMenuCategory[] = [
    {
        category_uuid: "HDW65DS45sda",
        category_name: "Breakfast",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaEF",
        category_name: "Dinner",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaVD",
        category_name: "Afternoon",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaSC",
        category_name: "Breakfast",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaQE",
        category_name: "Dinner",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaSCWRR",
        category_name: "Dinner",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaGRGHGHR",
        category_name: "Breakfast",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaWR43",
        category_name: "Dinner",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaTRY",
        category_name: "Afternoon",
        number_of_items: 5,
    },
    {
        category_uuid: "HDW65DS45sdaJHGF",
        category_name: "Dinner",
        number_of_items: 5,
    },
];



export const defaultPosMenuCategory: IPosMenuCategory = {
    category_uuid: null,
    category_name: "",
    number_of_items: 0,
}

export const defaultPosMenuCategoryState: IPosMenuCategoryState = {
    categories_list: {
        loading: LoadState.idle,
        data: [...dummy_categories_list],
        totalRecords: 0,
        error: null,
    },
    selected_category: null,
    single_category: {
        loading: LoadState.idle,
        data: defaultPosMenuCategory,
        error: null,
    }
}


