import moment from "moment";
import { IAirlineRevenue, IBarChart, IFlightFoodCost, IRevenueResponse } from "./Dashboard.types";


export const calculateAirlineRevenue = (data: IAirlineRevenue[]): number => {
    let revenue = 0;
    (data || []).forEach((airline_data) => {
        revenue += Number(airline_data.amount)
    })
    return revenue
}


export const calculateRevenueOrFoodCostForBarChart = (airline_or_flight: "airline" | "flight", data: IRevenueResponse[]): IBarChart[] => {
    if (airline_or_flight === "airline") {
        return data.map((airline_data, idx) => {
            return {
                name: airline_data.airline_name || "",
                uv: ((idx + 1) * 1000),
                amt: Number(airline_data.amount)
            }
        })
    }
    return data.map((flight_data, idx) => {
        return {
            name: flight_data.flight_name || "",
            uv: ((idx + 1) * 1000),
            amt: Number(flight_data.amount)
        }
    })
}