import { Stack, Grid, Box, Button, FormControl, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IBreadcrumbProps } from "../../../layout/breadcrumb/IBreadcrumbProps";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { PageLoader } from "../../../Components/PageLoader/PageLoader";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { clearAirlineState, upsertSingleAirlineAsync } from "../../../redux/airlineAndFlights/airlines";
import { fetchSingleAirlinesAsync } from '../../../redux/airlineAndFlights/airlines/airlines.actions';
import { AIRLINE_ROUTES } from "../AirlineAndFlights.constants";
import { ControlledCustomSelect, CustomTextField } from "../../../Components/formComponents";
import LoadingDialog from "../../../Components/Dialogs/LoadingDialog";
import { LoadState } from "../../../constants/enum";
import { Breadcrumb } from "../../../layout/breadcrumb/Breadcrumb";
import { StandardCard } from "../../../Components/Card";
import { AirlineMenuTable } from "./AirlineMenu/AirlineMenuTable";
import { DASHBOARD_ROUTES } from "../../Dashboard/Dashboard.constants";

const BCrumb: IBreadcrumbProps["items"] = [
    {
        to: DASHBOARD_ROUTES,
        title: "dashboard",
    },
    {
        to: "/airlines",
        title: "airlines",
    },
    {
        title: "manage",
    },
];

export const ManageSingleAirline = () => {
    const { uuid: airline_uuid } = useParams() as {
        uuid?: string | null;
    };
    const isUpdateProject = airline_uuid ? true : false;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {
        data: airlineInfo,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.airlinesAndFlights.airlines.single_airline);

    const [saveLoading, setSaveLoading] = React.useState(false);

    const {
        values,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: airlineInfo,
        validate: (values) => {
            const errors: any = {};
            if (!values.airline_name) {
                errors.airline_name = "*This field is required.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);
            dispatch(
                upsertSingleAirlineAsync(values)
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    navigate(AIRLINE_ROUTES)
                }
            }).finally(() => {
                setSaveLoading(false);
                // onClose()
            });
        },
    });

    React.useEffect(() => {
        if (airline_uuid) {
            dispatch(fetchSingleAirlinesAsync(airline_uuid));
        }
    }, [airline_uuid]);

    React.useEffect(() => {
        setValues(airlineInfo);
    }, [airlineInfo]);

    React.useEffect(() => {
        return () => {
            dispatch(clearAirlineState());
        };
    }, []);

    return (
        <>
            <PageLoader
                loading={loading === LoadState.pending}
            >
                
                <AirlineMenuTable airline_name={airlineInfo.airline_name || ""} />
            </PageLoader>
        </>
    );
};
