import { useEffect } from 'react';
import ReactFlow, {
  NodeTypes,
  useNodesState,
} from 'reactflow';

import 'reactflow/dist/style.css';

import ShapeNode from './components/shape-node';
import { ICustomTableNode } from './ReactFlowShapes.types';
import "./ReactFlowShapes.css"


const nodeTypes: NodeTypes = {
  shape: ShapeNode as any,
};

const proOptions = { account: 'paid-pro', hideAttribution: true };

type IReactFlowShapesViewProps = {
  initialNodes: ICustomTableNode[]
};

export const ReactFlowShapesView: React.FC<IReactFlowShapesViewProps> = ({
  initialNodes,
}) => {
  const [nodes, setNodes] = useNodesState(initialNodes);

  useEffect(() => {
    setNodes(initialNodes.map((n) => ({
      ...n, data: {
        ...n.data,
        mode: "viewOnly"
      }
    })))
  }, [initialNodes])


  return (
    <ReactFlow
      className={"dark"}
      proOptions={proOptions}
      nodeTypes={nodeTypes}
      nodes={nodes}
      fitView
      zoomOnPinch={false}
      zoomOnScroll={false}
      zoomOnDoubleClick={false}
      draggable={false}
      panOnDrag={false}
      preventScrolling
    />
  );
}