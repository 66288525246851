import React from "react";
import { IErrorMessageProps } from "./interfaces/ErroMessageProps";
import {Typography} from "@mui/material";

export const ErrorMessage: React.FC<IErrorMessageProps> =(props) => {
    const {value} = props;

    return(
        <Typography variant="body2" color="error">{value}</Typography>
    )
}