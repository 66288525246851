import { Avatar, Chip } from "@mui/material";
import React from "react";

import { ICustomUserChipProps } from "./interfaces/interface";
import { CustomChip } from "./CustomChip";
import { FiUser } from "react-icons/fi";

export const CustomUserChip = (props: ICustomUserChipProps) => {
  const { title, icon, onClick } = props;

  const isIcon = React.isValidElement(icon);
  return (
    <CustomChip
      label={title}
      icon={
        !isIcon ? (
          <FiUser
            style={{
              border: "1px solid #CFDEDB",
              borderRadius: "50%",
              padding: "0.3rem",
              fontSize: "2rem",
            }}
          />
        ) : (
          <Avatar>{icon}</Avatar>
        )
      }
      onClick={onClick}
      sx={{
        "& .MuiChip-icon": {
          mx: 0,
          bgcolor: "white",
          color: "black",
        },
        bgcolor: "#534741",
        fontSize: "0.8rem",
        borderRadius: 6,
        paddingY: 2.4,
        pl: 0.5,
        pr: 1.5,
      }}
    />
  );
};
